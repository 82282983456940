import React, { useEffect, useState } from 'react'
import { UserSettings } from '.'
import Cookies from 'js-cookie'

const FirstVisitModalManager = props => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!Cookies.get('region-ack')) {
      setShow(true)
    }
    Cookies.set('region-ack', true, { expires: 7 })
  }, [])

  if (!show) return null
  return <UserSettings onClose={() => setShow(false)} />
}

export default FirstVisitModalManager