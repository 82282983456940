import React from 'react'
import { formatCurrency } from '../../formatters/currency'

import { CartItemWrapper, Price, RemoveButton } from './styled'
import DeleteIcon from '@material-ui/icons/Delete';
import { Gap } from 'shared/ui/gap'
import ImageLoader from "shared/ui/image-loader";
import { useTranslations } from '../../context/translations';


const CartConfig = props => {
  const t = useTranslations('cart')
  const {
    currency,
    color,
    region,
    image,
    name,
    editLink,
    price,
    removeFromCart,
  } = props

  return (
    <CartItemWrapper>
      <ImageLoader ratio="4:3" source={image || '/images/VicBooth_Icon-Custom_configuration.png'} />
      <span>
        <b>{name}</b>
        <br />
        {region ? `${region} - ` : ''}{color}
        <br />
        <a href={editLink}>{t('editBoothConfigurationLink.text')}</a>
      </span>
      <Gap />
      <Price>{formatCurrency(price, currency)}</Price>
      <RemoveButton onClick={removeFromCart}><DeleteIcon /></RemoveButton>
    </CartItemWrapper>
  )
}

export default CartConfig