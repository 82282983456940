import React from "react";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Root, Flex } from "./styled";
import { formatCurrency } from "../../../../../shared/formatters/currency";
import { getCurrencyCookie } from "shared/utils/cookies";
import { SmallP } from "../../../../../shared/ui/headings";
import { useTranslations } from "../../../../../shared/context/translations";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: `calc(100% - ${theme.spacing(2)}px - 100px)`,
    maxWidth: `calc(100% - ${theme.spacing(2)}px - 100px)`,
  },
  quantityFormControl: {
    margin: theme.spacing(1),
    minWidth: `80px`,
    maxWidth: `100%`,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff3700",
      main: "#ff3700",
      dark: "#ff3700",
      contrastText: "#fff",
    },
  },
});

const relevancySort = (a, b) =>
  (parseInt(b.relevancy) || 0) - (parseInt(a.relevancy) || 0);

const RegionSelector = (props) => {
  const classes = useStyles();
  const {
    regions,
    selectRegion,
    selectedRegion,
  } = props;

  const handleChange = (event) => {
    if (!event.target.value) return;
    selectRegion(event.target.value);
  };

  const t = useTranslations('configurator')

  return (
    <Root>
      <Flex $alignEnd>
        <ScopedCssBaseline style={{ background: "transparent", width: "100%" }}>
          <ThemeProvider theme={theme}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl className={classes.formControl} size="small">
                <Select
                  value={selectedRegion}
                  variant="outlined"
                  onChange={handleChange}
                  error={selectedRegion === 'NONE'}
                >
                  <MenuItem disabled value={"NONE"}>{t('selectRegion')}</MenuItem>
                  {regions.sort(relevancySort).map((region) => (
                    <MenuItem value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </ThemeProvider>
        </ScopedCssBaseline>
      </Flex>
    </Root>
  );
};

export default RegionSelector;
