import styled from "styled-components";
import { colors } from "../../../shared/ui/colors";
import { NavLink } from "react-router-dom";

export const StyledLink = styled(NavLink)`
  padding: 5px 0;
  display: block;
`;

export const Root = styled.div`
  position: fixed;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: 100;
  transition: all 0.3s ease-out;
  background: ${({ $siteArea }) => colors.secondary[$siteArea]};
  color: ${({ $siteArea }) => $siteArea === 'generic' ? colors.black : colors.white};
  text-align: center;
  opacity: 0;
  pointer-events: none;
  
  ${StyledLink} {
    color: ${({ $siteArea }) => $siteArea === 'generic' ? colors.black : colors.white};
    &.active,
    &:hover {
      color: ${colors.primary};
    }  
  }

  &.open {
    pointer-events: all;
    top: 0px;
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  padding: 80px 30px;
`;

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
`;
