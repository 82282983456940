import React from "react";
import { draco } from "drei";
import { useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const GLTFModel = (props) => {
  const { src, ...otherProps } = props;
  const gltf = useLoader(GLTFLoader, src, draco());
  const object = gltf.scene.clone(true);
  object.traverse(function (child) {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
  return <primitive {...otherProps} object={object} />;
};
export default GLTFModel;
