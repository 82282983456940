import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';
import { chain } from "mathjs";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { colors } from "shared/ui/colors";
import Logo from "shared/ui/logo";
import { getCountryCookie, getCurrencyCookie } from "shared/utils/cookies";
import { useCartContext } from "../../../shared/context/cart";
import { useSiteAreaContext } from "../../../shared/context/site-area";
import { useBreakpoints } from "../../../shared/hooks/use-breakpoints";
import { useRenderedOnClient } from "../../../shared/hooks/use-rendered-on-client";
import CartBanner from "../../../shared/ui/cart-banner";
import { getLocaleCookie } from "../../../shared/utils/cookies";
import Menu from "../menu";
import { UserSettings } from "../user-settings";
import {
  CartButton,
  GlobalStyle,
  HeaderButtons,
  LogoHolder,
  MenuButton,
  MenuIcon,
  StyledHeader,
  UserSettingButton,
} from "./styled";
import { useScrollTop } from "./use-scroll-top";

export const HeaderContent = (props) => {
  const { stayStill, color, siteArea } = props;
  const [isOpen, setOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const isCheckoutPage = useRouteMatch("/checkout");
  const isRenderedOnClient = useRenderedOnClient()
  const { currentViewport } = useBreakpoints()
  const { cart, officeConfigurator, gamingConfigurator, ultraConfigurator, spaConfigurator, cartOpen, setCartState } = useCartContext()
  const hide = useScrollTop(stayStill, isOpen)

  const quantity = cart.items.reduce(
    (acc, item) => acc + item.quantity,
    chain(Math.min(1, officeConfigurator.configurator.items.length))
      .add(Math.min(1, gamingConfigurator.configurator.items.length))
      .add(Math.min(1, ultraConfigurator.configurator.items.length))
      .add(Math.min(1, spaConfigurator.configurator.items.length))
  )

  const location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  const country = isRenderedOnClient ? getCountryCookie() : ''
  const FlagComponent = hasFlag(country) ? Flags[country] : () => null
  const shouldHide = hide && !isOpen && !stayStill && !cartOpen
  
  return (
    <>
      <GlobalStyle $fixed={!stayStill} />
      <StyledHeader $siteArea={siteArea} hide={shouldHide} $fixed={!stayStill}>
        <Link to=''>
          <LogoHolder $fixed={!stayStill} hide={shouldHide}>
            <Logo
              siteArea="generic"
              width={220}
              color={color}
            />
          </LogoHolder>
        </Link>
        {props.children}
        {!isCheckoutPage && !props.children && (
          <>
            {isSettingsOpen && (
              <UserSettings onClose={() => setSettingsOpen(false)} />
            )}
            <CartBanner />
            <HeaderButtons $fixed={!stayStill} >
              <UserSettingButton
                $color={color}
                onClick={() => {
                  setCartState(false)
                  setOpen(false)
                  setSettingsOpen(true)
                }}
              >
                {currentViewport === "xs" || currentViewport === "s" ? (<SettingsIcon />) : 
                  <>
                    <div>
                      <span style={{ textTransform: 'uppercase' }}>{getLocaleCookie()}</span>
                    </div>
                    {country !== "ZZ" && (
                      <>
                        <div>
                          <span><FlagComponent style={{width: "25px"}} /></span>
                        </div>
                      </>
                    )}
                    <div>
                      <span>{getCurrencyCookie()}</span>
                    </div>
                  </>
                }
              </UserSettingButton>
              <CartButton
                id="cart-button"
                onClick={() => {
                  setOpen(false)
                  setSettingsOpen(false)
                  setCartState(v => !v)
                }}
                $isCartOpen={cartOpen}
                $quantity={quantity}
              >
                <ShoppingCartOutlinedIcon />
              </CartButton>
            </HeaderButtons>
          </>
        )}
        <MenuButton
          onClick={() => {
            if (!isOpen) {
              setCartState(false)
              setSettingsOpen(false)
            }
            setOpen(v => !v)
          }}
          $fixed={!stayStill}
          hide={shouldHide}
          $color={color}
        >
          <MenuIcon $color={color} isOpen={isOpen}><span>MENU</span></MenuIcon>
        </MenuButton>
        <Menu siteArea={siteArea} isOpen={isOpen} />
      </StyledHeader>
    </>
  );
};

const Header = props => {
  const { siteArea } = useSiteAreaContext()
  return <HeaderContent {...props} color={colors.white} siteArea={siteArea} />
}

export default Header;
