import React, { useState } from "react";
import Helmet from "react-helmet";
import Configurator from "./configurator";
import { Prompt, useHistory } from "react-router-dom";
import { useData } from "shared/hooks/use-data";
import { Loading } from "shared/ui/loading";
import { useRenderedOnClient } from "shared/hooks/use-rendered-on-client";
import { buildUrlQueryFromState, useInitialStateFromUrl } from "./helpers";
import Header from "../../partials/header";
import { useSiteAreaContext } from "../../../shared/context/site-area";

const ConfiguratorContainer = (props) => {
  const { filterSiteArea } = useSiteAreaContext()
  const { state, result } = useData(`/api/configurator?area=${filterSiteArea}`);
  const history = useHistory();
  const isLoading = state === "PENDING";
  const isRenderedOnClient = useRenderedOnClient();
  const [isDirty, setIsDirty] = useState(false)
  const initialStateFromUrl = useInitialStateFromUrl(isRenderedOnClient);

  const changeUrlStateString = (configuratorState) => {
    //setIsDirty(true)
    history.replace({ search: buildUrlQueryFromState(configuratorState) });
  };

  return (
    <>
      <Header stayStill />
      <Helmet>
        <title>Configurator</title>
      </Helmet>
      <Prompt 
        when={isDirty}
        message={(location, action) => {
          if (action !== "REPLACE") { 
            return `It looks like you have been editing something.\nIf you leave your changes will be lost.`
          }
          return true
        }}
      />
      {!isLoading ? (
        <Configurator
          initialState={initialStateFromUrl}
          onChange={changeUrlStateString}
          data={result}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ConfiguratorContainer;
