import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "./colors";

const breathe = keyframes`
  0% {
	opacity: 0.1;
  }
  40% {
  	opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
`;

const LoadingStyled = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  & > span {
    width: 4px;
    height: 25px;
    background: ${colors.primary};
    margin: 0 5px;
    opacity: 0;
    transform: skewX(-24deg);
    animation: ${breathe} 2.5s;
    animation-iteration-count: infinite;
  }
  & > span:nth-child(1) {
    animation: none;
    opacity: 1;
  }
  & > span:nth-child(2) {
    animation-delay: 0.2s;
  }
  & > span:nth-child(3) {
    animation-delay: 0.4s;
  }
  & > span:nth-child(4) {
    animation-delay: 0.6s;
  }
  & > span:nth-child(5) {
    animation-delay: 0.8s;
  }
  & > span:nth-child(6) {
    animation-delay: 1s;
  }
  & > span:nth-child(7) {
    animation-delay: 1.2s;
  }
  & > span:nth-child(8) {
    animation-delay: 1.4s;
  }
  & > span:nth-child(9) {
    animation-delay: 1.6s;
  }
  & > span:nth-child(10) {
    animation-delay: 1.8s;
  }
`;

export const Loading = () => (
  <LoadingStyled>
    {Array.from({ length: 10 }).map((_, i) => (
      <span key={i} />
    ))}
  </LoadingStyled>
);
