import styled from "styled-components";
import { colors } from "../../../shared/ui/colors";
import { Cell } from "../../../shared/ui/grid";
import { P, SmallP } from "../../../shared/ui/headings";

export const Main = styled.main`
  width: 100%;
  display: block;
  overflow: hidden;
  z-index: 0;
`;

export const ConfigurationsCell = styled(Cell)`
  background: #ddd;
`;

export const CanvasWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 991px) {
    min-height: 600px;
    height: 100%;
  }
`;

export const CanvasInstruction = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 50%;
  padding: 20px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: ${colors.white};
  text-align: center;
`;
export const CanvasInstructionsBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const PreviewButtons = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  padding: 15px;
  width: 100%;
  p {
    white-space: nowrap;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  @media screen and (max-width: 991px) {
    position: relative;
    display: block;
    a {
      display: inline-block;
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }
  }
`

export const PreviewImage = styled.img`
  display: block;
  max-width: 100%;
  margin: auto;
  max-height: 600px;
  @media screen and (min-width: 991px) {
    height: calc(-150px + 100vh);
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export const PreviewDisclaimer = styled(P)`
  text-align: center;
  max-width: 500px;
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ShortDescription = styled(SmallP)`
  margin: 0 20px;
  flex-grow: 1;
  white-space: normal !important;
  @media screen and (max-width: 991px) {
    margin: 20px 0 0 0;
    text-align: center;
  }
`