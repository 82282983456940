import React from "react";
import GamingLogo from "./logos/gaming";
import OfficeLogo from "./logos/office";
import SpaLogo from "./logos/spa";
import UltraLogo from "./logos/ultra";
import GenericLogo from "./logos/generic";

function Logo(props) {
  const { siteArea } = props

  switch (siteArea) {
    case 'office': return <OfficeLogo {...props}  />
    case 'ultra': return <UltraLogo {...props} />
    case 'spa': return <SpaLogo {...props} />
    case 'gaming': return <GamingLogo {...props} />
    default: return <GenericLogo {...props} />
  }
}

export default Logo;
