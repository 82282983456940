import { propOr } from 'ramda';
import React from 'react'
import styled from 'styled-components';
import { useResources } from '../../../utility/use-resources';
import bbCodeParser from "../../../utility/bb-code-parser";
import { Gap } from '../../../../vicbooth/shared/ui/gap';

const List = styled.ul`
  list-style:none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
  }
`

const Project = styled.li`
  margin: 15px;
  max-width: 400px;
  flex: 1 0 calc(100% / 3 - 30px);
  background: white;
  box-shadow: 1px 2px 4px 2px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    margin: 15px 30px;
    width: calc(100% - 30px);
  }
`
  
const ProjectHeader = styled.div`
  position: relative;
  padding-top: 50%;
  div, img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    * {
      color: ${propOr('inherit', '$color')};
    }
    p {
      margin-top: 10px;
    }
  }
`

const ProjectDescription = styled.div`
  padding: 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & > p {
    flex-grow: 1;
    line-height: 145%;
  }
`

const ProjectList = props => {
  const [r] = useResources("project-request");

  return (
    <List>
      {r('projectColumns').map(project => (
        <Project>
          <ProjectHeader $color={project.headerColor}>
            {project.image && project.image.length > 0 && <img src={project.image[0].downloadURL} />}
            <div>
              <h2 className='h4'><b>{project.title}</b></h2>
              {project.subTitle && <p>{bbCodeParser.parse(project.subTitle)}</p>}
            </div>
          </ProjectHeader>
          <ProjectDescription>
            <p className="small content-description">
              {bbCodeParser.parse(project.description)}
            </p>
            {project.link && (
              <>
                <Gap />
                <a
                  href={project.link.url}
                  className="link-primary link-outline link-dark"
                  target="_blank"
                >
                  {project.link.name}
                </a>
              </>
            )}
          </ProjectDescription>
        </Project>
      ))}
    </List>
  )
}

export default ProjectList