import styled from "styled-components";
import { colors } from "../../../../shared/ui/colors";

export const CartItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const CartItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid ${({ $noBorder }) => $noBorder ? colors.transparent : colors.gray};

  & > span + span {
    padding-left: 10px;
  }
`;

export const CartItemsHeader = styled(CartItem)`
  text-align: right;
  border-bottom: none;

  span:first-child {
    font-weight: 700;
    flex-grow: 1;
  }
  span:last-child {
    min-width: calc(100% / 3);
  }
`;

export const ConfigurationWrapper = styled(CartItem)`
  justify-content: flex-start;
  span:first-child {
    font-weight: 600;
  }
  & ~ ${CartItem} {
    padding: 5px 0;
  }
`

export const ActionButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
`