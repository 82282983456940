import React, { useRef, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import Header from "../../partials/header";
import Footer from "../../partials/footer";
import { findIndex, range } from "ramda";
import { menuLinks } from "../../../shared/constants/menu";

import LookCloser from "./ultra-sections/look-closer";
import Intro from "./ultra-sections/intro";
import Applications from "./common-sections/applications";
import Kits from "./ultra-sections/kits";
import Highlights from "./common-sections/highlights";
import SustainabilityVideo from "./common-sections/sustainability-video";
import TechSpecs from "./ultra-sections/tech-specs";
import Timelapse from "./common-sections/timelapse";
import { Helmet } from "react-helmet";
import ShowroomsContainer from "../showrooms";
import About from "./common-sections/about";
import Faqs from "./common-sections/faqs";
import Feedback from "./common-sections/feedback";
//import ReleaseBanner from "./release-banner";

const Homepage = (props) => {
  const sectionRefs = useRef([]);
  sectionRefs.current = range(0, menuLinks.length + 1).map(
    (ref, index) => (sectionRefs.current[index] = React.createRef())
  );

  const match = useRouteMatch("/ultra/:section");
  useEffect(() => {
    const matchIndex = findIndex(
      (link) => match?.params?.section === link,
      menuLinks
    );
    if (matchIndex === -1) return
    sectionRefs.current[matchIndex + 1]?.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }, [match]);

  return (
    <>
      <Helmet>
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: 'window.__initializeMap = function() {};' }} />
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBE936xV0qGB7dwduj6ZX3UjmahXTWgORU&callback=__initializeMap"></script>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous" />
      </Helmet>
      <Header />
      <div>
        {/*<ReleaseBanner />*/}
        <LookCloser ref={sectionRefs.current[0]} />
        <Intro ref={sectionRefs.current[1]} />
        <Feedback />
        <Applications ref={sectionRefs.current[2]} />
        <Highlights ref={sectionRefs.current[3]} />
        <Kits ref={sectionRefs.current[4]} />
        <ShowroomsContainer ref={sectionRefs.current[5]} />
        <TechSpecs ref={sectionRefs.current[6]} />
        <Timelapse ref={sectionRefs.current[7]} />
        <SustainabilityVideo ref={sectionRefs.current[8]} />
        <Faqs ref={sectionRefs.current[9]} />
        <About ref={sectionRefs.current[10]} />
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
