import { Link as RouterLink, NavLink as RouterNavLink } from "react-router-dom";
import React from "react";

export const Link = (props) => {
  const { to, isNav, ...rest } = props;

  const RoutedLink = isNav ? RouterNavLink : RouterLink;

  return /^(https?:)?\/\//.test(to) ? (
    <a href={to} target="_blank" {...rest} />
  ) : (
    <RoutedLink to={to} {...rest} />
  );
};

export const NavLink = (props) => <Link isNav {...props} />;

export default Link;
