import FormControl from "@material-ui/core/FormControl";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import Select from "@material-ui/core/Select";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import React from "react";
import { Flex, PanelIcon, Root } from "./styled";
import { getCurrencyCookie } from "shared/utils/cookies";
import { useTranslations } from "../../../../../shared/context/translations";
import { formatCurrency } from "../../../../../shared/formatters/currency";
import { filterVisible } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: `calc(100% - ${theme.spacing(2)}px)`,
    maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff3700",
      main: "#ff3700",
      dark: "#ff3700",
      contrastText: "#fff",
    },
  },
});

const relevancySort = (a, b) =>
  (parseInt(b.relevancy) || 0) - (parseInt(a.relevancy) || 0);

const PanelSelector = (props) => {
  const classes = useStyles();
  const {
    panelTypes,
    panel,
    panels,
    selectPanel,
    selectedPanel,
  } = props;
  const t = useTranslations('configurator')
  const handleChange = (event) => {
    if (!event.target.value) return;
    selectPanel(event.target.value);
  };
  return (
    <Root>
      <PanelIcon
        src={panel.icon?.src}
        alt={panel.icon?.title}
      />
      <Flex $alignEnd>
        <ScopedCssBaseline
          style={{ background: "transparent", width: "100%" }}
        >
          <ThemeProvider theme={theme}>
            <FormControl className={classes.formControl} size="small">
              <Select
                value={selectedPanel.type}
                variant="outlined"
                onChange={handleChange}
                displayEmpty
                renderValue={(v) => {
                  const panel = panels.find((p) => p.id === v)
                  return v
                    ? `${panel.name}${panel.price ? ` - ${formatCurrency(
                      panel.price || 0,
                      getCurrencyCookie()
                    )}` : ''}`
                    : t('selectPanel')
                  }
                }
                id="panel-selector"
              >
                {panelTypes.map((pt) => [
                  <ListSubheader>{pt.name}</ListSubheader>,
                  filterVisible(panels)
                    .filter(({ type }) => type === pt.id)
                    .sort(relevancySort)
                    .map((panel) => (
                      <MenuItem value={panel.id}>{`${panel.name}${panel.price ? ` - ${formatCurrency(
                        panel.price || 0,
                        getCurrencyCookie()
                      )}` : ''}`}</MenuItem>
                    )),
                ])}
              </Select>
            </FormControl>
          </ThemeProvider>
        </ScopedCssBaseline>
      </Flex>
    </Root>
  );
};

export default PanelSelector;
