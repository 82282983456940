import React from "react";
import reactParser, { Tag } from "bbcode-to-react";

class Link extends Tag {
  toReact() {
    const href = this.params.url
      ? this.params.url
      : this.params.link
      ? this.params.link
      : this.params.button
      ? this.params.button
      : this.getContent(true);
    return (
      <a
        href={href}
        target={this.params.target ? this.params.target : "_self"}
        className={
          this.params.link
            ? "link-secondary"
            : this.params.button
            ? "link-primary"
            : "in-text"
        }
      >
        {this.getComponents()}
      </a>
    );
  }
}

export default Link;
