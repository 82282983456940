import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../../../shared/ui/colors'
import { Gap } from '../../../shared/ui/gap'
import VicousticLogo from "shared/ui/vicoustic-logo";
import { intersperse } from 'ramda'
import { useTranslations } from '../../../shared/context/translations'

const Root = styled.footer`
  background: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
  padding: 30px 30px 80px;

  &, a {
    color: ${colors.midGray};
  }
  & > div {
    flex-basis: 0;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 290px;
  }
  & > div + div {
    text-align: right;
    padding-left: 30px;
  }
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 768px) {
    & > div {
      flex-basis: 100%;
      flex-shrink: 0;
    }
    & > div + div {
      text-align: left;
      padding: 30px 0 0 0;
    }
    padding: 15px 15px 80px;
  }
`


const SimplifiedFooter = props => {
  const currentYear = new Date().getFullYear();
  const t = useTranslations("footer");
  const links = [
    {
      href: 'https://vicoustic.com/cookies-policy',
      name: 'Cookies policy',
    },
    {
      href: 'https://vicoustic.com/privacy-policy',
      name: 'Privacy policy',
    },
    {
      href: 'https://vicoustic.com/where-to-buy',
      name: 'Where to buy',
    },
  ]
  return (
    <Root>
      <div>
        <b><Link to="/office">Vicbooth Office for Workspaces</Link></b>
        <br />
        <Link to="/office/products">Shop</Link><span> | </span><Link to="/office/configurator">Configurator</Link>
        <Gap x={0.5} />
        <b><Link to="/ultra">Vicbooth Ultra for Professional Audio</Link></b>
        <br />
        <Link to="/ultra/products">Shop</Link><span> | </span><Link to="/ultra/configurator">Configurator</Link>
        <Gap x={0.5} />
        <b><Link to="/spa">VicBooth Spa for Wellness</Link></b>
        <br />
        <Link to="/spa/products">Shop</Link>
        <Gap x={0.5} />
        <b><Link to="/gaming">Vicbooth Gaming</Link></b>
        <br />
        <Link to="/gaming/products">Shop</Link><span> | </span><Link to="/gaming/configurator">Configurator</Link>
      </div>
      <div>
        <VicousticLogo width={170} />
        <Gap x={0.5} />
        <div>{currentYear} Vicoustic. All rights reserved.</div>
        <div>{intersperse(' | ', links.map(l => <a key={l.href} target="_blank" href={l.href}>{l.name}</a>))}</div>
        <br />
        <div>
          <a href={t("norte2020.link")} target="_blank">
            <img src={t('norte2020.imageDark.src') || "/images/certifications-dark.png"} />
          </a>
        </div>
        {t('norte2020secondary.link') && t('norte2020secondary.imageDark.src') && (
          <>
            <br />
            <div>
              <a href={t('norte2020secondary.link')} target="_blank">
                <img src={t('norte2020secondary.imageDark.src')} />
              </a>
            </div>
          </>
        )}
      </div>
    </Root>
  )
}

export default SimplifiedFooter
