import React, { useEffect, useState } from 'react'
import { formatCurrency } from '../../formatters/currency'

import { CartItemWrapper, QuantityPicker, Price,QuantityWrapper, RemoveButton } from './styled'
import DeleteIcon from '@material-ui/icons/Delete';
import { Gap } from 'shared/ui/gap'
import ImageLoader from "shared/ui/image-loader";
import {getViewport} from "../../../../vicoustic/utility/get-viewport";


const CartItem = props => {
  const {
    currency,
    image,
    name,
    variationState,
    prices,
    quantity,
    changeQuantity,
    removeFromCart,
  } = props
  const [inputValue, setInputValue] = useState(quantity)
  const handleChange = e => {
    setInputValue(parseInt(e.target.value) || '')
  }
  
  const resetInputValue = () => {
    setInputValue(quantity)
  }

  useEffect(() => {
    if (!inputValue || inputValue < 1 || inputValue === quantity) return
    changeQuantity(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (inputValue === quantity) return
    setInputValue(quantity)
  }, [quantity])

  return (
    <CartItemWrapper>
      {image && <ImageLoader ratio="4:3" source={getViewport(image.src, 'xs')} />}
      <span>
        <b>{name}</b>
        {variationState && <>
          <br />
          {variationState}
        </>}
      </span>
      <Gap x={2} />
      <QuantityWrapper className="input-field">
        {'x '}
        <QuantityPicker
          type="number"
          value={inputValue}
          onBlur={resetInputValue}
          onChange={handleChange}
        />
      </QuantityWrapper>
      <Gap />
      <Price>{formatCurrency(prices[currency] * quantity, currency)}</Price>
      <RemoveButton onClick={removeFromCart}><DeleteIcon /></RemoveButton>
    </CartItemWrapper>
  )
}

export default CartItem