import styled from 'styled-components'
import { PlainButton } from '../../../shared/ui/button'
import { colors } from '../../../shared/ui/colors'

export const Root = styled.main`
  max-width: 1600px;
  position: relative;
  width: 100%;
  padding: 0 30px 50px;
  margin: auto;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`

export const Body = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    & > * {
      flex: 50% 0 0;
      padding: 0 30px 0 0;
    }
    & > *:last-child {
      padding: 0 0 0 30px;
    } 
  }
`

export const StickyBox = styled.div`
  @media screen and (min-width: 768px) {
    position: sticky;
    top: 30px;
  }
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  max-height: 100%;
`

export const TextWrapper = styled.div`
  max-width: 600px;
  width: 100%;
`

export const ImageRatioBox = styled.div`
  width: 100%;
  padding-top: 75%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 30px; 
  img {
    width: 100%;
    margin: auto;
    max-height: 100%;
    object-fit: contain;
    object-position: center top;
  }
`

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  display: block;
  background: ${colors.lightGray};
`

export const GalleryWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`

export const Thumb = styled(PlainButton)`
  width: 75px;
  height: 56.8px;
  border: 1px solid ${({ $selected }) => $selected ? colors.primary : colors.gray};

  &:hover {
    cursor: pointer;
  }

  margin-bottom: 15px;
  &:not(:last-child) {
    margin-right: 15px;
  }
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 37.5px;
  }
`

export const Gallery = styled.div`
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items:center;
`