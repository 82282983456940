import React from 'react'
import { Helmet } from 'react-helmet'
import { getViewport } from '../../../../vicoustic/utility/get-viewport'
import { useTranslations } from '../../../shared/context/translations'
import { useData } from '../../../shared/hooks/use-data'
import { colors } from '../../../shared/ui/colors'
import { H4, P } from '../../../shared/ui/headings'
import { Loading } from '../../../shared/ui/loading'
import parseMarkdown, { inlineMarkdown } from '../../../shared/utils/markdown'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import AccessoriesList from './accessories-list'
import { AccessoriesHero, AccessoriesImageWrapper } from './styled'

const AccessoriesListContainer = props => {
  const { state, result, error } =  useData(`/api/products?category=accessories`)
  const isLoading = state === 'PENDING'
  const t = useTranslations('accessories')
  return (
    <>
      <Helmet>
        <title>VicBooth Accessories</title>
        <meta name="description" content={"Vicoustic designed built-in accessories to make better use of the space inside a VicBooth acoustic cabin. From desks, tables and shelfs made in MDF to TV screen support bases, refurbishment and lighting solutions, all components fit the booth’s structure beams."} />
        <meta property="og:image" content={`/images/meta/VicBooth_Accessories.webp`} />
        <meta property="og:title" content={`VicBooth Accessories`} />
        <meta property="og:description" content={"Vicoustic designed built-in accessories to make better use of the space inside a VicBooth acoustic cabin. From desks, tables and shelfs made in MDF to TV screen support bases, refurbishment and lighting solutions, all components fit the booth’s structure beams."} />
        <meta property="og:site_name" content={`VicBooth`} />
      </Helmet>
      <NoContextHeader />
      <div>
        <AccessoriesHero>
          <div>
            <H4 as="h1" style={{ color: colors.accent, marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: inlineMarkdown(t('title'))}} />
            <P as="h4" dangerouslySetInnerHTML={{ __html: inlineMarkdown(t('subTitle'))}} />
            <div dangerouslySetInnerHTML={{ __html: parseMarkdown(t('description'))}} />
          </div>
          <AccessoriesImageWrapper>
            <img src={getViewport(t('image.src'), 'm')} alt="accessories" />
          </AccessoriesImageWrapper>
        </AccessoriesHero>
      </div>
      {isLoading
        ? <div><Loading /></div>
        : error || <AccessoriesList products={result} />}
      <SimplifiedFooter />
    </>
  )
}

export default AccessoriesListContainer
