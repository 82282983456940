import React from "react";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Root, Flex } from "./styled";
import { formatCurrency } from "../../../../../shared/formatters/currency";
import { getCurrencyCookie } from "shared/utils/cookies";
import { SmallP } from "../../../../../shared/ui/headings";
import { useTranslations } from "../../../../../shared/context/translations";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: `calc(100% - ${theme.spacing(2)}px - 100px)`,
    maxWidth: `calc(100% - ${theme.spacing(2)}px - 100px)`,
  },
  quantityFormControl: {
    margin: theme.spacing(1),
    minWidth: `80px`,
    maxWidth: `100%`,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff3700",
      main: "#ff3700",
      dark: "#ff3700",
      contrastText: "#fff",
    },
  },
});

const relevancySort = (a, b) =>
  (parseInt(b.relevancy) || 0) - (parseInt(a.relevancy) || 0);

const VentilatorSelector = (props) => {
  const classes = useStyles();
  const {
    ventilators,
    selectVentilator,
    selectedVentilator,
    quantity,
    setQuantity,
    minQuantity = 0,
    maxQuantity,
  } = props;
  // const isActive = (panel) => panel.id === selectedPanel.type;
  const t = useTranslations('configurator')
  const handleChange = (event) => {
    if (!event.target.value) return;
    selectVentilator(event.target.value);
  };
  const handleQuantityChange = (event) => {
    if (!event.target.value) return;
    setQuantity(event.target.value);
  };

  const allowNone = minQuantity === 0
  const hasChoice = minQuantity !== maxQuantity

  return (
    <Root>
      <Flex $alignEnd>
        <ScopedCssBaseline style={{ background: "transparent", width: "100%" }}>
          <ThemeProvider theme={theme}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl className={classes.formControl} size="small">
                <Select
                  value={selectedVentilator}
                  variant="outlined"
                  onChange={handleChange}
                  error={!allowNone && selectedVentilator === 'NONE'}
                >
                  <MenuItem disabled={!allowNone} value={"NONE"}>{allowNone ? t('noVentilationUnit') : t('selectVentilation')}</MenuItem>
                  {ventilators.sort(relevancySort).map((ventilator) => (
                    <MenuItem value={ventilator.id}>
                      {`${ventilator.name} - ${
                        ventilator.price === 0 
                          ? t('included')
                          : formatCurrency(
                            ventilator.price,
                            getCurrencyCookie()
                          )
                      }`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(!allowNone || selectedVentilator !== "NONE") && hasChoice && (
                <FormControl className={classes.quantityFormControl}>
                  <Select
                    value={quantity}
                    variant="outlined"
                    onChange={handleQuantityChange}
                  >
                    {Array.from({length: maxQuantity}, (_, i) => i + 1).slice(Math.max(0, minQuantity - 1)).map((qnt) => (
                      <MenuItem value={qnt}>{qnt}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {!hasChoice && minQuantity !== 0 && (
                <FormControl className={classes.quantityFormControl}>
                  <SmallP>x {quantity} unit{quantity === 1 ? '' : 's'}</SmallP>
                </FormControl>
              )}
            </div>
          </ThemeProvider>
        </ScopedCssBaseline>
      </Flex>
    </Root>
  );
};

export default VentilatorSelector;
