import React, { useEffect } from "react";
import { UnsignedByteType, PMREMGenerator } from "three";
import { useThree } from "react-three-fiber";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { Vector2 } from "three";

const HDRI = "/images/configurator/hdr/studio.hdr";

const Scene = (props) => {
  const { gl, scene } = useThree();
  const pmremGenerator = new PMREMGenerator(gl);
  const loader = new RGBELoader();
  loader.setDataType(UnsignedByteType);
  pmremGenerator.compileEquirectangularShader();

  useEffect(() => {
    loader.load(HDRI, (texture) => {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;

      scene.environment = envMap;

      texture.dispose();
      pmremGenerator.dispose();
    });
  }, [scene, loader, pmremGenerator]);
  return (
    <React.Fragment>
      <ambientLight intensity={0.5} />
      <directionalLight
        castShadow
        shadow-mapSize={new Vector2(2048, 2048)}
        intensity={2}
        position={[5, 2, 5]}
      />
      <directionalLight
        castShadow
        shadow-mapSize={new Vector2(2048, 2048)}
        intensity={2}
        position={[-5, 2, 5]}
      />
    </React.Fragment>
  );
};

export default Scene;
