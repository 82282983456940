import React, { Suspense, useRef, useEffect } from "react";
import * as THREE from "three";
import { useSpring } from "react-spring/three";
import { Canvas, useThree, useFrame, extend } from "react-three-fiber";
import Scene from "./scene";
import Effects from "./effects";
import GLTFModel from "./model";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { SceneRefSetter } from "./scene-ref-setter";
extend({ OrbitControls });

function Controls(props) {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();

  useFrame(() => {
    controls.current.update();
  });

  useEffect(() => {
    camera.position.x = props.cameraXZ;
    camera.position.z = -props.cameraXZ;
  }, [props.cameraXZ]);
  /*
  useSpring({
    rotation: props.intendedRotation,
    onFrame: ({ rotation }) => {
      const cameraDistanceFromCenter = 3.5355;
      const cameraY = 1.1;

      const cameraX = Math.cos(rotation) * cameraDistanceFromCenter;
      const cameraZ = Math.sin(rotation) * cameraDistanceFromCenter;

      camera.position.x = cameraX;
      camera.position.y = cameraY;
      camera.position.z = cameraZ;
    },
  });
    */

  const productionParams = {
    enableZoom: true,
    enablePan: true,
    //enableRotate: false,
    //maxAzimuthAngle: Math.PI / 2,
    //maxPolarAngle: Math.PI / 2,
    //minAzimuthAngle: Math.PI / 2,
    //minPolarAngle: Math.PI / 2,
  };
  return (
    <orbitControls
      {...productionParams}
      target={[0, 1.1, 0]}
      ref={controls}
      args={[camera, domElement]}
    />
  );
}

const ventilatorModelSrc = 'https://firebasestorage.googleapis.com/v0/b/vicoustic-website.appspot.com/o/_static%2Fvicbooth-configurator%2Fmodels%2FAC_Infinity.glb?alt=media&token=fb318cd3-5164-418e-b0c3-404594773ffd'

const BoothCanvas = (props) => {
  const {
    isMobile,
    structureModel,
    panels,
    cameraDistance = 3.5355,
    hasVentilators,
    ventilatorCount,
    ventilatorsMatrix,
   } = props;
  //const intendedRotation = props.rotationStep * rotationStep + offsetRotation;

  const cameraXZ = Math.sqrt(Math.pow(cameraDistance, 2) / 2);

  return (
    <Canvas
      colorManagement
      camera={{ position: [cameraXZ, 1.2, -cameraXZ], fov: 40 }}
      shadowMap
      gl={{
        preserveDrawingBuffer: true,
        powerPreference: 'high-performance',
      }}
      onCreated={({ gl }) => {
        gl.shadowMap.enabled = true;
        gl.setClearColor(new THREE.Color("#ffffff"));
      }}
      style={{ height: isMobile ? window.innerWidth * 0.75 : "calc(100vh - 150px)" }}
    >
      <SceneRefSetter />
      <Controls cameraXZ={cameraXZ} />
      <Suspense fallback={null}>
        <Effects />
        <Scene />
        {structureModel && <GLTFModel src={structureModel} />}
        {panels &&
          panels.map(
            ({ model, matrix }, idx) =>
              model && (
                <Suspense key={idx} fallback={null}>
                  <GLTFModel key={idx} {...matrix} src={model} />
                </Suspense>
              )
          )}
        {hasVentilators && [...new Array(ventilatorCount)].map((_, idx) => ventilatorsMatrix[idx] && (
          <Suspense key={idx} fallback={null}>
            <GLTFModel key={idx} {...ventilatorsMatrix[idx]} src={ventilatorModelSrc} />
          </Suspense>
        ))}
      </Suspense>
    </Canvas>
  );
};

export default BoothCanvas;
