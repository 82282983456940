import { allPass, propEq } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import { useTranslations } from '../../../shared/context/translations'
import { ColorButton } from '../../../shared/ui/color-button'
import { RegionButton } from '../../../shared/ui/region-button'
import { getVariationOptions } from './helpers'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const colorTextureByName = {
  'Black': '#000000',
  'Black Matte': '#000000',
  'White': '#ffffff',
  'Natural Oak': '#d8ad7a',
  'Natural Oak + White': '#d8ad7a-#ffffff',
  'Natural Oak + Black': '#d8ad7a-#000000',
}

const componentByVariate = {
  'extension-kit': kit => props => <RegionButton {...props}>{kit || ''}</RegionButton>,
  'furniture-color': color => props => <ColorButton texture={colorTextureByName[color] || 'White'} {...props} />,
  color: color => props => <ColorButton texture={colorTextureByName[color] || 'White'} {...props} />,
  region: region => props => <RegionButton {...props}>{region || ''}</RegionButton>,
  'light-and-ventilation': opt => props => <RegionButton {...props}>{opt === 'Yes' ? <CheckIcon style={{ fill: "#00ac54" }} /> : <CloseIcon style={{ fill: "red" }} />}</RegionButton>,
}

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 6px;
  & > span {
    margin-right: ${({ $hideText }) => $hideText ? '6px' : '30px'};
  }
  
`

const OptionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: -3px 0 0;
  display: flex;
`

const VariationSelector = props => {
  const { variations, variatesOptions } = props

  const filterPredicate = idx => allPass(
    props.variates.slice(0, idx)
      .map(v => propEq(v, props.active[v]))
  )
  const t = useTranslations('shop')

  return props.variates.map((variates, idx) => {
    const options = getVariationOptions({ variates, variations }, filterPredicate(idx))

    if (options.length < 2) return null

    return (
      <Root key={variates} $hideText={props.hideText}>
        {(!props.hideText || variates=== 'light-and-ventilation') && <span>{t(`selectorText.${variates}`)}:</span>}
        <OptionList>
          {options.sort((a, b) => variatesOptions[variates].indexOf(a) - variatesOptions[variates].indexOf(b)).map(option => (
            <li key={option}>
              {componentByVariate[variates]?.(option)({
                onClick: () => props.setVariation(variates, option),
                active: props.active[variates] === option,
              }) || null}
            </li>
          ))}
        </OptionList>
      </Root>
    )
  })
}

export default VariationSelector
