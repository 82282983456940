import React, { useState, useMemo } from "react";
import Select from "../../../../vicoustic/forms/select";
import { Loading } from "../../../shared/ui/loading";
import Modal from "../../../shared/ui/modal";
import { useData } from "../../../shared/hooks/use-data";
import { Root } from "./styled";
import { H5 } from "../../../shared/ui/headings";
import { Button } from "../../../shared/ui/button";
import { useHistory } from "react-router";
import { Gap } from "../../../shared/ui/gap";
import { setUrlVar } from "../../../shared/utils/url";
import { getCountryCookie, getLocaleCookie } from "shared/utils/cookies";
import { useTranslations } from "../../../shared/context/translations";

export const UserSettings = (props) => {
  const history = useHistory();
  const { result: countriesResult, state: countriesState } = useData("/api/countries");
  const { result: localesResult, state: localesState } = useData("/api/locales");
  const isLoading = countriesState === 'PENDING' || localesState === "PENDING";
  const [currentCountry, setCurrentCountry] = useState(() =>
    getCountryCookie()
  );

  const [currentLocale, setCurrentLocale] = useState(() =>
    getLocaleCookie()
  );

  const applySettings = () => {
    setUrlVar("country", currentCountry);
    setUrlVar("locale", currentLocale);
    history.go(0);
  };
  const t = useTranslations('header.settings')

  return (
    <Modal onClose={props.onClose} initiallyOpen>
      <Root>
        <H5>{t('title')}</H5>
        <Gap />
        {isLoading ? (
          <div style={{ height: "76px" }}>
            <Loading />
          </div>
        ) : (
          <>
            <Select
              id="locale"
              label={t('locale')}
              value={currentLocale}
              onChange={setCurrentLocale}
            >
              {localesResult ? localesResult.map((locale) => (
                <option key={locale.id} value={locale.id}>
                  {locale.name}
                </option>
              )) : null}
            </Select>
            <Gap />
            <Select
              id="country"
              label={t('country')}
              value={currentCountry}
              onChange={setCurrentCountry}
            >
              {currentCountry === 'ZZ' && <option value="ZZ">Not specified</option>}
              {countriesResult.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name} {country.currency ? `(${country.currency} ${country.currencySymbol})` : ''}
                </option>
              ))}
            </Select>
          </>
        )}
        <Gap />
        <Button disabled={isLoading} onClick={applySettings}>
          {t('button.text')}
        </Button>
      </Root>
    </Modal>
  );
};
