import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { configureStore } from "../store";
// Create store with reducers and initial state

var fbConfig = {
  apiKey: "AIzaSyBE936xV0qGB7dwduj6ZX3UjmahXTWgORU",
  authDomain: "vicoustic-website.firebaseapp.com",
  databaseURL: "https://vicoustic-website.firebaseio.com",
  projectId: "vicoustic-website",
  storageBucket: "vicoustic-website.appspot.com",
  messagingSenderId: "869267867657",
};
firebase.initializeApp(fbConfig);
firebase.firestore();

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;

const configuredStore = configureStore(firebase, preloadedState);
export const store = configuredStore.store;
export const rrfProps = configuredStore.rrfProps;
