import { createStore, combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users-profile",
  useFirestoreForProfile: true,
  enableClaims: true,
};

export const configureStore = (firebase, preloadedState = {}) => {
  // Add firebase to reducers
  const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });

  // Create store with reducers and initial state
  const store = createStore(
    rootReducer,
    preloadedState,
    typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (arg) => arg
  );

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  return {
    store,
    rrfProps,
  };
};
