import React, { forwardRef, useRef, useState } from "react";
import styled from "styled-components";
import { F, T } from "ramda";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { propTernary } from "../utils/styled";
import { useCombinedRefs } from "shared/hooks/use-combined-ref";
import { useBreakpoints } from "../hooks/use-breakpoints";
import { useRenderedOnClient } from "../hooks/use-rendered-on-client";

const ratioProp = ({ ratio }) => ratio;

const Root = styled.div`
  display: block;
  position: relative;
  transition: opacity 0.3s ease-out;
  opacity: ${propTernary("$hide", 0, 1)};
  width: 100%;
  overflow: hidden;
  z-index: 0;

  ::before {
    content: "";
    padding-top: ${ratioProp}%;
    display: block;
    width: 100%;
  }
  &.shade::after {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), transparent);
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  font-size: 32px;
  right: 20px;
  display: flex;
`;

const BackgroundVideo = (props, ref) => {
  const {
    ratio = "1920:1080",
    source,
    sourceMobile,
    posterMobile,
    poster,
    ratioMobile = "360:640",
    hide,
    shade,
    ...videoProps
  } = props;
  const { isMobile } = useBreakpoints();
  const renderedOnClient = useRenderedOnClient();

  const currentRatio = isMobile ? ratioMobile : ratio;
  const currentSource = isMobile ? sourceMobile : source;
  const currentPoster = isMobile ? posterMobile : poster;

  const ratioSplit = currentRatio.split(":").map((v) => parseInt(v, 10));
  const ratioPerc = (ratioSplit[1] / ratioSplit[0]) * 100;
  const innerRef = React.useRef(null);
  const combinedRef = useCombinedRefs(ref, innerRef);

  const [videoPlayingState, setVideoPlayingState] = useState(
    videoProps.autoplay
  );
  const videoEvents = {
    onPlay: () => setVideoPlayingState(T),
    onPause: () => setVideoPlayingState(F),
    onEnded: () => combinedRef.current.load(),
  };
  return (
    <Root ratio={ratioPerc} $hide={hide} className={shade && "shade"}>
      {renderedOnClient && (
        <Video
          ref={combinedRef}
          key={currentSource}
          poster={currentPoster}
          playsInline
          {...videoProps}
          {...videoEvents}
        >
          {currentSource && (
            <source key={currentSource} src={currentSource} type="video/mp4" />
          )}
        </Video>
      )}
      {currentSource && <Controls>
        {videoPlayingState ? (
          <PauseIcon
            fontSize={"inherit"}
            onClick={() => combinedRef.current.pause()}
          />
        ) : (
          <PlayArrowIcon
            fontSize={"inherit"}
            onClick={() => combinedRef.current.play()}
          />
        )}
      </Controls>}
    </Root>
  );
};

export default forwardRef(BackgroundVideo);
