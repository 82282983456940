import styled from "styled-components";
import { ColorButton } from "shared/ui/color-button";

export const List = styled.ul`
  list-style: none;
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
`;

export const Color = styled(ColorButton)`
  margin: 6px;
`;

export const Root = styled.div`
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;
