import microdown from 'micro-down'
import DOMPurify from 'dompurify'
import memoize from 'memoize-one'


// addHook is only present on the client side
if (DOMPurify.addHook) {
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    /**
     * All elements that can function as a link will oepn in a new tab
     * See more https://github.com/cure53/DOMPurify/blob/64ee968e790dedb25cb7a7a2478ad1d82d5d295a/demos/README.md#hook-to-open-all-links-in-a-new-window-link
     */
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      // Prevent Reverse Tabnabbing https://owasp.org/www-community/attacks/Reverse_Tabnabbing
      node.setAttribute('rel', 'noopener noreferrer')
    }
  })
}

/**
 * There are 2 different tools used for sanitization,
 * dompurify for client and sanitize-html for server.
 * This is because sanitize-html is too big for client purposes.
 * Due to that, we require that sanitizeHtml global is present on a server scenario.
 * dompurify also works on server but it's too heavy since it requires jsdom
 */
const createSanitizer = memoize(() => {
  if (typeof window !== 'undefined') return DOMPurify.sanitize

  if (!global.sanitizeHtml) {
    throw new Error('sanitizeHtml (from sanitize-html lib) is not included in the globals')
  }

  return global.sanitizeHtml
})

const parseMarkdown = (text) => {
  const parsedHTML = microdown.parse(text)
  const sanitizedHTML = createSanitizer()(parsedHTML, {})
  return sanitizedHTML
}

export const inlineMarkdown = (text) => {
  const parsedHTML = microdown.inline(text)
  const sanitizedHTML = createSanitizer()(parsedHTML, {})
  return sanitizedHTML
}

export default parseMarkdown
