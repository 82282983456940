import React, { forwardRef, useState } from "react";

import {
  PageSection,
  ColorBall,
  ImageContainer,
  PageSectionTitle,
  Center,
} from "../styled";
import { H4, SmallP, P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import Columns from "shared/ui/blocks/columns";
import ImageLoader from "shared/ui/image-loader";
import { FakeButton } from "../../../../shared/ui/button";
import NewsletterBoundLink from "../../../../shared/ui/newsletter-bound-link";
import styled from "styled-components";
import { Arrows } from "../../../../shared/ui/slideshow";
import { useTranslations } from "../../../../shared/context/translations";
import { intersperse } from "ramda";

const NUM_ITEMS_PER_SLIDE = 3
const Booth = ({ exteriorText, interiorText, nicText, ...booth }) => (
  <Center>
    <H4 $bold>{booth.title}</H4>
    <ImageContainer>
      <ImageLoader
        ratio={"4:3"}
        source={booth.image.src}
      />
    </ImageContainer>
    <SmallP>
      <b>{exteriorText}</b> {booth.mmExteriorSize}
      <br />
      {booth.inExteriorSize}
      <br />
      <b>{interiorText}</b> {booth.mmInteriorSize}
      <br />
      {booth.inInteriorSize}
      <br />
      <b>{nicText}</b> {booth.nic}
    </SmallP>
  </Center>
)

const Slider = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    transition: all .3s ease-out;
    transform: translateX(${({ active }) => active * -100}%);
    & > * {
      width: 100%;
      flex: 0 0 100%;
    }
  }
`
const SliderWrapper = styled.div`
  width: 100%;
  position: relative; 
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    & > *:not(:first-child) {
      display: none;
    }
  }
`

const TechSpecs = (props, ref) => {
  const t = useTranslations('homepageSections.ultra.technicalSpecs')

  const boothSpecs = t('booths')
  const [active, setActive] = useState(0);
  const chunks = Array(Math.ceil(boothSpecs.length / NUM_ITEMS_PER_SLIDE))
    .fill()
    .map((_, index) => index * NUM_ITEMS_PER_SLIDE)
    .map(begin => boothSpecs.slice(begin, begin + NUM_ITEMS_PER_SLIDE));
  
  return (
    <PageSection ref={ref}>
      <PageSectionTitle>{t('name')}</PageSectionTitle>
      <BlueprintIcon color={colors.primary} />
      <Gap />
      <SliderWrapper>
        <Slider active={active}>
          {chunks.map(boothChunks => (
            <Columns $mobileBreakpoint={1024}>
              {boothChunks.map(booth => (
                <>
                  <Booth
                    interiorText={t('interior')}
                    exteriorText={t('exterior')}
                    nicText={t('nic')}
                    {...booth}
                  />
                </>
              ))}
            </Columns>
          ))}
        </Slider>
        {(chunks.length > 1) && <Arrows setActive={setActive} itemCount={chunks.length} />}
      </SliderWrapper>
      <Gap />
      <Center>
        <SmallP>
          {t('footnote')}
        </SmallP>
      </Center>
      <Gap />
      <Columns $mobileBreakpoint={767} $keepColumnSize>
        {t('colors').map(color => (
          <>
            <ColorBall $color={color.texture} />
            <Center>
              <P $bold $noWrap>
                {color.text}
              </P>
            </Center>
          </>
        ))}
      </Columns>
      <SmallP>
        <b>{t('finishesText')}</b> {t('finishes')}
      </SmallP>
      <Gap />
      <Columns $mobileBreakpoint={767}>
        {t('items').map(item => (
          <Center>
            <ImageLoader ratio={"1:1"} source={item.image.src} />
            <SmallP $noWrap>
              <b>{item.title}</b>
            </SmallP>
            <SmallP>{item.description}</SmallP>
          </Center>
        ))}
      </Columns>
      <Gap />
      <Columns $noCellPadding $keepColumnSize $mobileBreakpoint={767} $wrap>
        {intersperse(<Gap />, t('buttons').map(button => (
          <Center>
            <NewsletterBoundLink url={button.link}>
              <FakeButton $withHover $noWrap>
                {button.text}
              </FakeButton>
            </NewsletterBoundLink>
          </Center>
        )))}
      </Columns>
    </PageSection>
  );
};

export default forwardRef(TechSpecs);
