import { add, multiply } from "mathjs"
import { propEq } from "ramda"
import React from 'react'
import { getCurrencyCookie } from "../../utils/cookies"
import CartConfig from "./cart-config"

function capitalize(word) {
  return word
    .toLowerCase()
    .replace(/\w/, firstLetter => firstLetter.toUpperCase());
}

export const BoothCartItem = props => {
  const currency = getCurrencyCookie()
  const { configurator, configuratorActions, siteArea } = props
  if (configurator.items.length === 0) return null

  const boothImage = configurator.preview
  const boothUrl = configurator.url

  const boothConfigTotal = configurator.items.reduce(
    (acc, item) => add(
      acc,
      multiply(
        item.quantity,
        (item.prices?.[currency] || item.price || 0)
      )
    ),
    0
  )

  const configBp = configurator.items.find(propEq('type', 'blueprint'))
  const configPanel = configurator.items.find(propEq('type', 'panel'))
  const configVentilator = configurator.items.find(propEq('type', 'ventilator'))

  return (
    <CartConfig
      currency={currency}
      name={`Vicbooth ${capitalize(siteArea)} ${configBp.name} - Custom Configuration`}
      color={configPanel.color}
      region={configVentilator?.desc || (configBp.region || '').toUpperCase()}
      price={boothConfigTotal}
      image={boothImage}
      editLink={boothUrl}
      removeFromCart={configuratorActions.removeConfiguration}
    />
  )
}