import React from 'react'
import {Tag} from 'bbcode-to-react'

class Quote extends Tag {
	toReact() {
		return (
			<q>
				"{this.getContent(true)}"
				<br />
				{this.params.author && <span>{this.params.author}</span>}
			</q>
		)
	}
}

export default Quote