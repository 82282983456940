import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import BackgroundVideo from "shared/ui/background-video";
import Multimedia from 'shared/ui/blocks/multimedia';
import { useTranslationsArray } from '../../../shared/context/translations';
import { useRenderedOnClient } from '../../../shared/hooks/use-rendered-on-client';
import Columns from '../../../shared/ui/blocks/columns';
import { Gap } from '../../../shared/ui/gap';
import { H6, P, SmallP } from '../../../shared/ui/headings';
import ImageLoader from '../../../shared/ui/image-loader';
import parseMarkdown from '../../../shared/utils/markdown';
import SimplifiedFooter from '../../partials/footer/simplified';
import NoContextHeader from '../../partials/header/no-context-header';
import { HeaderText } from '../../partials/header/styled';
import { Card, CardContent, HighlightColumn, ImageContainer, Root, TextHolder, Title, VideoWrapper } from './styled';

const desc = 'Sustainable acoustic booths for Hospitality, Offices and Professional Audio Environments developed for sound insulation, privacy and sound performance.'

const DisambiguationHome = props => {
  const { t, tA } = useTranslationsArray('disambiguationHome')
  const isRenderedOnClient = useRenderedOnClient();
  const videoRef = useRef(null);

  const [inViewRef, inView] = useInView({
    treshold: 0.05,
  });

  useEffect(() => {
    if (inView) videoRef.current.play();
  }, [inView]);

  return (
    <Root>
      <Helmet>
        <title>VicBooth - Acoustic and Soundproof Booths</title>
        <meta name="description" content={desc} />
        <meta property="og:image" content="/images/vicbooth.jpg" />
        <meta property="og:title" content={`VicBooth - Acoustic and Soundproof Booths`} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content="Vicbooth" />
      </Helmet>
      <NoContextHeader>
        <HeaderText>
          <H6 dangerouslySetInnerHTML={{ __html: parseMarkdown(t('newsBanner.title'))}} />
        </HeaderText>
      </NoContextHeader>
      <VideoWrapper>
        <Multimedia ref={inViewRef}>
          <BackgroundVideo
            ref={videoRef}
            ratio={"1920:1080"}
            ratioMobile={"16:9"}
            source={t('videoDesktop.src')}
            poster={t('posterDesktop.src')}
            sourceMobile={t('videoMobile.src')}
            posterMobile={t('posterMobile.src')}
            autoplay
            muted
            loop
          />
        </Multimedia>
      </VideoWrapper>
      <Columns $mobileBreakpoint={767}>
        <TextHolder dangerouslySetInnerHTML={{ __html: isRenderedOnClient ? parseMarkdown(t('description.text')) : '' }} />
        <ImageLoader ratio={"4:3"} source={t('description.image.src')} />
      </Columns>
      <div style={{ backgroundColor: '#f0f0f0', padding: '15px'}}>
        <Columns $mobileItemSize="50%" $mobileBreakpoint={767} $verticalAlignment="stretch">
          {tA('specs.items').map(item => (
            <HighlightColumn>
              <ImageContainer>
                <ImageLoader ratio={"1:1"} source={item.image.src} />
              </ImageContainer>
              <SmallP>
                <b>{item.title}</b>
              </SmallP>
            </HighlightColumn>
          ))}
        </Columns>
      </div>
      <Gap />
      <Columns $mobileBreakpoint={767} $noCellPadding $flexItems $verticalAlignment="stretch">
        {tA('cards.items').map(item => (
          <Card to={item.button.link} $bgColor={item.backgroundColor}>
            {item.image && <ImageLoader ratio={"1:1"} source={item.image.src} />}
            <CardContent>
              <Title>
                <span style={{ marginRight: '8px' }} dangerouslySetInnerHTML={{ __html: parseMarkdown(item.title) }} />
                <ArrowForwardIcon />
              </Title>
              <Gap x={0.5} />
              <P>{item.description}</P>
            </CardContent>
          </Card>
        ))}
      </Columns>
      <Gap />
      <SimplifiedFooter />
    </Root>
  )
}

//export default withFirebase(DisambiguationHome)
export default DisambiguationHome