import React, { forwardRef } from "react";
import styled from "styled-components";

const Root = styled.section`
  position: relative;
  align-items: ${({ $align }) => ($align ? $align : "center")};
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`;

const ItemChild = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    width: 200%;
  }
`;

const Item = styled.div`
  @media screen and (min-width: 769px) {
    width: 50%;
    flex: 50% 0 0;
    & + & ${/* sc-sel */ ItemChild} {
      margin-left: -100%;
    }
  }
`;

const MultimediaNoOverlap = (props, ref) => {
  return (
    <Root ref={ref} $align={props.$align}>
      {React.Children.map(props.children, (child) => (
        <Item>
          <ItemChild>{child}</ItemChild>
        </Item>
      ))}
    </Root>
  );
};

export default forwardRef(MultimediaNoOverlap);
