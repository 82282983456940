import styled, { css, createGlobalStyle } from "styled-components";
import { colors } from "../../../shared/ui/colors";
import { PlainButton } from "../../../shared/ui/button";
import { propTernary } from "../../../shared/utils/styled";
import { prop, propOr } from "ramda";

export const GlobalStyle = createGlobalStyle`
  body {
    padding-top: ${propTernary("$fixed", 80, 0)}px;
  }
  strong {
    font-weight: 700!important;
  }
`;


export const SimplifiedHeaderStyled = styled.header`
  position: absolute;
  background: white;
  height: 80px;
  top: 20px;
  left: 0;
  width: 200px;
  z-index: 10;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-out;
  margin-top: ${({ hide }) => (hide ? "-80px" : "0")};
  @media screen and (max-width: 768px) {
    position: fixed;
    width: 100%; 
    top: 0;
  }
`;

export const BasicHeader = styled.header`
  position: ${propTernary("$fixed", "fixed", "relative")};
  background: white;
  height: 80px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-out;
  margin-top: ${({ hide }) => (hide ? "-80px" : "0")};
`;

export const StyledHeader = styled(BasicHeader)`
  background: ${({ $siteArea }) => colors.secondary[$siteArea]};
  display: flex;
  justify-content: center;
`;

export const HeaderText = styled.div`
  padding: 5px 150px;
  text-align: center;
  display: flex;
  align-items: center;
  color: ${colors.lightGray};
  * { margin: 0; }
  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const LogoHolder = styled.div`
  max-width: 100%;
  position: ${propTernary("$fixed", "fixed", "absolute")};
  left: 15px;
  top: 6px;
  z-index: 101;
  transition: all 0.2s ease-out;
  margin-top: ${({ hide }) => (hide ? "-80px" : "0")};
  @media screen and (max-width: 767px) {
    left: 0;
  }
`;

export const CenteredLogoHolder = styled.div`
  max-width: 100%;
  margin: auto;
  z-index: 101;
  top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const HeaderButtons = styled.div`
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 80px;
  position: ${propTernary("$fixed", "fixed", "absolute")};

  @media screen and (max-width: 480px) {
    right: 60px;
  }
`

const HeaderButton = styled(PlainButton)`
  cursor: pointer;
  color: ${colors.white};
  position: relative;
  z-index: 101;
  padding: 0 15px;
  height: 80px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  @media screen and (max-width: 480px) {
    padding: 0 8px;
  }
`

export const UserSettingButton = styled(HeaderButton)`
  & > div + div {
    margin-left: 15px;
  }
  & span {
    font-weight: 700;
    display: flex;
    text-align: center;
    justify-content: center;
    color: ${propOr(colors.white, '$color')};
  }
`;

export const CartButton = styled(HeaderButton)`
  &:before {
    content: '';
    z-index: -1;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${propTernary('$isCartOpen', colors.accent, 'rgba(0,0,0,0.25)')};
    transition: all .3s ease-out;
  }
  &:after {
    display: ${({ $quantity }) => $quantity === 0 ? 'none' : 'block'};
    content: '${prop('$quantity')}';
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: 600;
    width: 18px;
    height: 18px;
    margin-top: -16px;
    border-radius: 50%;
    background: ${colors.accent};
  }
`;

export const MenuButton = styled(PlainButton)`
  cursor: pointer;
  position: ${propTernary("$fixed", "fixed", "absolute")};
  z-index: 101;
  right: 0;
  top: 0;
  height: 80px;
  padding: 0 15px;
  margin-top: ${({ hide }) => (hide ? "-80px" : "0")};
  transition: all 0.2s ease-out;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  @media screen and (max-width: 480px) {
    padding: 0 8px;
  }
`;

export const MenuIcon = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 40px;
  }

  span {
    color: ${({ $color }) => $color || 'white'};
    font-size: 12px;
    right: 0;
    top: 4px;
    position: absolute;
    ${propTernary(
      "isOpen",
      css`display: none;`,
      ""
    )}
  }

  :before,
  :after {
    content: "";
    transition: all 0.3s ease-out;
    height: 3px;
    background: ${({ $color }) => $color || colors.white};
    width: 100%;
    display: block;
    ${propTernary(
      "isOpen",
      css`
        margin-bottom: -3px;
        transform: rotate(-45deg);
      `,
      "margin-top: 10px;"
    )}
  }
  :after {
    margin-top: 6px;
    width: calc(200% / 3);
    margin-left: auto;
    ${propTernary(
      "isOpen",
      css`
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        transform: rotate(45deg);
      `,
      ""
    )}
  }
`;
