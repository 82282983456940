export const colors = {
  primary: "#ff3700",
  secondary: {
    office: "#daa900",
    ultra: "#1E4F62",
    spa: "#2c543c",
    gaming: "#440e62",
    generic: "#ffffff"
  },
  accent: "#ff3700",
  white: "#FFFFFF",
  black: "#000000",
  wood: "#d8ad7a",
  naturalOak: "#d8ad7a",
  darkGray: "rgb(80, 80, 80)",
  midGray: "#808080",
  nickelGray: "rgb(191, 191, 191)",
  gray: "rgb(204, 204, 204)",
  lightGray: "rgb(235, 235, 235)",
  lighterGray: "rgb(243, 243, 243)",
  transparent: "transparent",
  fakeBlack: "#1e1e1e",
};
