import React from "react";
import { Tag } from "bbcode-to-react";
import ProjectList from "../../client/canary/project-request/project-list";

class ProjectListTag extends Tag {
  toReact() {
    return <ProjectList />
  }
}

export default ProjectListTag;
