import { identity } from "ramda";
import React, { createContext, useContext, useState } from "react";

const getInitialState = () => ({
  checkoutData: {
    hasPurchased: false,
    orderNumber: null,
    isProcessing: false,
  },
  promoCodeData: {
    code: "",
    isLoading: false,
    appliedCode: null,
  },
  vatData: {
    valid: false,
    isLoading: false,
  },
  shippingPrice: -1,
  shippingDetails: {
    isLoaded: false,
    userType: "private",
    companyName: "",
    email: "",
    vatNumber: "",
    country: "",
    firstName: "",
    lastName: "",
    address: "",
    postCode: "",
    city: "",
    phone: "",
    isShippingDifferent: false,
    shippingCountry: "",
    shippingFirstName: "",
    shippingLastName: "",
    shippingAddress: "",
    shippingPostCode: "",
    shippingCity: "",
    shippingPhone: "",
  },
});

export const CheckoutContext = createContext(getInitialState());

export const CheckoutContextProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(getInitialState());

  return (
    <CheckoutContext.Provider value={{ state, setState }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = (selector = identity) => {
  const { state, setState } = useContext(CheckoutContext);
  return [selector(state), setState];
};
