export const useResources = (scope, ...initialKeys) => {
	const getResource = (...keys) => {
		const fullKey = scope + ":" + keys.join(".")
		if (window.__PAGECONTENT) {
			return window.__PAGECONTENT[fullKey]
		} else {
			return fullKey
		}
	}

	const scopeGetResource = (...resourceScope) => getResource.bind(null, ...resourceScope)

	return [scopeGetResource(...initialKeys), scopeGetResource]
}