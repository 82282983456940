import React, { useState, useEffect } from 'react'
import ImageLoader from "shared/ui/image-loader";
import { ImageWrapper, GalleryWrapper, Thumb, Gallery, ImageRatioBox } from './styled'
import {getViewport} from "../../../../vicoustic/utility/get-viewport";
import { withModalManagement } from "shared/modal-manager";
import { YT_VIDEO_MODAL } from './modals';

const ImageSwitcher = props => {
  const {
    image,
    gallery = [],
    videos = [],
    modalActions,
  } = props

  const [currentMain, setCurrentMain] = useState(image)

  useEffect(() => {
    if (currentMain !== image) {
      setCurrentMain(image)
    }
  }, [image])

  const onClick = image => () => {
    setCurrentMain(image)
  }

  const triggerVideo = videoId => {
    modalActions.triggerModal(YT_VIDEO_MODAL, {
      videoId,
    });
  }

  return (
    <ImageWrapper>
      <ImageRatioBox>
        {currentMain && <ImageLoader ratio="4:3" source={getViewport(currentMain.src, 'm')} alt={currentMain.title} />}
      </ImageRatioBox>
      <GalleryWrapper>
        <Gallery>
          {image && (
            <Thumb $selected={image === currentMain} onClick={onClick(image)} key={image.src}>
              <ImageLoader ratio="4:3" source={getViewport(image.src, 'xs')} alt={image.title} />
            </Thumb>
          )}
          {gallery.map(img => (
            <Thumb $selected={img === currentMain} onClick={onClick(img)} key={img.src}>
              <ImageLoader ratio="4:3" source={getViewport(img.src, 'xs')} alt={img.title} />
            </Thumb>
          ))}
          {videos.map(video => (
            <Thumb onClick={() => triggerVideo(video.videoId)} key={video.videoId}>
              <ImageLoader ratio="4:3" source={getViewport(video.thumbnail?.src, 'xs')} alt={video.thumbnail?.title} />
            </Thumb>
          ))}
        </Gallery>
      </GalleryWrapper>
    </ImageWrapper>
  )
}

export default withModalManagement(ImageSwitcher)
