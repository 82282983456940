import React from "react";
import styled from "styled-components";

import Columns from "./columns";
import { PlainButton } from "../button";
import { colors } from "../colors";
import { propTernary } from "../../utils/styled";

const Tab = styled(PlainButton)`
  padding: 10px;
  margin: 0 6px;
  position: relative;
  color: ${propTernary("active", colors.primary, colors.gray)};
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: ${propTernary("active", 100, 0)}%;
    transition: all 0.2s ease-out;
    height: 2px;
    background: ${colors.primary};
  }
`;

const Tabs = (props) => {
  const { active, setActive, children, autoSize } = props;
  return (
    <Columns $horizontalAlignment={"center"} $keepColumnSize $autoSize={autoSize} $noCellPadding $wrap="wrap">
      {React.Children.map(children, (child, index) => (
        <Tab active={active === index} onClick={() => setActive(index)}>
          {child}
        </Tab>
      ))}
    </Columns>
  );
};

export default Tabs;
