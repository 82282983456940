import { propOr } from "ramda";
import styled from "styled-components";

export const Stack = styled.div`
  display: flex;
  flex-direction: ${propOr('column', 'direction')};
  justify-content: ${propOr('flex-start', 'justifyContent')};
  align-items: ${propOr('flex-start', 'alignItems')};
  flex-wrap: ${propOr('nowrap', 'wrap')};
  & > * {
    margin: 16px;
    flex: 0 1 0;
  }
`
