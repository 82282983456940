import React from "react";
import { useField } from "formik";
import Input from "../../../vicoustic/forms/input";

export const TextField = (props) => {
  const [field, meta] = useField(props);
  let extraProps = {};

  if (meta.touched && meta.error) {
    extraProps = {
      error: true,
      helperText: meta.error,
    };
  }
  return <Input nativeEvents fullWidth {...field} {...props} {...extraProps} />;
};
