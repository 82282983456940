import reactParser from "bbcode-to-react";

import Video from "./react-tags/video";
import Image from "./react-tags/image";
import Link from "./react-tags/link";
import Quote from "./react-tags/quote";
import Youtube from "./react-tags/youtube";
import Table from "./react-tags/table";
import Sup from "./react-tags/sup";
import ProjectListTag from "./react-tags/project-list";
import BBGrid, { BBGridItem } from "./react-tags/bb-grid";
import Icon from "./react-tags/icon";
import UnorderedList from "./react-tags/unordered-list";
import ListItem from "./react-tags/list-item";
import OrderedList from "./react-tags/ordered-list";

reactParser.registerTag("video", Video);
reactParser.registerTag("img", Image);
reactParser.registerTag("url", Link);
reactParser.registerTag("link", Link);
reactParser.registerTag("button", Link);
reactParser.registerTag("quote", Quote);
reactParser.registerTag("youtube", Youtube);
reactParser.registerTag("icon", Icon);
reactParser.registerTag("ul", UnorderedList);
reactParser.registerTag("ol", OrderedList);
reactParser.registerTag("li", ListItem);
reactParser.registerTag("table", Table);
reactParser.registerTag("sup", Sup);
reactParser.registerTag("projectlist", ProjectListTag);
reactParser.registerTag("grid", BBGrid);
reactParser.registerTag("item", BBGridItem);

const parser = {
  parse: (text) => reactParser.toReact(text),
};
export default parser;
