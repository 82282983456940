import React from "react";
import { Center, ImageContainer, RelatedProduct } from "../styled";
import { P, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import ImageLoader from "shared/ui/image-loader";
import parseMarkdown from "../../../../shared/utils/markdown";

const getRelatedProducts = (items) => items.map(item => (
  <RelatedProduct to={item.link}>
    <Center>
      <ImageContainer>
        <ImageLoader
          ratio={"4:3"}
          source={item.image.src}
        />
      </ImageContainer>
      <Gap />
      <P $bold>{item.title}</P>
      <Gap />
      <SmallP as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(item.description) }} />
    </Center>
  </RelatedProduct>
))

export default getRelatedProducts;
