//import React, { useRef } from 'react'
//import Modal from "./modal";
//import { Button } from "./button";

const VacationsModal = props => {
  //const modalRef = useRef(null)
  return null;
  /*return (
    <Modal initiallyOpen ref={modalRef}>
      <div style={{ maxWidth: '500px' }}>
        Vicoustic offices and factories will be closed for the Summer Vacation from the 14th of August to the 4th of September. All orders during this period will only be processed after the 4th of September.
        <br/>
        Thank you for your understanding
        <br />
        <br />
        <Button onClick={() => modalRef.current.close()}>Ok</Button>
      </div>
    </Modal>
  )*/
}

export default VacationsModal