import React, { Children } from "react";
import reactParser, { Tag } from "bbcode-to-react";

class BBGrid extends Tag {
  toReact() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', margin: '0 -12px' }}>
        {this.getComponents()}
      </div>
    );
  }
}

export class BBGridItem extends Tag {
  toReact() {
    return (
      <div style={{ flex: '1 0 290px', margin: '12px' }}>{this.getComponents()}</div>
    );
  }
}

export default BBGrid;
