import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom';
import { colors } from '../../../shared/ui/colors';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { Button } from '../../../shared/ui/button';

export const Root = styled.main`
  max-width: 1600px;
  width: 100%;
  position: relative;
  padding: 0 30px 50px;
  margin: auto;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`
export const List = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  & > li {
    flex: 0 0 20%;
  }
`


export const StyledLink = styled(Link)`
  &:not(:hover),
  &:not(:active) {
    color: inherit;
  }
`

export const Card = styled.div`
  min-width: 290px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  text-align: center;
  align-items: center;
`

export const ButtonList = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

export const FilterButtonList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const FilterButton = styled.div`
  margin: 0 12px 12px 0;
  ${({ $active }) => $active ? css`
    & > * {
      color: ${colors.primary};
      border-color: ${colors.primary};
    }
  ` : ''}
`

export const AddToCartIcon = styled(AddShoppingCartIcon)``

export const AddToCartButton = styled(Button)`
  position: relative;
  padding-left: 28px;
  vertical-align: middle;
  & > ${AddToCartIcon} {
    position: absolute;
    left: 5px;
    top: 50%;
    font-size: 18px;
    transform: translateY(-50%);
  }
  @media screen and (max-width: 991px) {
    padding: 4px 8px 4px 28px;
  }
`

export const GetQuoteButtonStyled = styled(Button)`
  position: relative;
  vertical-align: middle;
  @media screen and (max-width: 991px) {
    padding: 4px 8px;
  }
`

export const VariationSelectorWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  
`