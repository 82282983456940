import styled from 'styled-components'
import { Button, FakeButton, PlainButton } from '../button'
import { colors } from '../colors'
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import { H6 } from '../headings';

export const Root = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  padding: 15px 30px;
  width: 100%;
  z-index: 50;
  background: ${colors.lightGray};
  @media screen and (max-width: 991px) {
    padding: 15px;
  }
`

export const CheckoutButtonWrapper = styled.div`
  align-self: flex-end;
  ${({ $disabled }) => $disabled ? `
    pointer-events: none;
    opacity: 0.4;
  ` : ''}
  a {
    color: inherit;
  }
`

export const CheckoutButton = styled(FakeButton)`
  width: 200px;
`

export const CartIconWrapper = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  width: 50px;
  height: 50px;
  background: ${colors.primary};
  color: ${colors.white};
  border-radius: 50%;
  display: flex; 
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 991px) {
    width: 42px;
    height: 42px;
  }
`

export const CartTitle = styled(H6)`
  font-weight: 600;
`

export const CartItems = styled.div`
  width: 100%;
`

export const CartPanel = styled.div`
  background: ${colors.lightGray};
  padding: 30px 30px 80px;
  max-height: 100%;
  overflow: auto;
  ${({ $isOpen }) => $isOpen && 'box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.15)'};
  height: 100%;
  z-index: -1;
  &, &:after {
    max-width: 584px;
    position: fixed;
    right: 0;
    top: 80px;
    width: 100%;
    transition: transform .2s ease-out;
    transform: ${({ $isOpen }) => $isOpen ? 'none' : 'translateX(100%)'};
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media screen and (max-width: 991px) {
    padding: 30px 15px 80px;
  }

`

export const CartItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.gray};
  img {
    width: 110px;
  }
`

export const AccessoriesPlug = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`

export const QuantityWrapper = styled.div`
  display: flex;
  align-items:center;
`
export const QuantityPicker = styled.input`
  width: 40px !important;
  text-align: center;
  margin: 0 0 0 8px !important;
`

export const RemoveButton = styled(PlainButton)`
  padding: 5px;
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  :hover {
    background: rgba(0,0,0,0.1);
  }
`

export const Price = styled.span`
  flex-grow: 1;
  text-align:right;
  padding-right: 10px;
`

export const TotalLine = styled.div`
  text-align: right;
  font-weight: 600;
  padding: 5px 42px 5px 0;
  position: relative;
  span:last-of-type {
    margin-left: 80px;
    width: 80px;
    display: inline-block;
    max-width: 100%;
  }
  ${RemoveButton} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`