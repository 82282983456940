import { assoc, pipe } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { getCountryCookie } from "../utils/cookies";

export const useConfiguratorState = (siteArea) => {
  const region = getCountryCookie()
  const localStorageConfiguratorKey = `vicboothConfiguration_${siteArea}_${region}`

  const [configurator, setConfiguratorState] = useState({
    loaded: false,
    items: [],
    url: '',
    preview: '',
  })

  useEffect(() => {
    // load configuration from localstorage
    const localConfig = localStorage.getItem(localStorageConfiguratorKey)
    try {
      setConfiguratorState({
        loaded: true,
        items: !localConfig ? [] : JSON.parse(localConfig),
        url: localStorage.getItem(`${localStorageConfiguratorKey}_url`),
        preview: localStorage.getItem(`${localStorageConfiguratorKey}_preview`)
      })
    } catch (e) {
      console.log('Config loading failed.')
    }
  }, [])


  useEffect(() => {
    // save cart to localstorage
    if (!configurator.loaded) return
    localStorage.setItem(localStorageConfiguratorKey, JSON.stringify(configurator.items))
    localStorage.setItem(`${localStorageConfiguratorKey}_url`, configurator.url),
    localStorage.setItem(`${localStorageConfiguratorKey}_preview`, configurator.preview)
  }, [configurator])


  const updateConfiguration = (newConfig, url, preview) => {
    setConfiguratorState(pipe(
      assoc('items', newConfig),
      assoc('url', url),
      assoc('preview', preview),
    ))
  }

  const removeConfiguration = () => {
    setConfiguratorState(pipe(
      assoc('items', []),
      assoc('url', ''),
      assoc('preview', ''),
    ))
  }

  const configuratorActions = useMemo(
    () => ({
      updateConfiguration,
      removeConfiguration,
    }),
    [configurator]
  )

  return {
    configurator,
    configuratorActions,
  }
}