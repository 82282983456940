import React from "react";
import { useTranslations } from "../../../../../shared/context/translations";
import { P, SmallP } from "../../../../../shared/ui/headings";
import { BlueprintButton, List, BlueprintIcon } from "./styled";
import styled from "styled-components";
import { Stack } from "../../../../../shared/ui/stack";

const Root = styled.div`
  padding-top: 10px;
`

const BlueprintSelector = (props) => {
  const { blueprints, selectBlueprint, selectedBlueprint, children } = props;
  const isActive = (bp) => bp.id === selectedBlueprint;
  const t = useTranslations('configurator')
  return (
    <Root>
      <Stack direction="row" alignItems="center">
        <SmallP $bold style={{ whiteSpace: 'nowrap', margin: '0 24px 0 0' }}>{t('sizeAndColorSelector')}</SmallP>
        {children}
      </Stack>
      <List>
        {blueprints.map((bp) => (
          <li key={bp.id}>
            <BlueprintButton
              active={isActive(bp)}
              onClick={() => selectBlueprint(bp)}
            >
              <BlueprintIcon $active={isActive(bp)} src={bp.icon?.src} />
              <P $bold>{bp.name}</P>
            </BlueprintButton>
          </li>
        ))}
      </List>
    </Root>
  );
};

export default BlueprintSelector;
