import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { getUrlVars, setUrlVar } from '../../../shared/utils/url'
import { intersperse, propEq } from "ramda";
import { PlainButton } from "../../../shared/ui/button";
import { useTranslations } from "../../../shared/context/translations";
import { useSiteAreaContext } from "../../../shared/context/site-area";

const hasCoords = (showroom) =>
  showroom.hasOwnProperty("latitude") &&
  showroom.hasOwnProperty("longitude") &&
  showroom.latitude !== null &&
  showroom.longitude !== null;

const buildCenter = (showroom) => ({
  lat: showroom.latitude,
  lng: showroom.longitude,
});

const ShowroomList = (props) => {
  /*const { active, setActive } = useActiveManagement(
    props.showrooms,
    props.active || 0
  );*/
  const {active, setActive} = props
  if (props.showrooms.length == 0) {
    return (
      <div className="reseller-list">
        <p className="reseller-pagination">{props.noResults}</p>
      </div>
    );
  }

  /*useEffect(() => {
    setActive(props.active);
  }, [props.active]);
*/
  return (
    <div className="reseller-list">
      <ul style={{ transform: "translateX(" + active * -75 + "%)" }}>
        {props.showrooms.map((showroom, idx) => (
          <li
            key={showroom.id}
            className={idx == active ? "active" : ""}
            onClick={() => setActive(idx)}
          >
            <p
              onClick={
                hasCoords(showroom)
                  ? () => props.onClick(buildCenter(showroom))
                  : null
              }
            >
              <b>{showroom.name}</b>
            </p>
            {showroom.description ? (
              <p className="small">
                <b>{showroom.description}</b>
              </p>
            ) : null}
            {showroom.address ? (
              <p className="small">{showroom.address}</p>
            ) : null}
            {showroom.availableBooths ? <>
              <p className="small">
                <b>{props.availableBoothsText}</b>
                <br />
                {intersperse(<br />, showroom.availableBooths.map(ab => (
                  <span>{ab}</span>
                )))}
              </p>
            </> : null}
            {showroom.linkUrl ? (
              <p className="small">
                <a href={showroom.linkUrl} target="_blank">
                  {showroom.linkText}
                </a>
              </p>
            ) : null}
          </li>
        ))}
      </ul>
      <p className="reseller-pagination">
        <PlainButton onClick={() => setActive(active - 1)} disabled={active == 0}>
          <i className="fas fa-angle-left" />
        </PlainButton>
        <span>{active + 1 + "/" + props.showrooms.length}</span>
        <PlainButton
          onClick={() => setActive(active + 1)}
          disabled={active == props.showrooms.length - 1}
        >
          <i className="fas fa-angle-right" />
        </PlainButton>
      </p>
    </div>
  );
};

const Showrooms = (props) => {
  const [mapInitialized, setMapInitialized] = useState(false);
  const [data, setData] = useState(null);
  const [activeShowroomIdx, setActiveShowroomIdx] = useState(0);
  const selectedShowroomRef = useRef(null)
  const urlVars = getUrlVars();

  const sortPredicate = (a, b) =>
    (parseInt(b.relevancy) || 0) - (parseInt(a.relevancy) || 0)
  
  const getMapCenter = () => {
    if (props.useQueryString && selectedShowroomRef.current && hasCoords(selectedShowroomRef.current)) {
      return buildCenter(selectedShowroomRef.current)
    }
    return { lat: 43.834526782236814, lng: 0 };
  };

  useEffect(() => {
    if (!mapInitialized && data) {
      window.__initializeMap = function () {
        window.__map = new window.google.maps.Map(
          document.getElementById("map"),
          {
            zoom: 2,
            center: {lat: 43.834526782236814, lng: 0},
            disableDefaultUI: true,
            styles: [
              { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
              { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
              {
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
              },
              {
                elementType: "labels.text.stroke",
                stylers: [{ color: "#f5f5f5" }],
              },
              {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [{ color: "#bdbdbd" }],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#ffffff" }],
              },
              {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#dadada" }],
              },
              {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
              },
              {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
              {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
              },
              {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#c9c9c9" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
            ],
          }
        );
        setMapInitialized(true);
        setTimeout(() => {
          window.__map?.panTo(getMapCenter())
          if (props.useQueryString && urlVars.showroom) {
            window.__map?.setZoom(8)
          }
        }, 100);
      };
      if (window.google && window.google.maps && data) {
        window.__initializeMap();
      }
    }
  }, [data]);

  useEffect(() => {
    axios.get("/api/showrooms").then((result) => {
      const sortedData = result.data.sort(sortPredicate)
      selectedShowroomRef.current = sortedData[0]
      
      if (props.useQueryString && urlVars.showroom) {
        const selectedShowroom = sortedData.indexOf(propEq('name', decodeURIComponent(urlVars.showroom)))
        if (selectedShowroom !== -1) {
          selectedShowroomRef.current = sortedData[selectedShowroom]
          setActiveShowroomIdx(selectedShowroom)
        }
      }
      setData(sortedData);
    });
  }, []);

  const setMapCenter = (center) => {
    window.__map.setZoom(5);
    window.__map.panTo(center);
  };

  useEffect(() => {
    if (mapInitialized && data) {
      let markers = [];

      for (var i = 0; i < data.length; i++) {
        var showroom = data[i];
        if (showroom.latitude && showroom.longitude) {
          
          var marker = new window.google.maps.Marker({
            position: { lat: showroom.latitude, lng: showroom.longitude },
            map: window.__map,
            title: showroom.name,
            animation: google.maps.Animation.DROP,
          });
          marker.addListener(
            "click",
            ((_marker, idx) => () => {
              setActiveShowroomIdx(idx);
              setMapCenter(_marker.getPosition());
            })(marker, i)
          );
          markers.push(marker);
        }
      }
      if (markers.length != 0) {
        window.__map.setZoom(2);
        window.__map.panTo({
          lat: 43.834526782236814,
          lng: 0,
        });
      }
      return () => {
        for (var i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
      };
    }
  }, [mapInitialized, data]);

  useEffect(() => {
    if (props.useQueryString && data) {
      setUrlVar(
        'showroom',
        data[activeShowroomIdx]
          ? encodeURIComponent(data[activeShowroomIdx].name)
          : null
      )
    }
  }, [activeShowroomIdx])
  
  const { siteArea } = useSiteAreaContext()
  const t = useTranslations(`homepageSections.${siteArea}.showrooms`)
  return (
    <div className="block-map">
      <div id="map" style={{opacity: data ? 1 : 0}} />
      <div className="location-selector">
        <div className="text-holder">
          <h4>
            <b>{t('title')}</b>
          </h4>
          <p>
            <b style={{ fontSize: '17px' }}>{t('subTitle')}</b>
            <br />
            {t('description')}
          </p>
          {data ? (
            <ShowroomList
              noResults={'No showrooms'}
              availableBoothsText={t('availableBooths')}
              key={'showrooms'}
              active={activeShowroomIdx}
              showrooms={data}
              onClick={setMapCenter}
              setActive={setActiveShowroomIdx}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const ShowroomsInViewWrapper = (props) => {
  const [ref, inView, entry] = useInView({
    treshold: 0.05,
    triggerOnce: true,
  });

  return <div ref={ref}>{inView && <Showrooms {...props} />}</div>;
};

export default ShowroomsInViewWrapper;
