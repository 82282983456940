import React, { forwardRef } from 'react'
import { useSiteAreaContext } from '../../../shared/context/site-area'
import { colors } from '../../../shared/ui/colors'
import Showrooms from './showrooms'
import { Style } from './styled'

const ShowroomsContainer = (props, ref) => {
  const { siteArea } = useSiteAreaContext()

  return (
    <div ref={ref}>
      <Style bgColor={colors.secondary[siteArea]} />
      <Showrooms />
    </div>
  )
}

export default forwardRef(ShowroomsContainer)
