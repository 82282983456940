import { useEffect, useState } from "react";
import axios from "axios";
import { useCurrentUser } from "./use-current-user";

const emptyObject = {};

export const useData = (
  url,
  options = emptyObject,
  shouldCall = true,
  debounce = 0,
  initialData = null
) => {
  const { profile } = useCurrentUser()
  const [localUrl, setLocalUrl] = useState(url);
  const [state, setState] = useState(
    initialData !== null
      ? {
          state: "FULFILLED",
          result: initialData,
          error: null,
          url: url,
        }
      : {
          state: "PENDING",
          result: null,
          error: null,
        }
  );

  let tout = null;
  useEffect(() => {
    if (debounce) {
      tout = setTimeout(() => {
        setLocalUrl(url);
      }, debounce);
      return () => clearTimeout(tout);
    } else {
      setLocalUrl(url);
    }
  }, [url, debounce]);

  useEffect(() => {
    if (state.url === localUrl || !shouldCall || !profile.isLoaded) return;
    setState({
      state: "PENDING",
      result: state.result,
      error: null,
    });
    
    const headers = profile.isLoaded && profile.token 
      ? {'Authorization': "Bearer " + profile.token.token}
      : {}
    
    let frozenUrl = localUrl;
    axios({
      url: frozenUrl,
      withCredentials: true,
      method: "get",
      headers,
      ...options,
    })
    .then((result) => {
      if (frozenUrl != localUrl) return;

      setState({
        state: "FULFILLED",
        result: result.data,
        error: null,
      });
    })
    .catch((error) => {
      if (frozenUrl != localUrl) return;

      setState({
        state: "REJECTED",
        result: null,
        error: error,
      });
    });
  }, [localUrl, options, profile.isLoaded]);
  return state;
};
