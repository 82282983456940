import React from 'react';


const CheckBox = props => {
	const preventBubbling = e => {
		e.stopPropagation()
	}
	return (  
		<div className={"switch"} onClick={preventBubbling}>
			<label className={!props.offLabel ? "only-on" : ""}>
				{props.offLabel}
				<input 
					type="checkbox" 
					onChange={(e) => props.onChange(e.target.checked)} 
					checked={props.checked} 
					disabled={props.disabled} 
				/>
				<span className="lever"></span>
				{props.onLabel}
			</label>
		</div>
	)
}

export default CheckBox;
