import { keys, pipe, forEach, mergeWith } from "ramda";

export const setUrl = (vars = "") => {
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    vars;
  window.history.replaceState(null, null, newurl);
};

export const getUrlVars = () => {
  let currentVars = {},
    hash;
  let hashes =
    window.location.search.length > 0
      ? decodeURIComponent(window.location.search.slice(1)).split("&")
      : [];
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    currentVars[hash[0]] = hash[1];
  }
  return currentVars;
};

export const setUrlVar = (name, value) => {
  if (history.replaceState) {
    let currentVars = getUrlVars();
    if (value == null || value == "") {
      delete currentVars[name];
    } else {
      currentVars[name] = value;
    }
    let newQueryStr = "?";
    for (let k in currentVars) {
      newQueryStr += k + "=" + currentVars[k] + "&";
    }
    setUrl(newQueryStr.slice(0, -1));
    return true;
  }
  return false;
};

export const setUrlVars = (obj, options = {}) => {
  let newVars = obj;
  if (options.clearUrlVars) {
    newVars = mergeWith((l) => (l ? l : null), obj, getUrlVars());
  }
  pipe(
    keys,
    forEach((k) => setUrlVar(k, obj[k]))
  )(newVars);
};
