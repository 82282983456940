import { prop, propOr } from "ramda";
import { createGlobalStyle } from "styled-components";

export const Style = createGlobalStyle`
  .block-map {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0;
    width: 100%;
    
  }
  .block-map #map {
    -ms-flex-preferred-size: calc(200% / 3);
    flex-basis: calc(200% / 3);
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-top: calc(100% / 3 + 150px);
  }
  .block-map .location-selector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: calc(100% / 3);
  }
  .block-map .location-selector .text-holder {
    background: ${prop('bgColor')};
    position: relative;
    padding: 100px 0 50px 50px;
    color: ${propOr('white', 'color')};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
    
  }
  .block-map .location-selector .text-holder .reseller-list li * + * {
    margin-top: 5px;
  }
  .block-map .location-selector .text-holder a {
    text-decoration: underline;
  }
  .block-map .location-selector .location-filters {
    margin: 50px -10px 30px;
    display: flex;
    flex-wrap: wrap;
  }
  .block-map .location-selector .input-field {
    width: 40%;
    margin: 10px;
  }
  .block-map .location-selector .reseller-list {
    position: relative;
    overflow: hidden;
    width: calc(100% + 50px);
    margin-left: -50px;
    padding-bottom: 0px;
  }
  .block-map .location-selector .reseller-list li p{
    white-space: pre-line;
  }

  .block-map .location-selector .reseller-list li > p: first-child {
    font-size: 20px;
    cursor: pointer;
  }
  .block-map .location-selector .reseller-list li > p: first-child: hover {
    text-decoration: underline;
    text-underline-position: under;
  }
  .block-map .location-selector .reseller-list ul{
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
  }
  .block-map .location-selector .reseller-list ul li{
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 50px;
    position: relative;
  }
  .block-map .location-selector .reseller-list ul: first-child li: not(.active): after {
    content: '';
    cursor: pointer;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
  }
  .block-map .location-selector .reseller-list .reseller-pagination {
    margin-left: 45px;
    margin-top: 30px;
  }
  .block-map .location-selector .reseller-list .reseller-pagination span {
    display: inline-block;
    min-width: 45px;
    text-align: center;
  }
  .block-map .location-selector .reseller-list .reseller-pagination button: focus,.block-map .location-selector .reseller-list .reseller-pagination button: active {
    background: none;
  }
  .block-map .location-selector .reseller-list .reseller-pagination button {
    cursor: pointer;
    margin: 0 5px;
  }
  .block-map .location-selector .reseller-list .reseller-pagination button[disabled] {
    cursor: default;
    opacity: 0.4;
  }
  .block-map .location-selector .reseller-list .reseller-nav {
    position: absolute;
    bottom: 0;
    left: 50px;
    flex-wrap: wrap;
    margin-left: -10px;
    max-width: calc(100% - 50px);
  }
  .block-map .location-selector .reseller-list .reseller-nav li {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    padding-left: 0;
  }
  .block-map .location-selector .reseller-list .reseller-nav li{
    margin-left: 10px;
    margin-bottom: 4px;
  }
  .block-map .location-selector .reseller-list .reseller-nav button{
    cursor: pointer;
    width: 10px;
    height: 10px;
    opacity: 0.6;
    background: white;
    border-radius: 50%;
  }
  .block-map .location-selector .reseller-list .reseller-nav li.active button{
    opacity: 1;
  }
  #where-to-buy #map {
    padding-top: calc(100% / 2);
  }
  #where-to-buy .location-selector {
    padding-bottom: 0;
  }
  #where-to-buy .location-selector .text-holder {
    padding-top: 170px;
  }
  @media screen and (max-width: 768px) {
    .block-map {
    display: block;
    }
    .block-map .location-selector {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    padding-bottom: 0;
    }
    .block-map .location-selector .text-holder,#where-to-buy .location-selector .text-holder {
    padding: 40px 0 30px 20px;
    }
    .block-map .location-selector .input-field {
    margin-top: 30px;
    }
  }

`