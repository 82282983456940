import styled from "styled-components";
import { propTernary } from "../../../../../shared/utils/styled";

export const Root = styled.div`
  position: fixed;
  bottom: 0px;
  left: calc(400% / 12);
  z-index: 4;
  width: 100%;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.8);
  @media screen and (max-width: 991px) {
    position: static;
    left: 0;
    width: 100%;
    padding: 12px;
    max-width: 100%;
    text-align: center;
    align-items: stretch;
    margin: auto;
    margin-bottom: 100px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  text-align: center;
  & > * {
    flex-grow: 0;
  }
  @media screen and (max-width: 991px) {
    max-width: 200px;
    flex-direction: column;
    align-items: stretch;
    margin: auto;
  }
`;

export const OrderButton = styled.a`
  pointer-events: ${propTernary("disabled", "none", "all")};
`;
