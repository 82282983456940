import { intersperse } from "ramda";
import React from "react";
import { FooterStyled } from "./styled";
import { useSiteAreaContext } from "../../../shared/context/site-area";
import { useTranslations } from "../../../shared/context/translations";

const Footer = (props) => {
  const { siteArea } = useSiteAreaContext()
  const currentYear = new Date().getFullYear();
  const t = useTranslations('footer');

  return (
    <FooterStyled $siteArea={siteArea}>
      <div>{currentYear} {t('text')} | {intersperse(' | ', t('links').map(l => <a key={l.link} target="_blank" href={l.link}>{l.text}</a>))}</div>
      <br />
      <div>
        <a href={t('norte2020.link')} target="_blank">
          <img src={t('norte2020.image.src') || "/images/certifications.png"} />
        </a>
      </div>
      {t('norte2020secondary.link') && t('norte2020secondary.image.src') && (
        <>
          <br />
          <div>
            <a href={t('norte2020secondary.link')} target="_blank">
              <img src={t('norte2020secondary.image.src')} />
            </a>
          </div>
        </>
      )}
    </FooterStyled>
  );
};

export default Footer;
