import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import Columns from "shared/ui/blocks/columns";
import { BUTTON_VARIANTS, FakeButton } from "shared/ui/button";
import { colors } from "shared/ui/colors";
import { Gap } from "shared/ui/gap";
import { H3, H6 } from "shared/ui/headings";
import ImageLoader from "shared/ui/image-loader";
import { useTranslations } from "../../../../shared/context/translations";
import MultimediaNoOverlap from "../../../../shared/ui/blocks/multimedia-no-overlap";
import { PageSection, TextHolder } from "../styled";
import { useBreakpoints } from "../../../../shared/hooks/use-breakpoints";

const LookCloser = (props, ref) => {
  const t = useTranslations('homepageSections.ultra.lookCloser')
  const { isMobile } = useBreakpoints()

  return (
    <PageSection $noPadding ref={ref}>
      <MultimediaNoOverlap $align="flex-end">
        <ImageLoader
          ratio={"1920:1080"}
          viewports={{
            m: t('imageDesktop.src'),
            xs: t('imageMobile.src'),
          }}
          viewportsRatio={{
            m: "1920:1080",
            xs: "16:9",
          }}
        />
        <TextHolder
          style={{
            maxWidth: isMobile ? '100%' : "600px",
            marginBottom: '25px',
            color: colors.white,
            padding: '30px 30px 18px',
            background: `${colors.secondary.ultra}${isMobile ? '' : 'cc'}`,
          }}
        >
          <H3 $bold as="h1">
            {t('title')}
          </H3>
          <Gap />
          <H6 $light>{t('subTitle')}</H6>
          <Gap />
          <Columns
            $wrap
            $noCellPadding
            $keepColumnSize
            $horizontalAlignment={"center"}
          >
            {t('buttons').map(button => (
              <div style={{ margin: '0 6px 12px 6px' }}>
                <Link to={button.link}>
                  <FakeButton
                    style={{ minWidth: '206px' }}
                    $withHover
                    $noWrap
                    variant={BUTTON_VARIANTS.FILLED}
                    >
                    {button.text}
                  </FakeButton>
                </Link>
              </div>
            ))}
            <div style={{ margin: '0 6px 12px 6px' }}>
              <a href={t('registerBoothButton.link')} target="_blank">
                <FakeButton
                  style={{ minWidth: '206px' }}
                  $withHover
                  variant={BUTTON_VARIANTS.FILLED}
                >
                  {t('registerBoothButton.text')}
                </FakeButton>
              </a>
            </div>
          </Columns>
        </TextHolder>
      </MultimediaNoOverlap>
    </PageSection>
  );
};

export default forwardRef(LookCloser);
