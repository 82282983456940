import styled from 'styled-components'

export const AccessoriesHero = styled.div`
  max-width: 1600px;
  margin: 50px auto 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const AccessoriesImageWrapper = styled.div`
  max-width: 500px;
  margin: auto;
  padding-left: 50px;
  img {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 30px 0;
  }
`

