import React, { forwardRef, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import {
  PageSection,
  TextHolder,
  PageSectionTitle,
  PageSectionHeader,
} from "../styled";
import { H4, P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import { BUTTON_VARIANTS, FakeButton } from "shared/ui/button";
import BackgroundVideo from "shared/ui/background-video";
import Multimedia from "shared/ui/blocks/multimedia";
import Columns from "shared/ui/blocks/columns";
import { useInView } from "react-intersection-observer";
import NewsletterBoundLink from "../../../../shared/ui/newsletter-bound-link";
import { useTranslations, useTranslationsArray } from "../../../../shared/context/translations";
import { useSiteAreaContext } from "../../../../shared/context/site-area";
import { sendTalkToSalesEvent } from "../../../../shared/events/talk-to-sales";

const MANUAL = {
  name: "Installation Manuals",
  file: "https://drive.google.com/drive/folders/1-RU4UAbviY3X-8gU_xEs6_gW35wRMTMJ?usp=sharing",
}

const Timelapse = (props, ref) => {
  const { siteArea } = useSiteAreaContext()
  const videoRef = useRef(null);
  const { t, tA } = useTranslationsArray(`homepageSections.${siteArea}.assembly`)
  const [inViewRef, inView, entry] = useInView({
    treshold: 0.05,
  });

  useEffect(() => {
    if (inView) videoRef.current.play();
  }, [inView]);

  return (
    <PageSection $invertColors $noPadding ref={ref}>
      <PageSectionHeader>
        <PageSectionTitle>{t('name')}</PageSectionTitle>
        <BlueprintIcon color={colors.white} />
      </PageSectionHeader>
      <Multimedia ref={inViewRef}>
        <BackgroundVideo
          ref={videoRef}
          ratio={"1920:1080"}
          ratioMobile={"360:500"}
          source={t('videoDesktop.src')}
          poster={t('posterDesktop.src')}
          sourceMobile={t('videoMobile.src')}
          posterMobile={t('posterMobile.src')}
          autoplay
          muted
          loop
          preload={"auto"}
          shade
        />
        <TextHolder $wide $textAlign={"left"}>
          <H4 $bold>{t('title')}</H4>
          <Gap />
          <P>{t('subTitle')}</P>
          <Gap />
          <Columns
            $wrap
            $noCellPadding
            $keepColumnSize
            $horizontalAlignment={"flex-start"}
          >
            <NewsletterBoundLink url={t('manualsButton.link')}>
              <FakeButton
                style={{ minWidth: '220px' }}
                $withHover
                $noWrap
                variant={BUTTON_VARIANTS.FILLED}
              >
                {t('manualsButton.text')}
              </FakeButton>
            </NewsletterBoundLink>
          </Columns>
          <Gap />
          <Columns
            $wrap
            $noCellPadding
            $keepColumnSize
            $horizontalAlignment={"flex-start"}
          >
            {tA('buttons').map(button => (
              <div style={{ margin: '0 12px 12px 0' }}>
                <Link to={button.link}>
                  <FakeButton
                    style={{ minWidth: '220px' }}
                    $withHover
                    $noWrap
                    variant={BUTTON_VARIANTS.FILLED}
                    >
                    {button.text}
                  </FakeButton>
                </Link>
              </div>
            ))}
            {t('talkToSalesButton.text') ? (
              <div style={{ margin: '0 12px 12px 0' }}>
                <a onClick={sendTalkToSalesEvent} href={t('talkToSalesButton.link')} target="_blank">
                  <FakeButton style={{ minWidth: '206px' }} $withHover $noWrap variant={BUTTON_VARIANTS.FILLED}>
                    {t('talkToSalesButton.text')}
                  </FakeButton>
                </a>
              </div>
            ) : null}
          </Columns>
        </TextHolder>
      </Multimedia>
    </PageSection>
  );
};

export default forwardRef(Timelapse);
