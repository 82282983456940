import React from 'react';
import { SmallP, H6 } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { getViewport } from "../../../../vicoustic/utility/get-viewport";
import { Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core'
import { Link } from 'react-router-dom';

const NewsCard = props => {
  const {
    ...article
  } = props

  return (
    <Card style={{ width: '100%' }}>
      <CardActionArea style={{ height: '100%' }} component={Link} to={`/news/${article.id}`}>
        {article.mainImage && <CardMedia
          component="img"
          image={getViewport(article.mainImage.src, 'xs')}
          alt={article.title}
        />}
        <CardContent>
          <SmallP>{article.date}</SmallP>
          <H6><b>{article.title}</b></H6>
          <Gap x={0.5} />
          <SmallP>{article.description}</SmallP>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default NewsCard
