import React from "react";
import { Tag } from "bbcode-to-react";

class OrderedList extends Tag {
  toReact() {
    return <ol className="ordered-list">{this.getComponents()}</ol>
  }
}

export default OrderedList;
