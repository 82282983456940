import React from 'react'
import { Helmet } from 'react-helmet'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import NewsList from './news-list'

const NewsContainer = props => {
  return (
    <>
      <Helmet>
        <title>VicBooth News</title>
        <meta name="description" content={"News about VicBooth Ultra, Office, Gaming and Spa, showcasing updates to Vicoustic’s acoustic booth, press releases, and new products. Learn how the VicBooth range won multiple awards and was certified for indoor air quality."} />
        <meta property="og:image" content={`/images/meta/VicBooth_news.webp`} />
        <meta property="og:title" content={`VicBooth News`} />
        <meta property="og:description" content={"News about VicBooth Ultra, Office, Gaming and Spa, showcasing updates to Vicoustic’s acoustic booth, press releases, and new products. Learn how the VicBooth range won multiple awards and was certified for indoor air quality."} />
        <meta property="og:site_name" content={`VicBooth`} />
      </Helmet>
      <NoContextHeader />
      <NewsList />
      <SimplifiedFooter />
    </>
  )
}

export default NewsContainer
