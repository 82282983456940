import React from 'react'
import {Tag} from 'bbcode-to-react'

import { Player } from 'video-react';

class Video extends Tag {
	toReact() {
		const attributes = {
			src: this.getContent(true),
			preload: "none",
			poster: this.params.poster,
		}
		return <Player {...attributes} />
	}
}

export default Video