import React from "react";
import styled from "styled-components";
import Modal from "shared/ui/modal";

export const ModalContent = styled.div`
  max-width: 800px;
  width: 100%;
`;

const YTVideoModal = (props) => {
  const { modalProps, videoId } = props;

  return (
    <Modal {...modalProps}>
      <ModalContent>
        <iframe
          style={{ maxWidth: "100%", width: '560px', margin: "auto", display: "block" }}
          width="560"
          height="315"
          src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`}
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </ModalContent>
    </Modal>
  );
};

export const YT_VIDEO_MODAL = 'ytVideoModal'
export const modalRegistry = {
  [YT_VIDEO_MODAL]: YTVideoModal,
}
