import { not } from 'ramda'
import React, { useState } from 'react'
import Collapsible from '../../../../../shared/ui/collapsible'
import { P } from '../../../../../shared/ui/headings'
import styled from 'styled-components'
import microdown from 'micro-down'
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { colors } from '../../../../../shared/ui/colors'
import { PlainButton } from '../../../../../shared/ui/button'

const FaqTitle = styled(PlainButton)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  &:hover {
    color: ${colors.primary};
  }
  p {
    font-weight: 700;
  }
`

const Root = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.lightGray};
  }
`

const FaqBody = styled.div`
  padding: 15px 0;
`

const FaqItem = props => {
  const { title, body } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(not)
  return (
    <Root>
      <FaqTitle onClick={toggleOpen}>
        <P>{title}</P>
        {isOpen ? <ClearIcon /> : <AddIcon />}
      </FaqTitle>
      <Collapsible isOpen={isOpen}>
        <FaqBody dangerouslySetInnerHTML={{ __html: microdown.parse(body) }} />
      </Collapsible>
    </Root>
  )
}

export default FaqItem