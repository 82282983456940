import React, { useRef } from "react";
import { useCurrentUser } from "../../../shared/hooks/use-current-user";
import { Button } from "../../../shared/ui/button";
import { H5 } from "../../../shared/ui/headings";
import { Gap } from "../../../shared/ui/gap";
import Modal from "../../../shared/ui/modal";
import LoginForm from "./login-form";
import styled, { css } from "styled-components";
import { colors } from "../../../shared/ui/colors";

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${colors.black};
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const LoginButton = (props) => {
  const { auth, profile } = useCurrentUser();

  const modalRef = useRef(null);
  const handleButtonClick = (e) => {
    e.preventDefault();
    modalRef.current?.open();
  };
  const onLogin = () => {
    modalRef.current?.close();
  };
  if (auth?.uid && profile.isLoaded) return null;

  return (
    <>
      <StyledLink
        disabled={auth?.uid && !profile.isLoaded}
        onClick={handleButtonClick}
      >
        or Login
      </StyledLink>
      <Modal initiallyOpen={false} ref={modalRef}>
        <H5>Sign in</H5>
        <Gap />
        <LoginForm onLogin={onLogin} />
      </Modal>
    </>
  );
};
