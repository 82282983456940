import React from 'react'
import { Helmet } from 'react-helmet'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import VideosList from './videos-list'

const VideosContainer = props => {
  return (
    <>
      <Helmet>
        <title>VicBooth Videos</title>
        <meta name="description" content={"Watch video reviews by VicBooth users and learn how to install VicBooth in installation procedures tutorials, presentations and timelapses. A full set of videos related to the acoustic booths developed by Vicoustic, the VicBooth Ultra, Office, Gaming and Spa."} />
        <meta property="og:image" content={`/images/meta/VicBooth_Videos.webp`} />
        <meta property="og:title" content={`VicBooth Videos`} />
        <meta property="og:description" content={"Watch video reviews by VicBooth users and learn how to install VicBooth in installation procedures tutorials, presentations and timelapses. A full set of videos related to the acoustic booths developed by Vicoustic, the VicBooth Ultra, Office, Gaming and Spa."} />
        <meta property="og:site_name" content={`VicBooth`} />
      </Helmet>
      <NoContextHeader />
      <VideosList />
      <SimplifiedFooter />
    </>
  )
}

export default VideosContainer
