import React, { useState } from "react";
import axios from "axios";
import Modal from "../../../vicoustic/client/commons/modal";
import Input from "../../../vicoustic/forms/input";
import CheckBox from "../../../vicoustic/forms/check-box";
import Select from "../../../vicoustic/forms/select";
import { countryList } from "../../../vicoustic/utility/countries";
import { useResources } from "../../../vicoustic/utility/use-resources";
import bbCodeParser from "../../../vicoustic/utility/bb-code-parser";
import { Button, BUTTON_VARIANTS } from "./button";
import { useTranslations } from "../context/translations";
import parseMarkdown from "../utils/markdown";

export const mainInterestAreas = [
  "Commercial & Offices",
  "Education",
  "Hi-Fi & Home Cinema",
  "Hospitality",
  "Industrial",
  "Professional Audio",
  "Residential",
];

const NewsletterModal = (props) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("PT");
  const [interestAreas, setInterestAreas] = useState([]);
  const [consent, setConsent] = useState(false);
  const [r] = useResources("general", "newsletterModal");
  const t = useTranslations('newsletterModal')

  const areFieldsFilled = () =>
    [email, firstName, lastName, country, interestAreas].reduce(
      (valid, field) => valid && field.length != 0,
      true
    ) && consent;

  const submitHandler = () => {
    if (areFieldsFilled()) {
      axios
        .post("/api/leads", {
          email,
          firstName,
          lastName,
          country,
          interestAreas,
        })
        .then((res) => {
          props.onSuccess();
        });
    }
  };
  return (
    <Modal onClose={props.onClose}>
      <form id="form-newsletter">
        <div className="row">
          <div className="col s12">
            <p>{t('title')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <Input
              id="firstName"
              type="text"
              value={firstName}
              onChange={setFirstName}
              label={t('form.firstName')}
            />
          </div>
          <div className="col s6">
            <Input
              id="lastName"
              type="text"
              value={lastName}
              onChange={setLastName}
              label={t('form.lastName')}
            />
          </div>
          <div className="col s12">
            <Input
              id="email"
              type="email"
              value={email}
              onChange={setEmail}
              label={t('form.email')}
            />
          </div>
          <div className="col s12">
            <Select
              key="interestAreas"
              label={t('form.areasOfInterest')}
              onChange={setInterestAreas}
              multiple={true}
              children={mainInterestAreas.map((v) => (
                <option selected={interestAreas.indexOf(v) !== -1} value={v}>
                  {v}
                </option>
              ))}
            />
          </div>
          <div className="col s12">
            <Select
              key="country"
              label={t('form.country')}
              onChange={setCountry}
              value={country}
              children={countryList.map(({ key, value }) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            />
          </div>
          <div className="col s12">
            <CheckBox
              id={"consent"}
              checked={consent}
              onChange={setConsent}
              onLabel={<div dangerouslySetInnerHTML={{ __html: parseMarkdown(t('form.consent')) }} />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <Button
              onClick={submitHandler}
              disabled={!areFieldsFilled()}
              $withHover
              variant={BUTTON_VARIANTS.ACCENT}
            >
              {t('form.submitButton.text')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NewsletterModal;
