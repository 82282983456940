import React, { useEffect } from "react";
import { PortalWithState } from "react-portal";

const Modal = ({ children, onClose, style = {} }) => {
  useEffect(() => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {
      onCloseEnd: onClose,
    });
    instances.forEach((instance) => instance.open());
    return () => instances.forEach((instance) => {
      instance.close()
      instance.destroy()
    });
  }, []);

  return (
    <PortalWithState defaultOpen>
      {({ portal }) =>
        portal(
          <div className="modal open" style={style}>
            <div className="modal-content">{children}</div>
          </div>
        )
      }
    </PortalWithState>
  );
};

export default Modal;
