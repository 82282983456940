import React, { useEffect } from "react";
import { H5 } from "shared/ui/headings";
import { Cell, Grid } from "shared/ui/grid";
import { Gap } from "shared/ui/gap";
import Input from "../../../../../vicoustic/forms/input";
import Select from "../../../../../vicoustic/forms/select";
import CheckBox from "../../../../../vicoustic/forms/check-box";
import { assoc, assocPath } from "ramda";
import { useCheckoutContext } from "../context";
import { useData } from "../../../../shared/hooks/use-data";
import { SmallP } from "../../../../shared/ui/headings";
import { LoginButton } from "../../../partials/login";
import {
  isBuyerCompany,
  isVatValidFormat,
  validateVat,
  VAT_REGEXP,
} from "../helper";
import { getCountryCookie } from "../../../../shared/utils/cookies";

import { useTranslations } from "../../../../shared/context/translations";

const ShippingDetails = (props) => {
  const [checkoutState, setState] = useCheckoutContext();
  const { state, result } = useData("/api/configurator/shipping-countries");
  const isCountriesLoading = state === "PENDING";
  const { shippingDetails } = checkoutState;

  const wrapDispatch = (key) => (value) =>
    setState(assocPath(["shippingDetails", key], value));

  const wrapFormInput = (key, inputProps) => (FormInput) => (
    <FormInput
      {...inputProps}
      value={shippingDetails[key]}
      checked={shippingDetails[key]}
      onChange={wrapDispatch(key)}
    />
  );

  useEffect(() => {
    if (!isCountriesLoading && result.length) {
      const userCountry = getCountryCookie();
      const defaultCountry =
        result.find((country) => country.id === userCountry)?.id || result[0].id;
      wrapDispatch("country")(defaultCountry);
      wrapDispatch("shippingCountry")(defaultCountry);
    }
  }, [isCountriesLoading, result]);

  const isCompany = isBuyerCompany(checkoutState);

  useEffect(() => {
    if (!isCompany) return;
    if (checkoutState.vatData.vatNumber === shippingDetails.vatNumber) return;
    let cancel = false;

    const combinedVAT = `${shippingDetails.country}${shippingDetails.vatNumber}`;

    if (isVatValidFormat(combinedVAT)) {
      assoc("vatData", {
        isLoading: true,
        valid: false,
        error: "",
      });

      validateVat(
        shippingDetails.country,
        shippingDetails.vatNumber,
        ({ err, validationInfo }) => {
          if (!cancel && validationInfo) {
            setState(
              assoc("vatData", {
                isLoading: false,
                ...validationInfo,
                error:
                  !validationInfo.valid && (err || "VAT number is not valid"),
              })
            );
          }
        }
      );
    } else {
      setState(
        assoc("vatData", {
          isLoading: false,
          valid: false,
          error: "VAT number is not valid",
        })
      );
    }
    return () => {
      cancel = true;
    };
  }, [shippingDetails.vatNumber, shippingDetails.country, isCompany]);
  const t = useTranslations('checkout')
  return (
    <div>
      <Grid $wrap>
        <Cell s={12}>
          <H5>{t('billingDetails')}</H5>
          <Gap x={0.5} />
          <SmallP>
            Fill out your information <LoginButton />
          </SmallP>
          <SmallP>
            If you experience any problem please contact us at{" "}
            <a href="mailto:sales@vicoustic.com">sales@vicoustic.com</a>
          </SmallP>
          <Gap />
        </Cell>
        <Cell s={12}>
          <CheckBox
            checked={shippingDetails.userType === "company"}
            onChange={(value) =>
              wrapDispatch("userType")(value ? "company" : "private")
            }
            offLabel={t('privatePerson')}
            onLabel={t('company')}
          />
          <Gap />
        </Cell>
        {shippingDetails.userType === "company" && (
          <Cell s={12}>
            {wrapFormInput("companyName", {
              label: t('form.companyName'),
              type: "text",
            })(Input)}
          </Cell>
        )}
        <Cell s={6}>
          {wrapFormInput("vatNumber", {
            label: t('form.vatNumber'),
            type: "text",
            customError: isCompany && !checkoutState.vatData.valid,
            error: checkoutState.vatData.error,
            helper:
              isCompany && !checkoutState.vatData.valid
                ? checkoutState.vatData.error
                : "",
          })(Input)}
        </Cell>
        <Cell s={6}>
          {wrapFormInput("email", { label: t('form.email'), type: "email" })(Input)}
        </Cell>
        <Cell s={6}>
          {wrapFormInput("firstName", {
            label: t('form.firstName'),
            type: "text",
          })(Input)}
        </Cell>
        <Cell s={6}>
          {wrapFormInput("lastName", {
            label: t('form.lastName'),
            type: "text",
          })(Input)}
        </Cell>
        <Cell s={12}>
          {wrapFormInput("address", { label: t('form.address'), type: "text" })(Input)}
        </Cell>
        <Cell s={6}>
          {wrapFormInput("postCode", { label: t('form.postCode'), type: "text" })(
            Input
          )}
        </Cell>
        <Cell s={6}>
          {wrapFormInput("city", { label: t('form.city'), type: "text" })(Input)}
        </Cell>
        <Cell s={6}>
          {wrapFormInput("phone", {
            validate: true,
            label: t('form.phone'),
            type: "text",
            regex: "^\\+?[1-9]\\d{1,14}$",
            helper: "Example: +351910000000",
          })(Input)}
        </Cell>
        {!isCountriesLoading && (
          <Cell $noOverflow s={6}>
            {wrapFormInput("country", {
              key: shippingDetails.country,
              label: t('form.country'),
              children: result.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              )),
            })(Select)}
          </Cell>
        )}
        <Cell s={6}></Cell>
      </Grid>
      <Gap />
      <Grid $wrap>
        <Cell s={12}>
          <H5>{t('shippingDetails')}</H5>
          <Gap x={0.5} />
          {wrapFormInput("isShippingDifferent", {
            onLabel: t('differentShipping'),
          })(CheckBox)}
          <Gap />
        </Cell>
        {shippingDetails.isShippingDifferent && (
          <>
            <Cell s={6}>
              {wrapFormInput("shippingFirstName", {
                label: t('form.firstName'),
                type: "text",
              })(Input)}
            </Cell>
            <Cell s={6}>
              {wrapFormInput("shippingLastName", {
                label: t('form.lastName'),
                type: "text",
              })(Input)}
            </Cell>
            <Cell s={12}>
              {wrapFormInput("shippingAddress", {
                label: t('form.address'),
                type: "text",
              })(Input)}
            </Cell>
            <Cell s={6}>
              {wrapFormInput("shippingPostCode", {
                label: t('form.postCode'),
                type: "text",
              })(Input)}
            </Cell>
            <Cell s={6}>
              {wrapFormInput("shippingCity", { label: t('form.city'), type: "text" })(
                Input
              )}
            </Cell>
            <Cell s={6}>
              {wrapFormInput("shippingPhone", {
                validate: true,
                label: t('form.phone'),
                type: "text",
                regex: "^\\+?[1-9]\\d{1,14}$",
                helper: "Example: +351910000000",
              })(Input)}
            </Cell>
            {!isCountriesLoading && (
              <Cell $noOverflow s={6}>
                {wrapFormInput("shippingCountry", {
                  key: shippingDetails.shippingCountry,
                  label: t('form.country'),
                  children: result.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  )),
                })(Select)}
              </Cell>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default ShippingDetails;
