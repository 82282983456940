import React from 'react'
import { colors } from '../colors'

const GenericLogo = ({ color = colors.fakeBlack, ...rest }) => (
  <svg
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 280 80"
    {...rest}
  >
    <defs>
      <style>{`.c{fill:${color}}.e{isolation:isolate}`}</style>
    </defs>
    <path
      className="c"
      d="m66.8 36.75-1.69 1.69L66.66 40 54.21 52.45 41.76 40l12.45-12.45 1.56 1.55 1.69-1.69L40.06 10l-30 30 30 30 30-30-3.26-3.25Zm-14.29-10.9L40.06 38.3 27.61 25.85 40.06 13.4l12.45 12.45Zm-26.62 1.7L38.36 40 25.89 52.45 13.45 40l12.44-12.45Zm1.7 26.6L40.06 41.7l12.45 12.45L40.06 66.6 27.59 54.15Z"
    />
    <path
      className="c"
      d="M55.41 45v-3.8h3.8v-2.4h-3.8V35h-2.4v3.8h-3.8v2.4h3.8V45h2.4z"
    />
    <text
      transform="translate(80.06 48.88)"
      style={{
        fontFamily: "Barlow",
        fontWeight: 600,
        isolation: "isolate",
        fontSize: "25.5px",
        fill: color,
      }}
    >
      <tspan x={0} y={0}>
        {"VicBo"}
      </tspan>
      <tspan
        x={66.07}
        y={0}
        style={{
          letterSpacing: "-.01em",
        }}
      >
        {"o"}
      </tspan>
      <tspan
        x={79.92}
        y={0}
        style={{
          letterSpacing: 0,
        }}
      >
        {"th"}
      </tspan>
    </text>
    <g className="e">
      <path
        className="c"
        d="M90.28 65.43c0 .53-.05.94-.14 1.23-.12.4-.34.72-.64.95-.3.23-.68.35-1.13.35-.26 0-.49-.05-.71-.15-.21-.1-.39-.25-.53-.45 0-.01-.02-.02-.03-.01-.01 0-.02.01-.02.02v.42c0 .06-.03.1-.1.1h-.49c-.06 0-.1-.03-.1-.1v-6.58c0-.06.03-.1.1-.1h.49c.06 0 .1.03.1.1v2.28s0 .02.01.02c0 0 .02 0 .03-.01.14-.19.32-.33.53-.43.21-.1.45-.16.71-.16.46 0 .84.12 1.15.35s.52.56.64.97c.08.29.12.69.12 1.2Zm-.73.71c.03-.17.04-.41.04-.71s-.02-.56-.05-.74a1.7 1.7 0 0 0-.17-.48c-.08-.21-.22-.38-.41-.51s-.41-.19-.67-.19-.46.07-.63.2c-.18.13-.3.31-.38.53-.06.14-.11.29-.14.46-.03.17-.04.42-.04.74s.01.55.04.72c.03.17.07.32.13.46.08.23.21.41.39.54.17.14.39.2.65.2.27 0 .5-.07.7-.2.2-.13.33-.32.41-.55.06-.14.11-.29.14-.46Z"
      />
    </g>
    <g className="e">
      <path
        className="c"
        d="M91.31 69.72v-.39c0-.06.03-.1.1-.1h.04c.25 0 .45-.04.59-.11.14-.07.26-.19.36-.38.1-.18.21-.46.32-.84.01-.03.01-.04 0-.06l-1.63-4.76v-.04s.02-.07.08-.07h.51c.06 0 .1.03.12.08l1.26 3.91s.02.02.03.02.02 0 .03-.02l1.24-3.91a.12.12 0 0 1 .12-.08h.5c.07 0 .1.04.08.11l-1.79 5.21c-.15.43-.3.75-.44.96s-.3.35-.51.43c-.2.08-.49.12-.86.12h-.07s-.07-.03-.07-.1Z"
      />
    </g>
    <path
      className="c"
      d="m123.37 65.08-.07.15a2.999 2.999 0 0 1-2.89 1.66c-1.89 0-3.42-1.53-3.42-3.42s1.53-3.42 3.42-3.42c1.21-.07 2.34.59 2.87 1.68l.07.15 1.13-.65-.07-.12a4.377 4.377 0 0 0-4-2.32 4.69 4.69 0 0 0 0 9.38c1.69.06 3.26-.84 4.06-2.32l.06-.13-1.16-.64ZM130.55 58.79a4.692 4.692 0 1 0 4.82 4.69 4.648 4.648 0 0 0-4.61-4.69h-.18m0 8.1c-1.89-.03-3.39-1.59-3.36-3.48s1.59-3.39 3.48-3.36c1.87.03 3.37 1.56 3.36 3.43a3.34 3.34 0 0 1-3.27 3.41h-.21M143.66 58.92v5.4a2.698 2.698 0 0 1-2.86 2.53 2.697 2.697 0 0 1-2.53-2.53v-5.4h-1.32v5.4c0 2.41 1.5 3.85 4 3.85s4-1.44 4-3.85v-5.4h-1.29ZM151.29 62.88h-.35c-1.94-.26-2.59-.48-2.59-1.24 0-1 .89-1.55 2.38-1.55 1.12-.15 2.22.42 2.74 1.43l.07.17 1.13-.69-.06-.13a3.998 3.998 0 0 0-3.83-2.08c-2.24 0-3.74 1.15-3.74 2.86 0 2 2 2.22 3.75 2.45 2.34.3 2.89.45 2.89 1.17 0 1-1 1.62-2.65 1.62-1.21.09-2.41-.31-3.32-1.11l-.13-.13-.73 1.1.1.08c1.13.92 2.55 1.39 4 1.34 2.42 0 4-1.14 4-2.91 0-1.94-1.88-2.16-3.69-2.38M155.89 58.92v1.26h3.31v7.86h1.31v-7.86h3.33v-1.26h-7.95zM165.58 58.92h1.31v9.13h-1.31zM176.27 65.08l-.07.15a2.997 2.997 0 0 1-2.9 1.66c-1.89 0-3.42-1.53-3.42-3.42s1.53-3.42 3.42-3.42c1.21-.07 2.35.59 2.88 1.68l.07.15 1.12-.65-.06-.12a4.377 4.377 0 0 0-4-2.32 4.69 4.69 0 0 0 0 9.38c1.69.07 3.26-.83 4.06-2.32l.07-.13-1.17-.64ZM113.87 58.91l-4.13 9.13h1.44l4.11-9.13h-1.42zM111.18 58.91h-1.42l-3.41 7.54-3.41-7.54h-1.42l4.11 9.13h1.44l4.11-9.13z"
    />
  </svg>
)

export default GenericLogo