import React from 'react'
import styled from 'styled-components'
import { BUTTON_SIZES, BUTTON_VARIANTS, FakeButton } from '../../../shared/ui/button'
import { Link } from "react-router-dom";
import { useSiteAreaContext } from '../../../shared/context/site-area';
import { propOr } from 'ramda';
import { useTranslations } from '../../../shared/context/translations';

const Root = styled.div`
  position: absolute;
  top: ${propOr('55px', '$top')};
  right: 30px;
  @media screen and (max-width: 768px) {
    top: 12px;
    position: relative;
    right: 0;
    padding-bottom: 15px;
  }
`

const ConfiguratorButton = props => {
  const { siteArea } = useSiteAreaContext()
  const t = useTranslations('shop')
  return (
    <Root $top={props.top}>
      <Link to={`/${siteArea}/configurator`}>
        <FakeButton
          variant={BUTTON_VARIANTS.INFO}
          size={BUTTON_SIZES.SMALL}
        >
          {siteArea === 'office' ? t('designYourOwnOffice') : t('designYourOwnUltra')}
        </FakeButton>
      </Link>
    </Root>
  )
}

export default ConfiguratorButton
