export const countryList = [
	{key: "AF", value: "Afghanistan"},
	{key: "AX", value: "Åland Islands"},
	{key: "AL", value: "Albania"},
	{key: "DZ", value: "Algeria"},
	{key: "AS", value: "American Samoa"},
	{key: "AD", value: "Andorra"},
	{key: "AO", value: "Angola"},
	{key: "AI", value: "Anguilla"},
	{key: "AQ", value: "Antarctica"},
	{key: "AG", value: "Antigua & Barbuda"},
	{key: "AR", value: "Argentina"},
	{key: "AM", value: "Armenia"},
	{key: "AW", value: "Aruba"},
	{key: "AC", value: "Ascension Island"},
	{key: "AU", value: "Australia"},
	{key: "AT", value: "Austria"},
	{key: "AZ", value: "Azerbaijan"},
	{key: "BS", value: "Bahamas"},
	{key: "BH", value: "Bahrain"},
	{key: "BD", value: "Bangladesh"},
	{key: "BB", value: "Barbados"},
	{key: "BY", value: "Belarus"},
	{key: "BE", value: "Belgium"},
	{key: "BZ", value: "Belize"},
	{key: "BJ", value: "Benin"},
	{key: "BM", value: "Bermuda"},
	{key: "BT", value: "Bhutan"},
	{key: "BO", value: "Bolivia"},
	{key: "BA", value: "Bosnia & Herzegovina"},
	{key: "BW", value: "Botswana"},
	{key: "BR", value: "Brazil"},
	{key: "IO", value: "British Indian Ocean Territory"},
	{key: "VG", value: "British Virgin Islands"},
	{key: "BN", value: "Brunei"},
	{key: "BG", value: "Bulgaria"},
	{key: "BF", value: "Burkina Faso"},
	{key: "BI", value: "Burundi"},
	{key: "KH", value: "Cambodia"},
	{key: "CM", value: "Cameroon"},
	{key: "CA", value: "Canada"},
	{key: "IC", value: "Canary Islands"},
	{key: "CV", value: "Cape Verde"},
	{key: "BQ", value: "Caribbean Netherlands"},
	{key: "KY", value: "Cayman Islands"},
	{key: "CF", value: "Central African Republic"},
	{key: "EA", value: "Ceuta & Melilla"},
	{key: "TD", value: "Chad"},
	{key: "CL", value: "Chile"},
	{key: "CN", value: "China"},
	{key: "CX", value: "Christmas Island"},
	{key: "CC", value: "Cocos (Keeling) Islands"},
	{key: "CO", value: "Colombia"},
	{key: "KM", value: "Comoros"},
	{key: "CG", value: "Congo - Brazzaville"},
	{key: "CD", value: "Congo - Kinshasa"},
	{key: "CK", value: "Cook Islands"},
	{key: "CR", value: "Costa Rica"},
	{key: "CI", value: "Côte d’Ivoire"},
	{key: "HR", value: "Croatia"},
	{key: "CU", value: "Cuba"},
	{key: "CW", value: "Curaçao"},
	{key: "CY", value: "Cyprus"},
	{key: "CZ", value: "Czechia"},
	{key: "DK", value: "Denmark"},
	{key: "DG", value: "Diego Garcia"},
	{key: "DJ", value: "Djibouti"},
	{key: "DM", value: "Dominica"},
	{key: "DO", value: "Dominican Republic"},
	{key: "EC", value: "Ecuador"},
	{key: "EG", value: "Egypt"},
	{key: "SV", value: "El Salvador"},
	{key: "GQ", value: "Equatorial Guinea"},
	{key: "ER", value: "Eritrea"},
	{key: "EE", value: "Estonia"},
	{key: "ET", value: "Ethiopia"},
	{key: "EZ", value: "Eurozone"},
	{key: "FK", value: "Falkland Islands"},
	{key: "FO", value: "Faroe Islands"},
	{key: "FJ", value: "Fiji"},
	{key: "FI", value: "Finland"},
	{key: "FR", value: "France"},
	{key: "GF", value: "French Guiana"},
	{key: "PF", value: "French Polynesia"},
	{key: "TF", value: "French Southern Territories"},
	{key: "GA", value: "Gabon"},
	{key: "GM", value: "Gambia"},
	{key: "GE", value: "Georgia"},
	{key: "DE", value: "Germany"},
	{key: "GH", value: "Ghana"},
	{key: "GI", value: "Gibraltar"},
	{key: "GR", value: "Greece"},
	{key: "GL", value: "Greenland"},
	{key: "GD", value: "Grenada"},
	{key: "GP", value: "Guadeloupe"},
	{key: "GU", value: "Guam"},
	{key: "GT", value: "Guatemala"},
	{key: "GG", value: "Guernsey"},
	{key: "GN", value: "Guinea"},
	{key: "GW", value: "Guinea-Bissau"},
	{key: "GY", value: "Guyana"},
	{key: "HT", value: "Haiti"},
	{key: "HN", value: "Honduras"},
	{key: "HK", value: "Hong Kong SAR China"},
	{key: "HU", value: "Hungary"},
	{key: "IS", value: "Iceland"},
	{key: "IN", value: "India"},
	{key: "ID", value: "Indonesia"},
	{key: "IR", value: "Iran"},
	{key: "IQ", value: "Iraq"},
	{key: "IE", value: "Ireland"},
	{key: "IM", value: "Isle of Man"},
	{key: "IL", value: "Israel"},
	{key: "IT", value: "Italy"},
	{key: "JM", value: "Jamaica"},
	{key: "JP", value: "Japan"},
	{key: "JE", value: "Jersey"},
	{key: "JO", value: "Jordan"},
	{key: "KZ", value: "Kazakhstan"},
	{key: "KE", value: "Kenya"},
	{key: "KI", value: "Kiribati"},
	{key: "XK", value: "Kosovo"},
	{key: "KW", value: "Kuwait"},
	{key: "KG", value: "Kyrgyzstan"},
	{key: "LA", value: "Laos"},
	{key: "LV", value: "Latvia"},
	{key: "LB", value: "Lebanon"},
	{key: "LS", value: "Lesotho"},
	{key: "LR", value: "Liberia"},
	{key: "LY", value: "Libya"},
	{key: "LI", value: "Liechtenstein"},
	{key: "LT", value: "Lithuania"},
	{key: "LU", value: "Luxembourg"},
	{key: "MO", value: "Macau SAR China"},
	{key: "MK", value: "Macedonia"},
	{key: "MG", value: "Madagascar"},
	{key: "MW", value: "Malawi"},
	{key: "MY", value: "Malaysia"},
	{key: "MV", value: "Maldives"},
	{key: "ML", value: "Mali"},
	{key: "MT", value: "Malta"},
	{key: "MH", value: "Marshall Islands"},
	{key: "MQ", value: "Martinique"},
	{key: "MR", value: "Mauritania"},
	{key: "MU", value: "Mauritius"},
	{key: "YT", value: "Mayotte"},
	{key: "MX", value: "Mexico"},
	{key: "FM", value: "Micronesia"},
	{key: "MD", value: "Moldova"},
	{key: "MC", value: "Monaco"},
	{key: "MN", value: "Mongolia"},
	{key: "ME", value: "Montenegro"},
	{key: "MS", value: "Montserrat"},
	{key: "MA", value: "Morocco"},
	{key: "MZ", value: "Mozambique"},
	{key: "MM", value: "Myanmar (Burma)"},
	{key: "NA", value: "Namibia"},
	{key: "NR", value: "Nauru"},
	{key: "NP", value: "Nepal"},
	{key: "NL", value: "Netherlands"},
	{key: "NC", value: "New Caledonia"},
	{key: "NZ", value: "New Zealand"},
	{key: "NI", value: "Nicaragua"},
	{key: "NE", value: "Niger"},
	{key: "NG", value: "Nigeria"},
	{key: "NU", value: "Niue"},
	{key: "NF", value: "Norfolk Island"},
	{key: "KP", value: "North Korea"},
	{key: "MP", value: "Northern Mariana Islands"},
	{key: "NO", value: "Norway"},
	{key: "OM", value: "Oman"},
	{key: "PK", value: "Pakistan"},
	{key: "PW", value: "Palau"},
	{key: "PS", value: "Palestinian Territories"},
	{key: "PA", value: "Panama"},
	{key: "PG", value: "Papua New Guinea"},
	{key: "PY", value: "Paraguay"},
	{key: "PE", value: "Peru"},
	{key: "PH", value: "Philippines"},
	{key: "PN", value: "Pitcairn Islands"},
	{key: "PL", value: "Poland"},
	{key: "PT", value: "Portugal"},
	{key: "PR", value: "Puerto Rico"},
	{key: "QA", value: "Qatar"},
	{key: "RE", value: "Réunion"},
	{key: "RO", value: "Romania"},
	{key: "RU", value: "Russia"},
	{key: "RW", value: "Rwanda"},
	{key: "WS", value: "Samoa"},
	{key: "SM", value: "San Marino"},
	{key: "ST", value: "São Tomé & Príncipe"},
	{key: "SA", value: "Saudi Arabia"},
	{key: "SN", value: "Senegal"},
	{key: "RS", value: "Serbia"},
	{key: "SC", value: "Seychelles"},
	{key: "SL", value: "Sierra Leone"},
	{key: "SG", value: "Singapore"},
	{key: "SX", value: "Sint Maarten"},
	{key: "SK", value: "Slovakia"},
	{key: "SI", value: "Slovenia"},
	{key: "SB", value: "Solomon Islands"},
	{key: "SO", value: "Somalia"},
	{key: "ZA", value: "South Africa"},
	{key: "GS", value: "South Georgia & South Sandwich Islands"},
	{key: "KR", value: "South Korea"},
	{key: "SS", value: "South Sudan"},
	{key: "ES", value: "Spain"},
	{key: "LK", value: "Sri Lanka"},
	{key: "BL", value: "St. Barthélemy"},
	{key: "SH", value: "St. Helena"},
	{key: "KN", value: "St. Kitts & Nevis"},
	{key: "LC", value: "St. Lucia"},
	{key: "MF", value: "St. Martin"},
	{key: "PM", value: "St. Pierre & Miquelon"},
	{key: "VC", value: "St. Vincent & Grenadines"},
	{key: "SD", value: "Sudan"},
	{key: "SR", value: "Suriname"},
	{key: "SJ", value: "Svalbard & Jan Mayen"},
	{key: "SZ", value: "Swaziland"},
	{key: "SE", value: "Sweden"},
	{key: "CH", value: "Switzerland"},
	{key: "SY", value: "Syria"},
	{key: "TW", value: "Taiwan"},
	{key: "TJ", value: "Tajikistan"},
	{key: "TZ", value: "Tanzania"},
	{key: "TH", value: "Thailand"},
	{key: "TL", value: "Timor-Leste"},
	{key: "TG", value: "Togo"},
	{key: "TK", value: "Tokelau"},
	{key: "TO", value: "Tonga"},
	{key: "TT", value: "Trinidad & Tobago"},
	{key: "TA", value: "Tristan da Cunha"},
	{key: "TN", value: "Tunisia"},
	{key: "TR", value: "Turkey"},
	{key: "TM", value: "Turkmenistan"},
	{key: "TC", value: "Turks & Caicos Islands"},
	{key: "TV", value: "Tuvalu"},
	{key: "UM", value: "U.S. Outlying Islands"},
	{key: "VI", value: "U.S. Virgin Islands"},
	{key: "UG", value: "Uganda"},
	{key: "UA", value: "Ukraine"},
	{key: "AE", value: "United Arab Emirates"},
	{key: "GB", value: "United Kingdom"},
	{key: "UN", value: "United Nations"},
	{key: "US", value: "United States"},
	{key: "UY", value: "Uruguay"},
	{key: "UZ", value: "Uzbekistan"},
	{key: "VU", value: "Vanuatu"},
	{key: "VA", value: "Vatican City"},
	{key: "VE", value: "Venezuela"},
	{key: "VN", value: "Vietnam"},
	{key: "WF", value: "Wallis & Futuna"},
	{key: "EH", value: "Western Sahara"},
	{key: "YE", value: "Yemen"},
	{key: "ZM", value: "Zambia"},
	{key: "ZW", value: "Zimbabwe"}
];

export const stateListUS = [
	{ key: "AL", value: "Alabama" },
	{ key: "AK", value: "Alaska" },
	{ key: "AS", value: "American Samoa" },
	{ key: "AZ", value: "Arizona" },
	{ key: "AR", value: "Arkansas" },
	{ key: "CA", value: "California" },
	{ key: "CO", value: "Colorado" },
	{ key: "CT", value: "Connecticut" },
	{ key: "DE", value: "Delaware" },
	{ key: "DC", value: "District Of Columbia" },
	{ key: "FM", value: "Federated States Of Micronesia" },
	{ key: "FL", value: "Florida" },
	{ key: "GA", value: "Georgia" },
	{ key: "GU", value: "Guam" },
	{ key: "HI", value: "Hawaii" },
	{ key: "ID", value: "Idaho" },
	{ key: "IL", value: "Illinois" },
	{ key: "IN", value: "Indiana" },
	{ key: "IA", value: "Iowa" },
	{ key: "KS", value: "Kansas" },
	{ key: "KY", value: "Kentucky" },
	{ key: "LA", value: "Louisiana" },
	{ key: "ME", value: "Maine" },
	{ key: "MH", value: "Marshall Islands" },
	{ key: "MD", value: "Maryland" },
	{ key: "MA", value: "Massachusetts" },
	{ key: "MI", value: "Michigan" },
	{ key: "MN", value: "Minnesota" },
	{ key: "MS", value: "Mississippi" },
	{ key: "MO", value: "Missouri" },
	{ key: "MT", value: "Montana" },
	{ key: "NE", value: "Nebraska" },
	{ key: "NV", value: "Nevada" },
	{ key: "NH", value: "New Hampshire" },
	{ key: "NJ", value: "New Jersey" },
	{ key: "NM", value: "New Mexico" },
	{ key: "NY", value: "New York" },
	{ key: "NC", value: "North Carolina" },
	{ key: "ND", value: "North Dakota" },
	{ key: "MP", value: "Northern Mariana Islands" },
	{ key: "OH", value: "Ohio" },
	{ key: "OK", value: "Oklahoma" },
	{ key: "OR", value: "Oregon" },
	{ key: "PW", value: "Palau" },
	{ key: "PA", value: "Pennsylvania" },
	{ key: "PR", value: "Puerto Rico" },
	{ key: "RI", value: "Rhode Island" },
	{ key: "SC", value: "South Carolina" },
	{ key: "SD", value: "South Dakota" },
	{ key: "TN", value: "Tennessee" },
	{ key: "TX", value: "Texas" },
	{ key: "UT", value: "Utah" },
	{ key: "VT", value: "Vermont" },
	{ key: "VI", value: "Virgin Islands" },
	{ key: "VA", value: "Virginia" },
	{ key: "WA", value: "Washington" },
	{ key: "WV", value: "West Virginia" },
	{ key: "WI", value: "Wisconsin" },
	{ key: "WY", value: "Wyoming" }
]

export const stateListCA = [
	{ key: "AB", value: "Alberta" },
	{ key: "BC", value: "British Columbia" },
	{ key: "MB", value: "Manitoba" },
	{ key: "NB", value: "New Brunswick" },
	{ key: "NL", value: "Newfoundland and Labrador" },
	{ key: "NT", value: "Northwest Territories" },
	{ key: "NS", value: "Nova Scotia" },
	{ key: "NU", value: "Nunavut" },
	{ key: "ON", value: "Ontario" },
	{ key: "PE", value: "Prince Edward Island" },
	{ key: "QC", value: "Quebec" },
	{ key: "SK", value: "Saskatchewan" },
	{ key: "YT", value: "Yukon Territory" }
]
