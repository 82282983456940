import styled, { css } from "styled-components";
import { colors } from "./colors";
import { PlainButton } from "./button";

export const RegionButton = styled(PlainButton)`
  border: 1px solid ${colors.gray};
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 6px;
  border-radius: 50%;
  background: white;
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    border: 2px solid ${({ active }) => (active ? colors.primary : colors.gray)};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;
