import CloseIcon from '@material-ui/icons/Close'
import { add, multiply } from 'mathjs'
import React, { useEffect } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import { Link, useLocation } from 'react-router-dom'
import { canCheckoutOnline } from "shared/constants/checkout"
import { Gap } from 'shared/ui/gap'
import { SITE_AREAS } from '../../constants/siteAreas'
import { useCartContext } from '../../context/cart'
import { useTranslations } from '../../context/translations'
import { formatCurrency } from '../../formatters/currency'
import { getCurrencyCookie } from '../../utils/cookies'
import { BUTTON_SIZES, BUTTON_VARIANTS, Button } from '../button'
import { Note } from '../headings'
import { BoothCartItem } from './booth-item'
import CartItem from './cart-item'
import {
  AccessoriesPlug,
  CartItems,
  CartPanel,
  CartTitle,
  CheckoutButton,
  CheckoutButtonWrapper,
  TotalLine
} from './styled'
import GetAQuoteButton from './get-a-quote-button'

const CartBanner = props => {
  const currency = getCurrencyCookie()
  const {
    cart,
    cartActions,
    officeConfigurator,
    ultraConfigurator,
    gamingConfigurator,
    spaConfigurator,
    cartOpen,
    setCartState,
  } = useCartContext()
  const location = useLocation()

  useEffect(() => {
    setCartState(false)
  }, [location])

  const allConfiguratorItems = [].concat(
    officeConfigurator.configurator.items,
    ultraConfigurator.configurator.items,
    gamingConfigurator.configurator.items,
    spaConfigurator.configurator.items,
  )
  const cartTotal = cart.items.reduce((acc, item) => add(acc, multiply(item.quantity, (item.prices[currency] || 0))), 0)
  const boothsTotal = allConfiguratorItems.reduce((acc, item) => add(acc, multiply(item.quantity, (item.prices?.[currency] || item.price || 0))), 0)
  const total = cartTotal + boothsTotal

  const isCartEmpty = cart.items.length === 0 && allConfiguratorItems.length === 0
  const t = useTranslations('cart')

  return (
    <ClickAwayListener onClickAway={(e) => {
      if (cartOpen && e.target.id !== 'cart-button') {
        setCartState(false)
      }
    }}>
      <CartPanel $isOpen={cartOpen}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CartTitle>{isCartEmpty ? t('emptyTitle') : t('title')}</CartTitle>
          <CloseIcon onClick={() => setCartState(false)} style={{ cursor: 'pointer' }} />
        </div>
        <Gap x={2} />
        <CartItems>
          {cart.items.map(item => (
            <CartItem
              {...item}
              key={`${item.cartId}`}
              currency={currency}
              changeQuantity={quantity => cartActions.changeQuantity(item, quantity)}
              removeFromCart={() => cartActions.removeFromCart(item)}
            />
          ))}
          <BoothCartItem siteArea={SITE_AREAS.OFFICE} {...officeConfigurator} />
          <BoothCartItem siteArea={SITE_AREAS.ULTRA} {...ultraConfigurator} />
          <BoothCartItem siteArea={SITE_AREAS.GAMING} {...gamingConfigurator} />
          <BoothCartItem siteArea={SITE_AREAS.SPA} {...spaConfigurator} />
          {cart.items.length === 0 && (
            <AccessoriesPlug>
              <span><b>Need Accessories for your VicBooth?</b></span>
              <Link to="/accessories"><Button size={BUTTON_SIZES.TINY} variant={BUTTON_VARIANTS.ACCENT_FILLED}>Go to VicBooth Shop</Button></Link>
            </AccessoriesPlug>
          )}
        </CartItems>
        <Gap />
        {!isCartEmpty && (
          <>
            <TotalLine><span>{t('total')}</span><span>{formatCurrency(total, currency)}</span></TotalLine>
          </>
        )}
        <Gap x={1.5} />
        <CheckoutButtonWrapper $disabled={isCartEmpty}>
          {canCheckoutOnline() ? (
            <Link to="/checkout">
              <CheckoutButton variant={BUTTON_VARIANTS.ACCENT_FILLED}>
                {t('checkoutButton.text')}
              </CheckoutButton>
            </Link>
          ) : (
            <GetAQuoteButton
              cartItems={cart.items}
              officeConfiguratorItems={officeConfigurator.configurator.items}
              ultraConfiguratorItems={ultraConfigurator.configurator.items}
              gamingConfiguratorItems={gamingConfigurator.configurator.items}
              spaConfiguratorItems={spaConfigurator.configurator.items}
            >
              <CheckoutButton variant={BUTTON_VARIANTS.ACCENT_FILLED}>{t('getQuoteButton.text')}</CheckoutButton>
            </GetAQuoteButton>
          )}
        </CheckoutButtonWrapper>
        <Gap x={2} />
        <Note>
          {t('footnote')}
        </Note>
        <Gap x={2} />
      </CartPanel>
    </ClickAwayListener>
  )
}

export default CartBanner