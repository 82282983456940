import React from "react";
import { Tag } from "bbcode-to-react";

class Icon extends Tag {
  toReact() {
    return <i className={`fa ${this.getComponents()}`}></i>
  }
}

export default Icon;
