import { keys } from "ramda";
import { useState, useEffect } from "react";
import { useRenderedOnClient } from "./use-rendered-on-client";

const SIZE_BREAKPOINTS = {
  xxl: 1600,
  xl: 1201,
  l: 991,
  m: 768,
  s: 480,
  xs: 320,
};

const isMobileBreakpoints = ["m", "s", "xs"];

export const useBreakpoints = () => {
  const getViewport = (size) =>
    keys(SIZE_BREAKPOINTS).find((k) => SIZE_BREAKPOINTS[k] <= size);

  const renderedOnClient = useRenderedOnClient();
  const [currentViewport, setCurrentViewport] = useState(
    getViewport(renderedOnClient ? window.innerWidth : 1920)
  );

  useEffect(() => {
    let timeout = null;
    const listener = () => {
      clearTimeout(timeout);
      timeout = setTimeout(
        () => setCurrentViewport(getViewport(window.innerWidth)),
        200
      );
    };
    window.addEventListener("resize", listener);
    listener();
    return () => window.removeEventListener("resize", listener);
  }, []);

  const isMobile = isMobileBreakpoints.some((mb) => mb === currentViewport);
  return { currentViewport, isMobile };
};
