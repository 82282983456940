import React from "react";
import { hydrate } from "react-dom";
import App from "./client";
import { BrowserRouter } from "react-router-dom";
import "../vicoustic/utility/polyfill";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { store, rrfProps } from "./client/store";

hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
