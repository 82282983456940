import React from "react";
import { H5, P } from "shared/ui/headings";
import { Cell, Grid } from "shared/ui/grid";
import { Gap } from "shared/ui/gap";
import { useCheckoutContext } from "../context";
import { FakeButton } from "../../../../shared/ui/button";
import { Link } from "react-router-dom";
import { useTranslations } from "../../../../shared/context/translations";
import parseMarkdown from "../../../../shared/utils/markdown";

const ThankYou = (props) => {
  const [{ orderNumber }] = useCheckoutContext((state) => state.checkoutData);
  const t = useTranslations('checkout')
  return (
    <div>
      <Grid $wrap>
        <Cell s={12}>
          <H5>{t('success.title')}</H5>
          <Gap />
          <P dangerouslySetInnerHTML={{ __html: parseMarkdown(t('success.description').replace(/\$1/, orderNumber)) }} />
          <Gap />
          <Link to="/">
            <FakeButton>{t('success.backToHomepage')}</FakeButton>
          </Link>
        </Cell>
      </Grid>
    </div>
  );
};

export default ThankYou;
