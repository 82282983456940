import React from "react";

//const Select = props => {
class Select extends React.Component {
  handleChange(e) {
    let values = [];
    if (this.props.multiple) {
      let options = e.target.options;
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          values.push(options[i].value);
        }
      }
    }
    this.props.onChange(this.props.multiple ? values : e.target.value);
  }
  isTouch() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }
  componentDidMount() {
    window.M.FormSelect.init(this.select);
  }
  componentDidUpdate() {
    window.M.FormSelect.init(this.select);
  }
  render() {
    return (
      <div
        data-version={this.props.version}
        className={this.isTouch() ? "" : "input-field"}
      >
        {this.isTouch() && this.props.label && (
          <label>{this.props.label}</label>
        )}
        <select
          key={this.props.id}
          disabled={this.props.disabled}
          ref={(select) => (this.select = select)}
          required={this.props.required}
          value={this.props.value}
          onChange={this.handleChange.bind(this)}
          multiple={this.props.multiple}
          className={this.isTouch() ? "browser-default" : ""}
        >
          {this.props.children}
        </select>
        {!this.isTouch() && this.props.label && (
          <label>{this.props.label}</label>
        )}
      </div>
    );
  }
}

export default Select;
