import { propOr } from 'ramda'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { Button } from '../../../shared/ui/button';
import { P } from '../../../shared/ui/headings';
import { colors } from '../../../shared/ui/colors';
import { Center } from '../homepage/styled';

export const Root = styled.main`
  max-width: 1600px;
  margin: 0 auto;
`

export const Mosaic = styled.div`
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: -15px 0;
  flex-grow: 0;
  &:first-of-type {
    margin-top:0;
    flex-grow: 1;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const Image = styled.img`
  object-fit: contain;
  width: 50%;
  transform-origin: center;
  transition: transform .6s ease-out .1s;
`

export const TextHolder = styled.div`
  margin-left: 10px;
  margin-top: 15px;
`

export const Card = styled(Link)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${propOr('#ffffff', '$bgColor')};
  color: white;
  padding: 8px;
  margin: 32px 16px;
  box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.2);
`

export const VideoWrapper = styled.div`
  @media screen and (min-width: 769px) {
    margin: 30px 30px 0;
    border-radius: 15px;
    overflow: hidden;
  }
`

export const Title = styled.h5`
  font-weight: 300;
  display: flex;
  align-items: center;
  margin: 0;
  b {
    font-weight: bold;
  }
  p {
    margin: 0;
  }
`

export const ImageContainer = styled.div`
  max-width: 300px;
  width: 100%;
  margin: auto;
`;
export const HighlightColumn = styled(Center)`
  max-width: 300px;
  margin: auto;
  ${/* sc-sel */ ImageContainer} {
    img {
      height: 100%;
    }
    height: 65px;
  }
`;


export const CardContent = styled.div`
  padding: 16px;
`

export const Tile = styled(Link)`
  z-index: 0;
  position: relative;
  flex: 1 0 calc(50% - 30px);
  margin: 15px;
  color: white;
  background: #666;
  padding: 30px 30px 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${propOr('transparent', '$color')};
  background-image: url("${propOr('none', '$bg')}");
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    padding: 10px;
    flex: 0 0 100%;
    margin: 8px 0;
    min-height: 100px;
  }
  & ${Button} {
    color: ${propOr('transparent', '$color')};
    border-color: white;
    background-color: white;
    display: flex; align-items: center;
    font-size: 13px;
    > span {
      margin-right: 5px;
      margin-top: -1px;
    }
  } 
`

export const Description = styled(P)`
  line-height: 140%;
  white-space: pre-line;
  margin: 20px 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`

export const Fold = styled.div`
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`

export const ClaimTitle = styled.h6`
  font-weight: 700;
  margin: 30px auto 0px;
  text-align: center;
  padding: 0 15px 0 215px;
  color: ${colors.midGray};
  display: flex;
  align-items: center;
  justify-content: center;
  em {
    color: ${colors.primary};
    font-style: normal;
  }
  @media screen and (max-width: 768px) {
    padding: 0 15px;
    margin-top: 100px;
  }
`