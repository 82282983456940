import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import {
  PageSection,
  TextHolder,
  PageSectionTitle,
  PageSectionHeader,
} from "../styled";
import { H3, H4, H5, H6, P, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import ImageLoader from "shared/ui/image-loader";
import Multimedia from "shared/ui/blocks/multimedia";
import Columns from "shared/ui/blocks/columns";
import { Selectors, Arrows } from "../../../../shared/ui/slideshow";
import getRelatedProducts from "./related-products";
import { useInView } from "react-intersection-observer";
import { useBreakpoints } from "../../../../shared/hooks/use-breakpoints";
import { useTranslations } from "../../../../shared/context/translations";
import parseMarkdown from "../../../../shared/utils/markdown";

const Kits = (props, ref) => {
  const t = useTranslations('homepageSections.ultra.acousticKits')

  const slides = [
    "intro",
    ...t('items'),
    "related-products",
  ];
  const relatedProducts = getRelatedProducts(t('relatedProducts.items'));
  const firstCol = (item) =>
    item === slides.length - 1 ? (
      relatedProducts[0]
    ) : item === 0 ? null : (
      <>
        <H3 $bold>{slides[item].title}</H3>
        <Gap />
        <H6 $light>{slides[item].subTitle}</H6>
        <Gap />
        <SmallP as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(slides[item].description) }} />
      </>
    );
  const secondCol = (item) =>
    item === slides.length - 1 ? (
      relatedProducts[1]
    ) : item === 0 ? null : (
      <Link to={slides[item].link} target="_blank">
        <ImageLoader ratio={"600:450"} source={slides[item].image.src} />
      </Link>
    );
  

  const [active, setActive] = useState(0);
  const { isMobile } = useBreakpoints();

  const transitions = useTransition(active, null, {
    from: {
      wait: 0,
      display: "none",
      transform: "translate3d(0,20px,0)",
      opacity: 0,
    },
    enter: [
      { wait: 1 },
      { display: "block", transform: "translate3d(0,0px,0)", opacity: 1 },
    ],
    leave: [
      { transform: "translate3d(0,20px,0)", opacity: 0 },
      { display: "none" },
    ],
  });

  const [inViewRef, inView, entry] = useInView({
    treshold: 0.05,
  });

  const tout = useRef(null);


  return (
    <PageSection ref={ref} $invertColors $noPadding>
      <PageSectionHeader>
        <PageSectionTitle>{t('name')}</PageSectionTitle>
        <BlueprintIcon color={colors.white} />
      </PageSectionHeader>
      <Multimedia ref={inViewRef}>
        <div
          style={{
            transition: "all .3s ease-out",
            opacity: active === 0 ? 1 : 0,
          }}
        >
          <Multimedia>
            <ImageLoader
              viewports={{
                m: "/images/content/hp/kits.jpg",
                xs: "/images/content/hp/kits-360.jpg",
              }}
              viewportsRatio={{
                m: "1920:1080",
                xs: "360:640",
              }}
              imgProps={{ style: { width: "100%", maxWidth: "auto" } }}
            />
            <Columns $mobileBreakpoint={767}>
              <></>
              <TextHolder $textAlign="left">
                <H5>{t('intro.title')}</H5>
                <Gap />
                <P>{t('intro.subTitle')}</P>
              </TextHolder>
            </Columns>
          </Multimedia>
        </div>
        <div
          style={{ padding: !isMobile ? "130px 30px 60px" : "90px 30px 75px" }}
        >
          <div
            style={{
              transition: "opacity .3s ease-out",
              opacity: active === slides.length - 1 ? 1 : 0,
              maxHeight: active === slides.length - 1 ? "100px" : 0,
              textAlign: "center",
            }}
          >
            <P>
              {t('relatedProducts.title')}
            </P>
          </div>
          <Columns $mobileBreakpoint={767} $verticalAlignment={"flex-start"}>
            <div>
              {transitions.map(({ item, key, props }) => (
                <animated.div style={props} key={key}>
                  {firstCol(item)}
                </animated.div>
              ))}
            </div>
            <div>
              {transitions.map(({ item, key, props }) => (
                <animated.div style={props} key={key}>
                  {secondCol(item)}
                </animated.div>
              ))}
            </div>
          </Columns>
        </div>
      </Multimedia>
      <Selectors
        style={{ position: "absolute", bottom: "50px" }}
        itemCount={slides.length}
        setActive={setActive}
        active={active}
      />
      <Arrows setActive={setActive} itemCount={slides.length} />
    </PageSection>
  );
};

export default forwardRef(Kits);
