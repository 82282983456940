import React from 'react'
import { useTranslations } from '../../../../shared/context/translations'
import { ActionButton, CartItem, ConfigurationWrapper } from './styled'
import { formatCurrency } from '../../../../shared/formatters/currency'

export const CheckoutConfiguratorLines = props => {
  const { configurator, configuratorActions } = props
  const t = useTranslations('checkout')
  const boothUrl = configurator.url

  return (
    <>
      {configurator.items.length !== 0 && (
        <ConfigurationWrapper $noBorder>
          <span>{t('boothConfiguration')}</span>
          {boothUrl && <ActionButton><a href={boothUrl}>{t('edit')}</a></ActionButton>}
          <ActionButton onClick={() => configuratorActions.removeConfiguration()}><a>{t('remove')}</a></ActionButton>
        </ConfigurationWrapper>
      )}
      {configurator.items.map((line) => (
        <CartItem $noBorder>
          <span>{`${line.quantity}x ${line.desc}${line.region ? ` - ${line.region.toUpperCase()}` : ''}`}</span>
          <span>{line.price === 0 ? 'Included' : formatCurrency(line.price * line.quantity)}</span>
        </CartItem>
      ))}
    </>
  )
}