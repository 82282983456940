import { formatCurrency } from "../../formatters/currency";
import { formatState } from "./helpers";
import { getCurrencyCookie } from "shared/utils/cookies";

const pdf = require("pdfjs");
const HelveticaBold = require("pdfjs/font/Helvetica-Bold");

export const generateTable = (data, state, doc, t) => {
  const currency = getCurrencyCookie();
  var table = doc.table({
    widths: [
      1.5 * pdf.cm,
      3 * pdf.cm,
      3.2 * pdf.cm,
      null,
      2.5 * pdf.cm,
      2.5 * pdf.cm,
    ],
    borderHorizontalWidths: function (i) {
      return i < 2 ? 1 : 0.1;
    },
    padding: 5,
  });

  var tr = table.header({ font: HelveticaBold, borderBottomWidth: 1.5 });
  tr.cell(t('pdf.columns.quantity'));
  tr.cell(t('pdf.columns.reference'));
  tr.cell(t('pdf.columns.eanCode'));
  tr.cell(t('pdf.columns.description'));
  tr.cell(t('pdf.columns.unitPrice'), { textAlign: "right" });
  tr.cell(t('pdf.columns.totalPrice'), { textAlign: "right" });

  function addRow({ quantity, ref, eanCode, desc, price, region }) {
    var tr = table.row();
    tr.cell(quantity.toString());
    tr.cell("Ref: " + ref);
    tr.cell(eanCode);
    tr.cell(`${desc}${region ? ` - ${region.toUpperCase()}` : ''}`);
    if (price === 0) {
      tr.cell('-', { textAlign: "right" });
      tr.cell('Included', {
        textAlign: "right",
      });
    } else {
      tr.cell(formatCurrency(price, currency), { textAlign: "right" });
      tr.cell(formatCurrency(price * quantity, currency), {
        textAlign: "right",
      });
    }
  }
  const productLines = formatState(data, state);
  productLines.forEach(addRow);
  const total = productLines.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  tr = table.row({
    font: HelveticaBold,
    borderBottomWidth: 0,
  });
  tr.cell("", { colspan: 5 });
  tr.cell(formatCurrency(total, currency), { textAlign: "right" });
};
