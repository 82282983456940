import styled from "styled-components";
import { colors } from "shared/ui/colors";
import { Button, PlainButton, BUTTON_SIZES } from "shared/ui/button";
import { propTernary } from "../../../../../shared/utils/styled";

export const Title = styled.p`
  margin-left: 6px;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "no-wrap")};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ $alignEnd }) => ($alignEnd ? "align-items: flex-end;" : "")}
`;

export const PanelTypeOptionButton = styled(PlainButton)`
  color: ${({ active }) => (active ? colors.primary : colors.darkGray)};
  padding: 6px 8px 8px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  :after {
    content: "";
    transition: all 0.2s ease-out;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: ${({ active }) => (active ? "100%" : "0")};
    height: 2px;
    transform: translateX(-50%);
    background: ${colors.primary};
  }
`;
export const PanelOptionButton = styled(Button).attrs({
  size: BUTTON_SIZES.SMALL,
})`
  color: ${({ active }) => (active ? colors.primary : colors.gray)};
  background: ${colors.white};
  margin: 5px;
  text-transform: none;
`;

export const Root = styled.div`
  background: ${colors.lightGray};
  &:nth-child(2n) {
    background: ${colors.lighterGray};
  }
  position: relative;
  padding: 10px 25px 10px 105px;
  cursor: default;
`;

export const PanelIcon = styled.img`
  position: absolute;
  max-width: 70px;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: 100%;
`;
