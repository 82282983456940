import React from 'react'
import { Helmet } from 'react-helmet'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import CaseStudiesList from './case-studies-list'

const CaseStudiesContainer = props => {
  return (
    <>
      <Helmet>
        <title>VicBooth Case Studies</title>
        <meta name="description" content={"Real stories of VicBooth users, from voice over professionals to musicians, universities, streaming channels, and offices. These case studies show how Vicoustic’s acoustic booth is a soundproofing solution for professional audio and offices."} />
        <meta property="og:image" content={`/images/meta/VicBooth_CaseStudies.webp`} />
        <meta property="og:title" content={`VicBooth Case Studies`} />
        <meta property="og:description" content={"Real stories of VicBooth users, from voice over professionals to musicians, universities, streaming channels, and offices. These case studies show how Vicoustic’s acoustic booth is a soundproofing solution for professional audio and offices."} />
        <meta property="og:site_name" content={`VicBooth`} />
      </Helmet>
      <NoContextHeader />
      <CaseStudiesList />
      <SimplifiedFooter />
    </>
  )
}

export default CaseStudiesContainer
