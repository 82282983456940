import styled, { css } from "styled-components";
import { colors } from "./colors";
import { PlainButton } from "./button";

export const ColorButton = styled(PlainButton)`
  border: 1px solid ${colors.gray};
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 6px;
  border-radius: 50%;
  background: ${({ texture }) => {
    const textures = texture.split('-')
    if (textures.length > 1) {
      return `linear-gradient(45deg, ${textures[0]} 50%, ${textures[1]} 50%)`
    }
    return textures[0]
  }};
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    border: 2px solid ${({ active }) => (active ? colors.primary : colors.gray)};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;
