import React, { useState } from "react";
import Input from "../../../../../vicoustic/forms/input";
import { assocPath } from "ramda";
import { Cell, Grid } from "shared/ui/grid";
import { Gap } from "shared/ui/gap";

import { useCheckoutContext } from "../context";
import { Button } from "../../../../shared/ui/button";
import axios from "axios";
import { useTranslations } from "../../../../shared/context/translations";

const PromoCodeSelector = (props) => {
  const [error, setError] = useState(false);
  const [state, setState] = useCheckoutContext();

  const onChange = (value) =>
    setState(assocPath(["promoCodeData", "code"], value));

  const onApply = async () => {
    setError(false);
    setState(assocPath(["promoCodeData", "isLoading"], true));
    const result = await axios.get(
      `/api/promo-codes/${state.promoCodeData.code}`
    );
    setState(assocPath(["promoCodeData", "isLoading"], false));
    if (result.data?.isValid) {
      setState(assocPath(["promoCodeData", "appliedCode"], result.data));
    } else {
      setError(true);
    }
  };
  const t = useTranslations('checkout')
  return (
    <Grid>
      <Cell s={6}>
        <Input
          value={state.promoCodeData.code}
          onChange={onChange}
          helper={error ? t('promoCode.error') : ""}
          type="text"
          label={t('promoCode.label')}
        />
      </Cell>
      <Cell s={6}>
        <Button disabled={state.promoCodeData.isLoading} onClick={onApply}>
          {t('promoCode.apply')}
        </Button>
      </Cell>
    </Grid>
  );
};

export default PromoCodeSelector;
