import React from 'react'

const GetAQuoteButton = props => {
  const {
    cartItems = [],
    officeConfiguratorItems = [],
    ultraConfiguratorItems = [],
    gamingConfiguratorItems = [],
    spaConfiguratorItems = [],
    children,
  } = props
  
  const quoteURL = `https://forms.zohopublic.eu/vicoustic/form/OrderVicBoothUltra/formperma/bFBmjZIwMXInZbfkbZOx33e1mYRAY4ZUJ_ylubf4Tso?CartList=${encodeURIComponent(
    [].concat(
      cartItems.map(item => `${item.quantity}x ${item.desc} (${item.reference})`),
      officeConfiguratorItems.length !== 0 ? ['Office Booth configuration:'].concat(officeConfiguratorItems.map(item => `${item.quantity}x ${item.desc} (${item.ref})`)) : [],
      ultraConfiguratorItems.length !== 0 ? ['Ultra Booth configuration:'].concat(ultraConfiguratorItems.map(item => `${item.quantity}x ${item.desc} (${item.ref})`)) : [],
      gamingConfiguratorItems.length !== 0 ? ['Gaming Booth configuration:'].concat(gamingConfiguratorItems.map(item => `${item.quantity}x ${item.desc} (${item.ref})`)) : [],
      spaConfiguratorItems.length !== 0 ? ['Spa Booth configuration:'].concat(spaConfiguratorItems.map(item => `${item.quantity}x ${item.desc} (${item.ref})`)) : [],
    ).join('\n'))
  }`

  return <a href={quoteURL} target="_blank">
    {children}
  </a>
}

export default GetAQuoteButton
