import { descend, includes, prop } from 'ramda'
import React from 'react'
import { useSiteAreaContext } from '../../../shared/context/site-area'
import { useTranslations } from '../../../shared/context/translations'
import { useData } from '../../../shared/hooks/use-data'
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from '../../../shared/ui/button'
import { Gap } from '../../../shared/ui/gap'
import { SmallP } from '../../../shared/ui/headings'
import { FilterButtonList, FilterButton } from './styled'

const FilterPanel = props => {
  const { siteArea } = useSiteAreaContext()
  const { state, result, error } =  useData(`/api/product-categories?area=${siteArea}`)
  const isLoading = state === 'PENDING'
  const t = useTranslations('shop')

  if (isLoading || error) return null
  return (
    <>
      <SmallP><b>{t('selectCategory')}</b></SmallP>
      <Gap x={0.5} />
      <FilterButtonList>
        {result.sort(descend(prop('relevancy'))).map(category => (
          <FilterButton key={category.id} $active={includes(category.id, props.filterState)}>
            <Button
              $normalCase
              $noWrap
              variant={BUTTON_VARIANTS.INFO_OUTLINED}
              size={BUTTON_SIZES.SMALL}
              onClick={() => props.toggleFilter(category.id)}
            >{category.name}</Button>
          </FilterButton>
        ))}
      </FilterButtonList>
    </>
  )
}

export default FilterPanel
