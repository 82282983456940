import { useEffect, useRef, useState } from "react";

export const useScrollTop = (stayStill, isOpen) => {
  const [hide, setHide] = useState(false);
  const prevScrollTop = useRef(0);

  const checkScrollTop = (e) => {
    if (prevScrollTop.current < window.scrollY && !isOpen && window.scrollY > 60) {
      setHide(true)
    } else if (prevScrollTop.current > window.scrollY || isOpen) {
      setHide(false)
    }
    prevScrollTop.current = window.scrollY;
  };

  useEffect(() => {
    if (stayStill) return;
    checkScrollTop();
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [stayStill])

  return hide
}
