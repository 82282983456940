import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";

import {
  PageSection,
  TextHolder,
  PageSectionTitle,
  PageSectionHeader,
} from "../styled";
import { H3, H4, H5, H6, P, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import { BUTTON_VARIANTS, FakeButton } from "shared/ui/button";
import ImageLoader from "shared/ui/image-loader";
import Multimedia from "shared/ui/blocks/multimedia";
import { Arrows, Selectors } from "../../../../shared/ui/slideshow";
import { useInView } from "react-intersection-observer";
import { useTranslationsArray } from "../../../../shared/context/translations";
import { useSiteAreaContext } from "../../../../shared/context/site-area";

const Applications = (props, ref) => {
  const [active, setActive] = useState(0);
  const [inViewRef, inView, entry] = useInView({
    treshold: 0.05,
  });
  const { siteArea } = useSiteAreaContext()
  const { t, tA } = useTranslationsArray(`homepageSections.${siteArea}.applications`)
  const transitions = useTransition(active, null, {
    from: {
      wait: 0,
      display: "none",
      transform: "translate3d(0,20px,0)",
      opacity: 0,
    },
    enter: [
      { wait: 1 },
      { display: "block", transform: "translate3d(0,0px,0)", opacity: 1 },
    ],
    leave: [
      { transform: "translate3d(0,20px,0)", opacity: 0 },
      { display: "none" },
    ],
  });

  const tout = useRef(null);
  useEffect(() => {
    if (inView) {
      tout.current = setTimeout(
        () => setActive((prev) => (prev + 1 + slides.length) % slides.length),
        8000
      );
    }
    return () => clearTimeout(tout.current);
  }, [active, inView]);
  const slides = tA('items')

  if (slides.length === 0) return null
  return (
    <PageSection $siteArea={siteArea} ref={ref} $invertColors $noPadding>
      <PageSectionHeader>
        <PageSectionTitle>{t('name')}</PageSectionTitle>
        <BlueprintIcon color={colors.white} />
      </PageSectionHeader>
      <Multimedia ref={inViewRef} $align={"flex-end"}>
        <div
          style={{
            position: "relative",
            zIndex: 0,
            maxHeight: "750px",
            overflow: "hidden",
          }}
        >
          {transitions.map(({ item, props, key }) => (
            <animated.div
              style={{
                ...props,
                position: "absolute",
                width: "100%",
                zIndex: -1,
                top: 0,
                left: 0,
              }}
              key={key}
            >
              <ImageLoader
                viewports={{
                  m: slides[item].imageDesktop.src,
                  xs: slides[item].imageMobile.src,
                }}
                viewportsRatio={{
                  m: "1920:1080",
                  xs: "360:640",
                }}
                imgProps={{ style: { width: "100%", maxWidth: "auto" } }}
              />
            </animated.div>
          ))}
          <ImageLoader
            viewports={{
              m:
                "/images/content/hp/applications-slideshow/base-resized-1920.png",
              xs: "/images/content/hp/applications-slideshow/base-360.png",
            }}
            viewportsRatio={{
              m: "1920:1080",
              xs: "360:640",
            }}
            imgProps={{ style: { width: "100%", maxWidth: "auto" } }}
          />
        </div>
        <>
          {transitions.map(({ item, props, key }) => (
            <TextHolder key={key} as={animated.div} style={props}>
              <Gap />
              <H3 $bold>{slides[item].title}</H3>
              <Gap />
              {slides[item].subTitle && <>
                <H5 $bold>{slides[item].subTitle}</H5>
                <Gap />
              </>}
              <SmallP>{slides[item].description}</SmallP>
              <Gap />
              <Link to={slides[item].button.link}>
                <FakeButton $withHover variant={BUTTON_VARIANTS.FILLED}>
                  {slides[item].button.text}
                </FakeButton>
              </Link>
            </TextHolder>
          ))}
          <Selectors
            itemCount={slides.length}
            setActive={setActive}
            active={active}
          />
          <Gap />
        </>
      </Multimedia>
      <Arrows setActive={setActive} itemCount={slides.length} />
    </PageSection>
  );
};

export default forwardRef(Applications);
