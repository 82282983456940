import React, { forwardRef } from "react";
import {
  PageSection,
  PageSectionTitle,
} from "../../styled";
import { Gap } from "../../../../../shared/ui/gap";
import { colors } from "../../../../../shared/ui/colors";
import { BlueprintIcon } from "../../../../../shared/ui/blueprint-icon";
import Columns from "../../../../../shared/ui/blocks/columns";
import { FakeButton } from "../../../../../shared/ui/button";
import { useTranslationsArray } from "../../../../../shared/context/translations";
import { range } from "ramda";
import { useSiteAreaContext } from "../../../../../shared/context/site-area";
import FaqItem from "./faq-item";
import { Link } from "../../../../../../vicoustic/client/commons/link";

const FaqSection = (props, ref) => {
  const { siteArea } = useSiteAreaContext()
  const { t, tA } = useTranslationsArray(`homepageSections.${siteArea}.faq`)
  const items = tA('items')
  const firstColumnItemCount = Math.ceil(items.length / 2)

  if (items.length === 0) return null
  return (
    <PageSection $siteArea={siteArea} ref={ref}>
      <PageSectionTitle>{t('name')}</PageSectionTitle>
      <BlueprintIcon color={colors.primary} />
      <Gap />
      <Columns $verticalAlignment="flex-start" $mobileBreakpoint={767}>
        {range(0, 2).map(columnIndex => (
          <div>
            {items.slice(columnIndex * firstColumnItemCount, (columnIndex + 1) * firstColumnItemCount).map((item, itemIndex) => (
              <FaqItem 
                title={`${(columnIndex * firstColumnItemCount) + itemIndex + 1}. ${item.title}`}
                body={item.body}
              />
            ))}
          </div>
        ))}
      </Columns>
      <Gap />
      <Columns
        $wrap
        $noCellPadding
        $keepColumnSize
      >
        {tA('buttons').map(button => (
          <div style={{ margin: '0 12px 12px 0' }}>
            <Link to={button.link}>
              <FakeButton $withHover $noWrap>
                {button.text}
              </FakeButton>
            </Link>
          </div>
        ))}
      </Columns>
    </PageSection>
  );
};

export default forwardRef(FaqSection);
