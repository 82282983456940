import { useEffect } from "react";
import { useThree } from "react-three-fiber";

export const SceneRefSetter = () => {
  const {
    scene,
  } = useThree();

  useEffect(() => {
    window.__canvasScene = scene
  })

  return null
}
