import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
  	padding: 0;
  	min-height: 100%;
    font-family: 'Barlow', Helvetica, Sans-Serif;
    font-variant-ligatures: common-ligatures;
  }
  *, *:before, *:after { box-sizing: border-box; }
  a {
  	text-decoration: none;
  }
  #root {
  	min-height: 100vh;
    display: flex;
    flex-direction: column;
    & > *:nth-child(2) {
      flex-grow: 1;
    }
  }
  canvas {
    outline: none !important;
  }
  .switch .only-on {display: flex;line-height: 150%;}
  .switch .only-on .lever {margin-left: 0;flex-shrink: 0;margin-top: 3px;}
`;

export default GlobalStyle;
