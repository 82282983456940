import React, { forwardRef, useEffect, useRef, useState } from "react";
import { intersperse } from "ramda";

import { PageSection, PageSectionTitle, ColorSelector } from "../styled";
import { H4, H6, SmallP, P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import { ColorButton } from "shared/ui/color-button";
import ImageLoader from "shared/ui/image-loader";
import Columns from "shared/ui/blocks/columns";
import Tabs from "shared/ui/blocks/tabs";
import { Link } from "react-router-dom";
import { useTranslations } from "../../../../shared/context/translations";
import parseMarkdown from '../../../../shared/utils/markdown';
import { useSiteAreaContext } from "../../../../shared/context/site-area";

const blueprints = ["Booth with Standard Door", "Booth with Door with Window"];
const boothColors = ["white", "naturalOak", "black"];

const linksMap = {
  "Booth with Standard Door": {
    white:
      "/spa/product/vbs-3x4-vicbooth-spa-standard?color=White&region=EU",
    naturalOak:
      "/spa/product/vbs-3x4-vicbooth-spa-standard?color=Natural%20Oak&region=EU",
    black:
      "/spa/product/vbs-3x4-vicbooth-spa-standard?color=Black&region=EU",
  },
  "Booth with Door with Window": {
    white:
      "/spa/product/vbs-3x4-vicbooth-spa-with-door-w?color=White&region=EU",
    naturalOak:
      "/spa/product/vbs-3x4-vicbooth-spa-with-door-w?color=Natural%20Oak&region=EU",
    black:
      "/spa/product/vbs-3x4-vicbooth-spa-with-door-w?color=Black&region=EU",
  },
};

const Intro = (props, ref) => {
  const { siteArea } = useSiteAreaContext()
  const [blueprint, setBlueprint] = useState(1);
  const [color, setColor] = useState(1);
  const currentBlueprint = blueprints[blueprint];
  const currentColor = boothColors[color];
  const image = `/images/content/hp/mini-config/spa/${blueprints[blueprint].replace(/\s/g, '_')}_${boothColors[color]}.jpg`;
  const t = useTranslations(`homepageSections.${siteArea}.intro`)
  const lookCloserT = useTranslations(`homepageSections.${siteArea}.lookCloser`)
  return (
    <PageSection $siteArea={siteArea} ref={ref}>
      <PageSectionTitle>{t('name')}</PageSectionTitle>
      <BlueprintIcon color={colors.primary} />
      <Gap />
      <Tabs autoSize active={blueprint} setActive={setBlueprint}>
        {blueprints.map((bp) => (
          <P $bold key={bp}>
            {bp}
          </P>
        ))}
      </Tabs>
      <Columns $reverseOnMobile $mobileBreakpoint={767}>
        <>
          <H4 $accent $bold>
            {t('title')}
          </H4>
          <Gap />
          <H6 $light>
            {t('subTitle')}
          </H6>
          <Gap />
          <SmallP as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('description')) }} />
          <Gap />
          <P $bold>{t('certificationsTitle')}</P>
          <Gap />
          {lookCloserT('seals').map(seal => (
            <a href={seal.link} target="_blank">
              <img src={seal.image.src} alt={seal.image.title} />
            </a>
          ))}
        </>
        <Link target="_blank" to={linksMap[currentBlueprint][currentColor]}>
          <ImageLoader ratio={"4:3"} source={image} />
        </Link>
      </Columns>
      <ColorSelector>
        {intersperse(
          <Gap />,
          boothColors.map((c, index) => (
            <ColorButton
              key={c}
              active={color === index}
              onClick={() => setColor(index)}
              texture={colors[c]}
            />
          ))
        )}
      </ColorSelector>
    </PageSection>
  );
};

export default forwardRef(Intro);
