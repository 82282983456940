import { assoc, prop, propEq } from 'ramda';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SmallP } from "shared/ui/headings";
import ImageLoader from "shared/ui/image-loader";
import { getViewport } from "../../../../vicoustic/utility/get-viewport";
import { useCartContext } from '../../../shared/context/cart';
import { useTranslations } from '../../../shared/context/translations';
import { formatCurrency } from '../../../shared/formatters/currency';
import { BUTTON_SIZES, BUTTON_VARIANTS, Button } from '../../../shared/ui/button';
import { Gap } from '../../../shared/ui/gap';
import { getCurrencyCookie } from '../../../shared/utils/cookies';
import { buildCartProduct, getCurrentVariation, getInitialVariation, hasVariations, mergeProductWithVariation, reduceVariates } from '../product-detail/helpers';
import VariationSelector from '../product-detail/variation-selector';
import { AddToCartButton, AddToCartIcon, ButtonList, Card, StyledLink, VariationSelectorWrapper } from './styled';

const ProductCard = props => {
  const t = useTranslations()
  const currency = getCurrencyCookie()
  const {
    variates = [],
    variations = [],
    siteArea,
    variatesOptions,
  } = props
  const enabledVariations = variations.filter(prop('enabled'))
  const { cartActions } = useCartContext()
  const productHasVariations = hasVariations(props.variates, enabledVariations)
  const [variationState, setVariation] = useState(
    getInitialVariation(productHasVariations, enabledVariations, variates)
  )
  const currentVariation = getCurrentVariation(enabledVariations, variates, variationState)
  const product = mergeProductWithVariation(props, currentVariation)

  const variationUrl = productHasVariations ? `?${
    variates.map(variate => `${variate}=${encodeURIComponent(variationState[variate])}`).join('&')
  }` : ''
  const productLink = `${siteArea ? `/${siteArea}` : ''}/product/${props.id}${variationUrl}`

  const addToCart = () => {
    cartActions.addToCart(buildCartProduct({ ...product, productId: props.id }, variationState))
    M.toast({html: `1x ${product.name} ${t('cart.addedToCart')}`})
  }

  const changeVariation = (key, value) => {
    const newVariationState = assoc(key, value, variationState)
    const newVariation = getCurrentVariation(enabledVariations, variates, newVariationState)
    if (Object.keys(newVariation).length > 0) {
      setVariation(newVariationState)
    } else {
      setVariation(variates.reduce(reduceVariates(enabledVariations.filter(propEq(key, value))[0]), {}))
    }
  }

  return (
    <Card>
      <StyledLink to={productLink}>
        {product.image && <ImageLoader ratio="4:3" source={getViewport(product.image.src, 'xs')} />}
        <SmallP><b>{product.name}</b></SmallP>
        {product.prices[currency] ? <SmallP>{formatCurrency(product.prices[currency], currency)}</SmallP> : null}
      </StyledLink>
      {productHasVariations && (
        <>
          <Gap x={0.5} />
          <VariationSelectorWrapper>
            <VariationSelector
              hideText
              variates={variates}
              variatesOptions={variatesOptions}
              setVariation={changeVariation}
              variations={enabledVariations}
              active={variationState}
            />
          </VariationSelectorWrapper>
        </>
      )}
      <div>
        <Gap x={0.5} />
        <ButtonList>
          <Link to={productLink}><Button $withHover variant={BUTTON_VARIANTS.INFO} size={BUTTON_SIZES.TINY}>{t('shop.moreInfo')}</Button></Link>
          {product.prices[currency]
            ? <>
              <Gap x={0.5} />
              <AddToCartButton
                onClick={addToCart}
                $withHover
                variant={BUTTON_VARIANTS.ACCENT_FILLED}
                size={BUTTON_SIZES.TINY}
              >
                <AddToCartIcon />
                {t('cart.addToCart')}
              </AddToCartButton>
            </>
            : null
          }
        </ButtonList>
      </div>
    </Card>
  )
}

export default ProductCard
