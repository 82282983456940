import React, { useMemo } from "react";
import { H5 } from "shared/ui/headings";
import { formatCurrency } from "shared/formatters/currency";
import { Gap } from "shared/ui/gap";
import { CartItems, CartItem, CartItemsHeader, ConfigurationWrapper, ActionButton } from "./styled";
import { assoc, pick } from "ramda";
import { useData } from "shared/hooks/use-data";
import { useCheckoutContext } from "../context";
import { useEffect } from "react";
import { getCheckoutPrices } from "../helper";
import PromoCodeSelector from "../promo-code-selector";

import { useTranslations } from "../../../../shared/context/translations";
import { useCartContext } from "../../../../shared/context/cart";
import { AccessoriesPlug } from "../../../../shared/ui/cart-banner/styled";
import { Link } from "react-router-dom";
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from "../../../../shared/ui/button";
import { CheckoutConfiguratorLines } from "./configurator-lines";

const CartSummary = (props) => {
  const [checkoutState, setState] = useCheckoutContext();
  const { promoCodeData } = checkoutState;
  const { cart, allConfiguratorItems, officeConfigurator, ultraConfigurator, spaConfigurator, gamingConfigurator, cartActions } = useCartContext()
  const shippingDestinationCountry = checkoutState.shippingDetails
    .isShippingDifferent
    ? checkoutState.shippingDetails.shippingCountry
    : checkoutState.shippingDetails.country;

  const shippingOptionsRequestOptions = useMemo(
    () => ({
      method: "post",
      data: {
        objects: [].concat(
          allConfiguratorItems.map(pick(["id", "type", "quantity"])),
          cart.items.map(pick(["id", "type", "quantity"])),
        ),
        destination: {
          country: shippingDestinationCountry,
        },
      },
    }),
    [shippingDestinationCountry, allConfiguratorItems, cart]
  );
  const { state, result } = useData(
    "/api/configurator/shipping-options",
    shippingOptionsRequestOptions,
    shippingDestinationCountry !== ""
  );
  const isLoading = state === "PENDING";

  useEffect(() => {
    setState(assoc("shippingPrice", isLoading ? -1 : result[0].value));
    if (window.gtag && !isLoading) {
      window.gtag("event", 'ShippingLoaded', {
        event_category: "ecommerce",
        event_label: shippingDestinationCountry,
        value: 1,
      });
    }
  }, [isLoading, result]);

  const checkoutValues = getCheckoutPrices(checkoutState, cart.items, allConfiguratorItems);
  const removeCartItem = (product) => () => {
    cartActions.removeFromCart(product)
  }

  const t = useTranslations('checkout')
  return (
    <div>
      <H5>{t('shippingDetails')}</H5>
      <Gap />
      <PromoCodeSelector />
      <CartItems>
        {cart.items.map((line) => (
          <CartItem>
            <span>
              {`${line.quantity}x ${line.desc}`}
              <ActionButton onClick={removeCartItem(line)}><a>{t('remove')}</a></ActionButton>
            </span>
            <span>{formatCurrency(line.price * line.quantity)}</span>
          </CartItem>
        ))}
        <CheckoutConfiguratorLines {...officeConfigurator} />
        <CheckoutConfiguratorLines {...ultraConfigurator} />
        <CheckoutConfiguratorLines {...gamingConfigurator} />
        <CheckoutConfiguratorLines {...spaConfigurator} />
        {cart.items.length === 0 && allConfiguratorItems.length !== 0 && <AccessoriesPlug>
          <span><b>Need Accessories for your VicBooth?</b></span>
          <Link to={`/accessories`}><Button size={BUTTON_SIZES.TINY} variant={BUTTON_VARIANTS.ACCENT_FILLED}>Go to VicBooth Shop</Button></Link>
        </AccessoriesPlug>}
        <CartItemsHeader>
          <span>{t('subtotal')}</span>
          <span>{formatCurrency(checkoutValues.subTotal)}</span>
        </CartItemsHeader>
        <CartItemsHeader>
          <span>{t('shipping')} ({shippingDestinationCountry})</span>
          <span>
            {checkoutValues.shippingValue === -1
              ? "Calculating..."
              : checkoutValues.shippingValue === 0
                ? 'Included'
                : formatCurrency(checkoutValues.shippingValue)}
          </span>
        </CartItemsHeader>
        {promoCodeData.appliedCode !== null && (
          <CartItemsHeader>
            <span>Promo code ({promoCodeData.appliedCode.code})</span>
            <span>{`- ${formatCurrency(checkoutValues.discountValue)}`}</span>
          </CartItemsHeader>
        )}
        {checkoutValues.tax !== 0 && (
          <CartItemsHeader>
            <span>{t('vat')} (23%)</span>
            <span>{formatCurrency(checkoutValues.tax)}</span>
          </CartItemsHeader>
        )}
        <CartItemsHeader>
          <span>{t('total')}</span>
          <span>{formatCurrency(checkoutValues.total)}</span>
        </CartItemsHeader>
      </CartItems>
    </div>
  );
};

export default CartSummary;
