import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { SiteAreaContextProvider } from "../shared/context/site-area";
import Configurator from "./pages/configurator";
import Homepage from "./pages/homepage";
import ProductDetail from "./pages/product-detail";
import ProductList from "./pages/product-list";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const metaDescription = {
  ultra: "VicBooth Ultra | Soundproof Booth - The VicBooth Ultra is a sound insulation booth with improved acoustic performance for professional audio production environment, at an unbeatable price.",
  office: "Sustainable sound insulation and acoustic booths for phone calls, videoconferences, meetings, and focus work, in a sustainable ecosystem for the future office. Developed by Vicoustic for flexibility, privacy, speech intelligibility and better sound.",
  spa: "Create a silent space, for different therapies, in an acoustically protected environment, without traditional construction work",
  gaming: "Sound insulation acoustic booths for gaming and streaming in a private enclosed space, boundless of the noise produced inside or the sounds outside. Created by Vicoustic's experts in sound cabins and perfected for game development, VicBooth won Japan's Good Design Award and German Design Award 2022.",
}
const title = {
  ultra: 'VicBooth Ultra',
  office: 'VicBooth Office - Acoustic Office and Phone Booths',
  spa: 'VicBooth Spa - The easiest way to create a spa treatment room',
  gaming: 'VicBooth Gaming - A room to play without bounds',
}

const Routes = props => {
  const { siteArea, hideConfigurator } = props
  const capitalizedArea = capitalizeFirstLetter(siteArea)
  return (
    <SiteAreaContextProvider siteArea={siteArea}>
      <Route path={`/${siteArea}`} render={() => (
        <Helmet defaultTitle={title[siteArea]} titleTemplate={`%s - Vicbooth ${capitalizedArea}`}>
          <meta name="description" content={metaDescription[siteArea]} />
          <meta property="og:image" content={`/images/vicbooth_${siteArea}.jpg`} />
          <meta property="og:title" content={`VicBooth ${capitalizedArea} by Vicoustic`} />
          <meta property="og:description" content={metaDescription[siteArea]} />
          <meta property="og:site_name" content={`VicBooth ${capitalizedArea}`} />
        </Helmet>
      )} />
      <Switch>
        <Route path={`/${siteArea}/product/:id`} render={(props => <ProductDetail {...props} hideConfigurator={hideConfigurator} />)} />
        <Route path={`/${siteArea}/products`} render={(props => <ProductList {...props} hideConfigurator={hideConfigurator} />)} />
        {!hideConfigurator && <Route path={`/${siteArea}/configurator`} component={Configurator} />}
        <Route path={`/${siteArea}`} component={Homepage} />
      </Switch>
    </SiteAreaContextProvider>
  )
}

export default Routes
