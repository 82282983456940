import React from 'react'
import { Helmet } from 'react-helmet'
import { useData } from '../../../shared/hooks/use-data'
import { Loading } from '../../../shared/ui/loading'
import Footer from '../../partials/footer'
import Header from '../../partials/header'
import ProductDetail from './product-detail'
import { modalRegistry } from './modals';
import { ModalManagerContextProvider } from "shared/modal-manager";
import { useSiteAreaContext } from '../../../shared/context/site-area'

const ProductDetailPage = props => (
  <ModalManagerContextProvider modalRegistry={modalRegistry}>
    <ProductDetail {...props} />
  </ModalManagerContextProvider>
)

const ProductDetailContainer = props => {
  const { filterSiteArea, siteArea } = useSiteAreaContext()
  const { state, result, error } =  useData(`/api/products/${props.match.params.id}?area=${filterSiteArea}`)
  const isLoading = state === 'PENDING'

  return (
    <>
      <Helmet>
        {!isLoading && !error && <title>{result.name}</title>}
      </Helmet>
      <Header />
      {isLoading
        ? <div><Loading /></div>
        : error || <ProductDetailPage hideConfigurator={props.hideConfigurator} siteArea={siteArea} {...result} />}
      <Footer />
    </>
  )
}

export default ProductDetailContainer
