import React from "react";
import { Tag } from "bbcode-to-react";

class ListItem extends Tag {
  toReact() {
    return <li>{this.getComponents()}</li>
  }
}

export default ListItem;
