import React, { forwardRef, useEffect, useRef } from "react";

import { PageSection, TextHolder } from "../styled";
import { H1, H4, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import BackgroundVideo from "shared/ui/background-video";
import Columns from "shared/ui/blocks/columns";
import Multimedia from "shared/ui/blocks/multimedia";
import { useInView } from "react-intersection-observer";
import { useTranslations } from "../../../../shared/context/translations";
import { useSiteAreaContext } from "../../../../shared/context/site-area";

const SustainabilityVideo = (props, ref) => {
  const videoRef = useRef(null);
  const { siteArea } = useSiteAreaContext()
  const [inViewRef, inView, entry] = useInView({
    treshold: 0.05,
  });

  useEffect(() => {
    if (inView) videoRef.current.play();
  }, [inView]);
  const t = useTranslations(`homepageSections.${siteArea}.sustainability`)
  return (
    <PageSection $siteArea={siteArea}$noPadding ref={ref}>
      <Multimedia ref={inViewRef}>
        <BackgroundVideo
          ref={videoRef}
          ratioMobile={"360:500"}
          sourceMobile={t('videoMobile.src')}
          posterMobile={t('posterMobile.src')}
          ratio={"1920:1080"}
          source={t('videoDesktop.src')}
          poster={t('posterDesktop.src')}
          autoplay
          muted
          preload={"auto"}
        />
        <Columns $mobileBreakpoint={767}>
          <TextHolder $textAlign={"left"}>
            <H1>{t('title')}</H1>
            <Gap />
            <H4 $bold>
              {t('subTitle')}
            </H4>
            <Gap />
            <SmallP>
              {t('description')}
            </SmallP>
          </TextHolder>
          <></>
        </Columns>
      </Multimedia>
    </PageSection>
  );
};

export default forwardRef(SustainabilityVideo);
