import React, { useContext, createContext, useState, useMemo } from "react";

const getInitialState = () => ({
  activeModal: null,
  activeModalData: {},
});

export const ModalManagerContext = createContext(getInitialState());

export const ModalManagerContextProvider = (props) => {
  const { modalRegistry, children } = props;
  const [state, setState] = useState(getInitialState());
  const actions = useMemo(
    () => ({
      triggerModal: (key, data = null) =>
        setState({ activeModal: key, activeModalData: data }),
      closeModal: () => setState({ activeModal: null, activeModalData: {} }),
    }),
    []
  );
  const modalProps = useMemo(
    () => ({
      onClose: actions.closeModal,
      initiallyOpen: true,
    }),
    []
  );

  const ActiveModalComponent = modalRegistry[state.activeModal];
  return (
    <ModalManagerContext.Provider value={{ state, actions }}>
      {children}
      {state.activeModal && (
        <ActiveModalComponent
          modalProps={modalProps}
          {...state.activeModalData}
        />
      )}
    </ModalManagerContext.Provider>
  );
};

export const withModalManagement = (Component) => (props) => {
  const { actions } = useContext(ModalManagerContext);
  return <Component {...props} modalActions={actions} />;
};
