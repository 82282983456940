import React from "react";
import { Tag } from "bbcode-to-react";

class Sup extends Tag {
  toReact() {
    return <sup>{this.getComponents()}</sup>
  }
}

export default Sup;
