import React from "react";
import { Tag } from "bbcode-to-react";

class Youtube extends Tag {
  toReact() {
    return (
      <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%'}}>
      <iframe
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', margin: "auto", display: "block" }}
        width="100%"
        height="100%"
        src={`https://www.youtube-nocookie.com/embed/${this.getContent(true)}?rel=0${this.params.time ? `&start=${this.params.time}` : ''}`}
        frameborder="0"
        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      </div>
    );
  }
}

export default Youtube;
