import React from "react";

import { Color, List, Root } from "./styled";

const ColorSelector = (props) => {
  const { colors, selectColor, selectedColor, disabled } = props;
  return (
    <Root>
      <List>
        {colors.map(({ id, name, texture }) => (
          <li key={id}>
            <Color
              disabled={disabled}
              title={name}
              texture={texture}
              active={!disabled && selectedColor === id}
              onClick={() => selectColor(id)}
            />
          </li>
        ))}
      </List>
    </Root>
  );
};

export default ColorSelector;
