import styled from "styled-components";
import { colors } from "shared/ui/colors";
import { Button, BUTTON_SIZES } from "shared/ui/button";

export const Title = styled.p`
  font-weight: 700;
  margin-left: 6px;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "no-wrap")};
`;

export const PanelOptionButton = styled(Button).attrs({
  size: BUTTON_SIZES.SMALL,
})`
  color: ${({ active }) => (active ? colors.primary : colors.gray)};
  background: ${colors.white};
  margin: 5px;
  text-transform: none;
`;

export const Root = styled.div`
  margin: 5px 0;
  transition: all 0.2s ease-out;
  padding: 20px 5px 10px;
`;
