import { descend, propOr } from 'ramda'
import React from 'react'
import { Gap } from '../../../shared/ui/gap'
import ProductCard from '../product-list/product-card'
import { List, Root } from '../product-list/styled'

const AccessoriesList = props => {
  const { products } = props

  return (
    <Root>
      <Gap />
      <List>
        {products
          .sort(descend(propOr(0, 'relevancy')))
          .map(product => <li key={product.id}><ProductCard {...product} /></li>)
        }
      </List>
    </Root>
  )
}

export default AccessoriesList
