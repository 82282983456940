import { intersperse } from "ramda";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import { useTranslationsArray } from "../../../shared/context/translations";
import { BUTTON_VARIANTS, FakeButton } from "../../../shared/ui/button";
import { colors } from "../../../shared/ui/colors";
import { Gap } from "../../../shared/ui/gap";
import { H5 } from "../../../shared/ui/headings";
import { Root, StyledLink, StyledMenu, Wrapper } from "./styled";

const Menu = (props) => {
  const ref = useRef(null);
  const { siteArea } = props
  const { tA } = useTranslationsArray(`header.menu.${siteArea}`)
  return (
    <Root ref={ref} $siteArea={siteArea} className={props.isOpen ? "open" : ""}>
      <Wrapper>
        <BlueprintIcon color={colors.primary} />
        <Gap />
        <StyledMenu>
          {tA('links').map((link) => (
            <H5 $light key={link.link}>
              <StyledLink to={link.link}>{link.text}</StyledLink>
            </H5>
          ))}
        </StyledMenu>
        <Gap />
        {intersperse(<Gap />, tA('buttons').map(button => (
          <Link to={button.link}>
            <FakeButton $withHover variant={BUTTON_VARIANTS.ACCENT_FILLED}>
              {button.text}
            </FakeButton>
          </Link>
        )))}
      </Wrapper>
    </Root>
  );
};

export default Menu;
