import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import { colors } from './colors'
import { PlainButton } from './button'

const ModalRoot = styled.div`
  background: ${colors.lightGray};
  color: ${colors.black};
  padding: 15px 45px 15px 15px;
  position: fixed;
  z-index: 2147483640;
  bottom: 20px;
  left: 20px;
  max-width: 300px;
  width: 100%;
  box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.15);
  a {
    text-decoration: underline;
  }
`
const ModalClose = styled(PlainButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  &:focus,
  &:active {
    background: none;
  }
`
const ModalText = styled.p`
  font-size: 12px;
  line-height: 157%;
  margin: 0;
`

const CookiesModal = props => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!Cookies.get('cookies-ack')) {
      setShow(true)
    }
    Cookies.set('cookies-ack', true, { expires: 365 })
  }, [])

  if (!show) return null

  return (
    <ModalRoot>
      <ModalClose onClick={() => setShow(false)}>
        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" focusable="false"><title>thinCross</title><g fill="#323037" fill-rule="nonzero"><path d="M0.69 0L14 13.288 13.31 14 0 0.712z" ></path><path d="M13.31 0L0 13.288 0.69 14 14 0.712z"></path></g></svg>
      </ModalClose>
      <ModalText>
        We use cookies to give you the best experience. If you continue to use our service, we will assume that you agree to the use of such cookies. Find out more about cookies <a href="https://vicoustic.com/cookies-policy">here</a>
      </ModalText>
    </ModalRoot>
  )
}

export default CookiesModal
