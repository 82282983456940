import React, { useEffect } from "react";
import Helmet from "react-helmet";
// import { useData } from "shared/hooks/use-data";
import { useRenderedOnClient } from "shared/hooks/use-rendered-on-client";
import { Loading } from "shared/ui/loading";
import VacationsModal from "../../../shared/ui/vacations-modal";
import Footer from '../../partials/footer';
import Header from "../../partials/header";
import Checkout from "./checkout";
import { CheckoutContextProvider } from "./context";
import NoContextHeader from "../../partials/header/no-context-header";
import SimplifiedFooter from "../../partials/footer/simplified";

const CheckoutContainer = (props) => {
  const isRenderedOnClient = useRenderedOnClient();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", 'CheckoutInitiated', {
        event_category: "ecommerce",
        value: 1,
      });
    }
  }, [])
  return (
    <>
      <NoContextHeader />
      <Helmet>
        <title>Checkout</title>
        {isRenderedOnClient && (
          <script
            src={`https://www.paypal.com/sdk/js?currency=EUR&client-id=ATwkuJnmPpAgiOx6Qq3ogH6-LihJZIVyg8KIWweY0hkeFkU2nxSyjFLX5Wo31dyfKqrhs3zA66h900YX&disable-funding=credit,card,sepa`}
          ></script>
        )}
      </Helmet>
      {isRenderedOnClient ? (
        <CheckoutContextProvider>
          <VacationsModal />
          <Checkout />
        </CheckoutContextProvider>
      ) : (
        <div><Loading /></div>
      )}
      <SimplifiedFooter />
    </>
  );
};

export default CheckoutContainer;
