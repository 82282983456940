import ArrowBack from '@material-ui/icons/ArrowBack';
import { assoc, prop, propEq } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { H4, P, SmallP } from 'shared/ui/headings';
import { setUrlVar } from "shared/utils/url";
import bbCodeParser from "../../../../vicoustic/utility/bb-code-parser";
import { useCartContext } from '../../../shared/context/cart';
import { useTranslations } from '../../../shared/context/translations';
import { formatCurrency } from '../../../shared/formatters/currency';
import { BUTTON_SIZES, BUTTON_VARIANTS, FakeButton } from '../../../shared/ui/button';
import { Gap } from '../../../shared/ui/gap';
import { getCurrencyCookie } from '../../../shared/utils/cookies';
import { AddToCartButton, AddToCartIcon, GetQuoteButtonStyled } from '../product-list/styled';
import ConfiguratorButton from './configurator-button';
import { buildCartProduct, getCurrentVariation, getInitialVariation, hasVariations, mergeProductWithVariation, reduceVariates } from './helpers';
import ImageSwitcher from './image-switcher';
import { Body, PriceWrapper, Root, Separator, TextWrapper } from './styled';
import VariationSelector from './variation-selector';
import GetAQuoteButton from '../../../shared/ui/cart-banner/get-a-quote-button';

const ProductDetail = props => {
  const t = useTranslations()
  const currency = getCurrencyCookie()
  const {
    variates,
    variations = [],
    siteArea,
    backLink,
  } = props

  const enabledVariations = variations.filter(prop('enabled'))
  const productHasVariations = hasVariations(props.variates, enabledVariations)
  const { cartActions } = useCartContext()
  const [variationState, setVariation] = useState(
    getInitialVariation(productHasVariations, enabledVariations, variates)
  )

  useEffect(() => {
    variates.forEach(v => {
      setUrlVar(v, variationState[v])
    })
  }, [variationState])
  const currentVariation = getCurrentVariation(enabledVariations, variates, variationState)

  const product = mergeProductWithVariation(props, currentVariation)
  const {
    name,
    image,
    gallery,
    videos,
    shortDescription,
    description,
    prices,
    eanCode,
    reference,
    variatesOptions,
  } = product

  const cartItem = buildCartProduct({ ...product, productId: props.id }, variationState)

  const addToCart = () => {
    cartActions.addToCart(cartItem)
    M.toast({html: `1x ${product.name} ${t('cart.addedToCart')}`})
  }

  const changeVariation = (key, value) => {
    const newVariationState = assoc(key, value, variationState)
    const newVariation = getCurrentVariation(enabledVariations, variates, newVariationState)
    if (Object.keys(newVariation).length > 0) {
      setVariation(newVariationState)
    } else {
      setVariation(variates.reduce(reduceVariates(enabledVariations.filter(propEq(key, value))[0]), {}))
    }
  }

  return (
    <Root>
      <Gap x={0.5} />
      <Link to={backLink ? backLink : `${siteArea ? `/${siteArea}` : ''}/products`}>
        <FakeButton
          $normalCase
          variant={BUTTON_VARIANTS.INLINE}
          size={BUTTON_SIZES.INLINE}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowBack fontSize="small" />
            <span style={{ marginLeft: '4px' }}>{t('shop.backToProducts')}</span>
          </div>
        </FakeButton>
      </Link>
      {!props.hideConfigurator && <ConfiguratorButton top="20px" />}
      <Gap />
      <H4 as="h1"><b>{name}</b></H4>
      <Gap />
      <Body>
        <ImageSwitcher image={image} gallery={gallery} videos={videos} />
        <TextWrapper>
          <P><b>{shortDescription}</b></P>
          <Gap />
          {productHasVariations && (
            <>
              <VariationSelector
                variates={variates}
                variatesOptions={variatesOptions}
                setVariation={changeVariation}
                active={variationState}
                variations={enabledVariations}
              />
              <Gap />
            </>
          )}
          <PriceWrapper>
            {prices[currency] 
              ? <>
                <P>{formatCurrency(prices[currency] || 0, currency)}</P>
                <Gap />
                <AddToCartButton
                  onClick={addToCart}
                  $withHover
                  variant={BUTTON_VARIANTS.ACCENT_FILLED}
                  size={BUTTON_SIZES.TINY}
                >
                  <AddToCartIcon />
                  {t('cart.addToCart')}
                </AddToCartButton>
              </>
              : (
                <GetAQuoteButton cartItems={[cartItem]}>
                  <GetQuoteButtonStyled
                    $withHover
                    variant={BUTTON_VARIANTS.ACCENT_FILLED}
                    size={BUTTON_SIZES.TINY}
                  >
                    {t('cart.getQuoteButton.text')}
                  </GetQuoteButtonStyled>
                </GetAQuoteButton>
              )
            }
          </PriceWrapper>
          <Gap />
          <SmallP>{t('shop.eanCode')} {eanCode}</SmallP>
          <SmallP>{t('shop.reference')} {reference}</SmallP>
          <Gap />
          <Separator />
          <Gap />
          {description && <SmallP>{bbCodeParser.parse(description)}</SmallP>}
          <Gap />
        </TextWrapper>
      </Body>
    </Root>
  )
}

export default ProductDetail
