import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslations } from '../../../shared/context/translations'
import { useData } from '../../../shared/hooks/use-data'
import { Gap } from '../../../shared/ui/gap'
import { Loading } from '../../../shared/ui/loading'
import parseMarkdown from '../../../shared/utils/markdown'
import { Root, Title } from './styled'
import VideoCard from './video-card'

const VideosList = props => {
  const { state, result } =  useData(`/api/videos`)
  const isLoading = state === 'PENDING'
  const t = useTranslations('videos')

  return (
    <Root>
      <Gap x={2} />
      <Title as="h1" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('title') || '')}} />
      <Gap />
      {/*<FilterPanel filterState={filterState} toggleFilter={toggleFilter} />*/}
      <Grid container spacing={2} alignItems='stretch'>
        {isLoading || !result 
          ? <Loading />
          : result.map(video => (
            <Grid item container md={4} sm={6} key={video.id}>
              <VideoCard {...video} />
            </Grid>
          ))
        }
      </Grid>
      <Gap />
    </Root>
  )
}

export default VideosList
