import React, { useState } from "react";
import Cookies from "js-cookie";
import NewsletterModal from "./newsletter-modal";

const NewsletterBoundLink = (props) => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const clickHandler = (e) => {
    if (Cookies.get("newsletterEnrolled") && !props.alwaysOpen) return;
    e.preventDefault();
    setIsModalShowing(true);
  };
  const onSuccess = () => {
    window.open(props.url);
    setIsModalShowing(false);
    Cookies.set("newsletterEnrolled", "true", { expires: 365 });
  };
  return (
    <a onClick={clickHandler} target="_blank" href={props.url}>
      {isModalShowing && (
        <NewsletterModal
          onClose={() => setIsModalShowing(false)}
          onSuccess={onSuccess}
        />
      )}
      {props.children}
    </a>
  );
};
export default NewsletterBoundLink;
