import { Grid } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslations } from '../../../shared/context/translations'
import { colors } from '../../../shared/ui/colors'
import { Gap } from '../../../shared/ui/gap'
import { SmallP } from '../../../shared/ui/headings'
import { SectionRenderer } from '../../../shared/ui/section-renderer'
import { Stack } from '../../../shared/ui/stack'
import parseMarkdown from '../../../shared/utils/markdown'
import CaseStudiesCard from '../case-studies/case-study-card'
import { Description, Root, Separator, Title } from './styled'
import { useSetScrollToZero } from '../../../../vicoustic/utility/use-set-scroll-to-zero'

const CaseStudiesDetail = props => {
  const {
    related,
    title,
    sections,
    description,
    // mainImage,
    date,
  } = props
  const t = useTranslations('caseStudies')
  useSetScrollToZero(0)

  return (
    <Root>
      <Gap x={2} />
      <SmallP
        style={{
          textAlign: 'center',
          color: colors.midGray,
        }}
      >
        {date} <Separator /> <Link to="/case-studies">{t('backButton.text')}</Link>
      </SmallP>
      <Gap x={0.5} />
      <Title as="h1"><b>{title}</b></Title>
      <Gap />
      <Description><b>{description}</b></Description>
      {/*
      <ImageLoaderInViewWrapper source={getViewport(mainImage.src, 'm')} />
      <Gap />
       */}
      <Stack>
        {sections.map(section => <SectionRenderer {...section} />)}
      </Stack>
      {related && related.length !== 0 && (
        <>
          <Gap x={2} />
          <Title dangerouslySetInnerHTML={{ __html: parseMarkdown(t('related')) }} />
          <Gap x={2} />
          <Grid container spacing={2} alignItems='stretch' justifyContent='center'>
            {related.map(article => (
              <Grid item container md={4} sm={6} key={article.id}>
                <CaseStudiesCard {...article} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Gap />
    </Root>
  )
}

export default CaseStudiesDetail
