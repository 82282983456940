import styled from "styled-components";
import { colors } from "shared/ui/colors";
import { PlainButton } from "shared/ui/button";
import { propTernary } from "shared/utils/styled";

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
`;

export const BlueprintButton = styled(PlainButton)`
  padding: 5px 10px 20px 0px;
  height: 100%;
  text-align: center;
  cursor: ${propTernary("active", "default", "pointer")};
  background: ${colors.transparent};
  color: ${colors.black};
  ${propTernary("active", "", "opacity: 0.7;")}
  &:hover {
    opacity: 1;
  }
`;

export const BlueprintIcon = styled.img`
  max-width: 70px;
  width: 100%;
  max-height: 70px;
  height: 100%;
  margin-bottom: 10px;
  display: block;
  ${propTernary("$active", "", "filter: grayscale(100%);")}
`;
