import React from "react";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import Cookies from "js-cookie";
import { useFirebase } from "react-redux-firebase";
import { TextField } from "shared/forms";
import * as Yup from "yup";
import { Button, BUTTON_VARIANTS } from "shared/ui/button";

const initialState = {
  email: "",
  password: "",
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = (props) => {
  const firebase = useFirebase();

  return (
    <Formik
      initialValues={initialState}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
          .then(props.onLogin)
          .catch((e) => {
            setSubmitting(false);
            switch (e.code) {
              case "auth/wrong-password":
                return setFieldError("password", "Invalid credentials.");
              case "auth/invalid-email":
              case "auth/user-disabled":
              case "auth/user-not-found":
                return setFieldError(
                  "email",
                  "This email is not registered yet."
                );
            }
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField name="email" type="email" label="Email" />
            </Grid>
            <Grid item xs={12}>
              <TextField name="password" type="password" label="Password" />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant={BUTTON_VARIANTS.ACCENT}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
