export const menuLinks = [
  "intro",
  "applications",
  "highlights",
  "acoustic-kits",
  "showrooms",
  "technical-specs",
  "assembly",
  "sustainability",
  "faq",
  "about-vicoustic",
];

export const officeMenuLinks = [
  "intro",
  "applications",
  "highlights",
  "office-solutions",
  "showrooms",
  "technical-specs",
  "assembly",
  "sustainability",
  "faq",
  "about-vicoustic",
];
