import React from "react";
import { useTransition, animated } from "react-spring";

const Collapsible = (props) => {
  const { isOpen, children } = props;
  const transitions = useTransition(isOpen, null, {
    from: { maxHeight: "0px", opacity: 0, overflow: "hidden" },
    enter: { maxHeight: "500px", opacity: 1 },
    leave: { maxHeight: "0px", opacity: 0 },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          {children}
        </animated.div>
      )
  );
};
export default Collapsible;
