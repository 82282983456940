import styled, { css } from "styled-components";
import { propTernary } from "../utils/styled";
import { colors } from "./colors";

const commonStyles = css`
  letter-spacing: ${propTernary("$wide", 0.44, 0)}px;
  font-weight: ${(props) => (props.$bold ? 700 : props.$light ? 300 : 400)};
  margin: 0;
  ${propTernary("$accent", `color: ${colors.accent};`, "")}
  ${propTernary("$noWrap", "white-space: nowrap;", "")}
`;

const baseStyles = (fontSize, lineHeight, media) => css`
  @media screen and (${media}) {
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
  }
`;

export const H1 = styled.h1`
  ${commonStyles}
  ${baseStyles(40, 40, "max-width: 767px")}
  ${baseStyles(
    54,
    54,
    "min-width: 768px"
  )}
  ${baseStyles(62, 62, "min-width: 991px")}
  ${baseStyles(
    75,
    75,
    "min-width: 1201px"
  )}
`;

export const H2 = styled.h2`
  ${commonStyles}
  ${baseStyles(40, 40, "max-width: 767px")}
  ${baseStyles(
    54,
    54,
    "min-width: 768px"
  )}
  ${baseStyles(62, 62, "min-width: 991px")}
  ${baseStyles(
    75,
    75,
    "min-width: 1201px"
  )}
`;

export const H3 = styled.h3`
  ${commonStyles}
  ${baseStyles(32, 32, "max-width: 767px")}
  ${baseStyles(
    42,
    42,
    "min-width: 768px"
  )}
  ${baseStyles(50, 50, "min-width: 991px")}
  ${baseStyles(
    60,
    60,
    "min-width: 1201px"
  )}
`;

export const H4 = styled.h4`
  ${commonStyles}
  ${baseStyles(24, 24, "max-width: 767px")}
  ${baseStyles(
    26,
    26,
    "min-width: 768px"
  )}
  ${baseStyles(28, 28, "min-width: 991px")}
  ${baseStyles(
    34,
    34,
    "min-width: 1201px"
  )}
  ${baseStyles(44, 44, "min-width: 1601px")}
  ${baseStyles(
    54,
    54,
    "min-width: 1920px"
  )}
`;

export const H5 = styled.h5`
  ${commonStyles}
  ${baseStyles(20, 20, "max-width: 767px")}
  ${baseStyles(
    22,
    22,
    "min-width: 768px"
  )}
  ${baseStyles(24, 24, "min-width: 991px")}
  ${baseStyles(
    28,
    28,
    "min-width: 1201px"
  )}
  ${baseStyles(34, 34, "min-width: 1601px")}
  ${baseStyles(
    38,
    38,
    "min-width: 1920px"
  )}
`;

export const H6 = styled.h6`
  ${commonStyles}
  ${baseStyles(16, 16, "max-width: 767px")}
  ${baseStyles(
    18,
    18,
    "min-width: 768px"
  )}
  ${baseStyles(20, 20, "min-width: 991px")}
  ${baseStyles(
    22,
    22,
    "min-width: 1201px"
  )}
  ${baseStyles(26, 26, "min-width: 1601px")}
  ${baseStyles(
    30,
    40,
    "min-width: 1920px"
  )}
`;

export const BigP = styled.p`
  white-space: pre-line;
  ${commonStyles}
  ${baseStyles(18, 21, "max-width: 990px")}
  ${baseStyles(
    20,
    23,
    "min-width: 991px"
  )}
`;

export const P = styled.p`
  white-space: pre-line;
  ${commonStyles}
  ${baseStyles(16, 20, "max-width: 990px")}
  ${baseStyles(
    18,
    22,
    "min-width: 991px"
  )}
`;

export const SmallP = styled.p`
  white-space: pre-line;
  ${commonStyles}
  ${baseStyles(13, 19, "max-width: 990px")}
  ${baseStyles(
    14,
    20,
    "min-width: 991px"
  )}
`;

export const Note = styled.p`
  white-space: pre-line;
  ${commonStyles}
  ${baseStyles(11, 15, "max-width: 990px")}
  ${baseStyles(
    12,
    16,
    "min-width: 991px"
  )}
`;
