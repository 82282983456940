import React from "react";
import styled from "styled-components";
import CheckBox from "../../../../../../vicoustic/forms/check-box";
import { useTranslations } from "../../../../../shared/context/translations";
import { colors } from "../../../../../shared/ui/colors";
import { Gap } from "../../../../../shared/ui/gap";
import { SmallP } from "../../../../../shared/ui/headings";
import parseMarkdown from "../../../../../shared/utils/markdown";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Stack } from '../../../../../shared/ui/stack'
import { RegionButton } from "../../../../../shared/ui/region-button";

const OnLabel = styled(SmallP)`
  flex-grow: 0;
  white-space: nowrap;
  margin: 0 24px 0 0;
  p { margin: 0; color: black; }
  sup { color: ${colors.accent}; }
`

const BlueprintLightingSelector = (props) => {
  const { selectLighting, hasLighting, blueprints } = props;
  const t = useTranslations('configurator')

  if (blueprints.every(bp => !bp.hasLighting)) {
    return null
  }

  return (
    <>
      <Stack direction="row" alignItems="center">
        <OnLabel as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('lightingSelector'))}} />
        <RegionButton onClick={() => selectLighting(false)} active={!hasLighting}><CloseIcon style={{ fill: "red" }} /></RegionButton>
        <RegionButton onClick={() => selectLighting(true)} active={hasLighting}><CheckIcon style={{ fill: "#00ac54" }} /></RegionButton>
      </Stack>
    </>
  );
};

export default BlueprintLightingSelector;
