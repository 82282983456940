import styled from "styled-components";
import { colors } from "../../../shared/ui/colors";

export const FooterStyled = styled.footer`
  background: ${({ $siteArea }) => colors.secondary[$siteArea]};
  color: ${colors.white};
  padding: 30px 60px 100px;
  width: 100%;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 20px 80px;
  }
`;
