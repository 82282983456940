import styled from 'styled-components';
import { colors } from '../../../shared/ui/colors';
import { H4, P } from '../../../shared/ui/headings';

export const Root = styled.main`
  max-width: 860px;
  width: 100%;
  position: relative;
  padding: 0 30px 50px;
  margin: auto;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
`

export const Title = styled(H4)`
  color: ${colors.fakeBlack};
  text-align: center;
  * { margin: 0; }
`

export const Description = styled(P)`
  color: ${colors.fakeBlack};
  text-align: center;
  * { margin: 0; }
`

export const Separator = styled.div`
  width: 4px;
  height: 4px;
  margin: 3px 8px;
  background-color: currentColor;
  transform: rotate(45deg);
  display: inline-block;
`