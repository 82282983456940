import React from 'react'
import { Helmet } from 'react-helmet'
import NewsDetail from './news-detail'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import { Loading } from '../../../shared/ui/loading'
import { useData } from '../../../shared/hooks/use-data'

const NewsDetailContainer = props => {
  const { state, result, error } =  useData(`/api/news/${props.match.params.id}`)
  const isLoading = state === 'PENDING'

  return (
    <>
      <Helmet>
        {!isLoading && !error && <title>{result.title} - VicBooth</title>}
      </Helmet>
      <NoContextHeader />
      {isLoading
        ? <div><Loading /></div>
        : error || <NewsDetail {...result} />}
      <SimplifiedFooter />
    </>
  )
}

export default NewsDetailContainer
