import { range } from "ramda";
import React from "react";
import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { PlainButton } from "./button";
import { propTernary } from "../utils/styled";

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

const Item = styled(PlainButton)`
  cursor: pointer;
  margin: 0 5px;
  padding: 10px 0;
  & > span {
    height: 3px;
    width: 30px;
    display: block;
    background: rgba(255, 255, 255, ${propTernary("active", 1, 0.6)});
  }
`;

export const Selectors = (props) => {
  const { itemCount, active, setActive, style } = props;
  return (
    <Root style={style}>
      {range(0, itemCount).map((i) => (
        <Item key={i} active={active === i} onClick={() => setActive(i)}>
          <span />
        </Item>
      ))}
    </Root>
  );
};
export const Arrows = (props) => {
  const { setActive, itemCount } = props;
  if (itemCount === 1) return null

  return (
    <>
      <ArrowBackIosIcon
        fontSize={"large"}
        onClick={() => setActive((v) => (v - 1 + itemCount) % itemCount)}
        style={{
          position: "absolute",
          top: "50%",
          left: "15px",
          cursor: "pointer",
          transform: "translateY(-50%)",
        }}
      />
      <ArrowForwardIosIcon
        fontSize={"large"}
        onClick={() => setActive((v) => (v + 1) % itemCount)}
        style={{
          position: "absolute",
          top: "50%",
          right: "15px",
          cursor: "pointer",
          transform: "translateY(-50%)",
        }}
      />
    </>
  );
};
