import React from "react";
import { Tag } from "bbcode-to-react";

import ImageLoader from "../../client/commons/image-loader";

class Image extends Tag {
  toReact() {
    const attributes = {
      source: this.getContent(true),
    }
    const width = this.params.width || 'auto'
    const height = this.params.height || 'auto'

    return <ImageLoader {...attributes} width={width} height={height} />;
  }
}

export default Image;
