import { getUrlVars } from "shared/utils/url";
import { isNil, isEmpty, mergeWithKey, prop, propEq, uniqBy, allPass, T, values } from 'ramda';

const checkoutCurrency = "EUR"

export const hasVariations = (variates = [], variations = []) => variates.length !== 0 && variations.length !== 0

export const reduceVariates = obj => (acc, item) => ({
  ...acc,
  [item]: obj[item],
})

export const getInitialVariation = (productHasVariations, variations, variates) => {
  if (!productHasVariations) return {}
  const urlVars = getUrlVars()

  const variationStateFromUrl = variates.reduce(reduceVariates(urlVars), {})

  if (variations.some(variation => variates.every(v => variation[v] === variationStateFromUrl[v]))) {
    return variationStateFromUrl
  }

  const defaultVariation = variations.find(propEq('default', true))

  return variates.reduce(reduceVariates(defaultVariation ? defaultVariation : variations[0]), {})
}

export const getCurrentVariation = (variations, variates, variationState) => (
  variations.find(allPass(variates.map(v => propEq(v, variationState[v])))) || {}
)

export const getVariationOptions = ({ variates, variations }, filterPredicate = T) => (
  uniqBy(prop(variates), variations.filter(filterPredicate))
    .map(prop(variates))
)

export const mergeProductWithVariation = mergeWithKey((key, l, r) => {
  if (key === 'prices') {
    return mergeProductWithVariation(l, r)
  }
  return isNil(r) || isEmpty(r) || r === 0 ? l : r
})

const stringifyVariationState = vs => values(vs).join(' - ')

export const buildCartProduct = (product, variationState) => {
  const productHasVariations = hasVariations(product.variates, product.variations)
  const cartId = `${product.productId}${productHasVariations ? `_${stringifyVariationState(variationState)}` : ''}`
  const desc = `${product.name}${productHasVariations ? ` - ${stringifyVariationState(variationState)}` : ''}`
  
  const {
    prices,
    image,
    name,
    reference,
  } = product

  return {
    // needed for checkout
    type: 'individualProduct',
    price: prices[checkoutCurrency],
    id: product.productId,
    desc,
    // regular cart info
    reference,
    cartId,
    prices,
    image,
    name,
    variationState: stringifyVariationState(variationState),
    quantity: 1,
  }
}