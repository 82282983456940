import styled from "styled-components";

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "no-wrap")};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ $alignEnd }) => ($alignEnd ? "align-items: flex-end;" : "")}
`;

export const Root = styled.div`
  position: relative;
  padding: 20px 20xp 20px 0;
`;
