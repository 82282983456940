import React from "react";
import { Tag } from "bbcode-to-react";

class UnorderedList extends Tag {
  toReact() {
    return <ul className="slashed-list">{this.getComponents()}</ul>
  }
}

export default UnorderedList;
