import { append, contains, curry, ifElse, without } from 'ramda'
import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslations } from '../../../shared/context/translations'
import { useData } from '../../../shared/hooks/use-data'
import { Gap } from '../../../shared/ui/gap'
import { Loading } from '../../../shared/ui/loading'
import parseMarkdown from '../../../shared/utils/markdown'
import FilterPanel from './filter-panel'
import NewsCard from './news-card'
import { Root, Title } from './styled'

const toggleListItem = curry((value, list) => ifElse(
  contains(value),
  without([value]),
  append(value),
)(list));

const NewsList = props => {
  const [filterState, setFilters] = useState([])
  const { state, result } =  useData(`/api/news?categories=${filterState.join(',')}`)
  const isLoading = state === 'PENDING'
  const t = useTranslations('news')

  const toggleFilter = filter => {
    if (filterState.indexOf(filter) !== -1) {
      setFilters(toggleListItem(filter))
    } else {
      setFilters([filter])
    }
  }

  return (
    <Root>
      <Gap x={2} />
      <Title as="h1" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('title') || '')}} />
      <Gap />
      {/*<FilterPanel filterState={filterState} toggleFilter={toggleFilter} />*/}
      <Grid container spacing={4} alignItems='stretch'>
        {isLoading || !result 
          ? <Loading />
          : result.map(article => (
            <Grid item container md={4} sm={6} key={article.id}>
              <NewsCard {...article} />
            </Grid>
          ))
        }
      </Grid>
      <Gap />
    </Root>
  )
}

export default NewsList
