import React from 'react'
import { Helmet } from 'react-helmet'
import { ModalManagerContextProvider } from "shared/modal-manager"
import { useData } from '../../../shared/hooks/use-data'
import { Loading } from '../../../shared/ui/loading'
import SimplifiedFooter from '../../partials/footer/simplified'
import NoContextHeader from '../../partials/header/no-context-header'
import { modalRegistry } from './modals'
import ProductDetail from './product-detail'

const NoContextProductDetailContainer = props => {
  const { state, result, error } =  useData(`/api/products/${props.match.params.id}`)
  const isLoading = state === 'PENDING'

  return (
    <>
      <Helmet>
        {!isLoading && !error && <title>{result.name} - VicBooth</title>}
      </Helmet>
      <NoContextHeader />
      {isLoading
        ? <div><Loading /></div>
        : error || (
          <ModalManagerContextProvider modalRegistry={modalRegistry}>
            <ProductDetail hideConfigurator backLink="/accessories" {...result} />
          </ModalManagerContextProvider>
        )}
      <SimplifiedFooter />
    </>
  )
}

export default NoContextProductDetailContainer
