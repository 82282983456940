import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  padding: 20px;
  min-width: 320px;
  @media screen and (max-width: 768px) {
    min-width: 280px;
  }
`;
