import React, { forwardRef } from "react";
import styled from "styled-components";
import {
  PageSection,
  ColorBall,
  ImageContainer,
  PageSectionTitle,
  Center,
} from "../styled";
import { H4, SmallP, P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { colors } from "shared/ui/colors";
import { BlueprintIcon } from "shared/ui/blueprint-icon";
import Columns from "shared/ui/blocks/columns";
import ImageLoader from "shared/ui/image-loader";
import { Link } from "react-router-dom";
import { FakeButton } from "../../../../shared/ui/button";
import { useTranslations } from "../../../../shared/context/translations";
import { range } from "ramda";
import { useSiteAreaContext } from "../../../../shared/context/site-area";

const HighlightColumn = styled(Center)`
  max-width: 300px;
  margin: auto;
  ${/* sc-sel */ ImageContainer} {
    img {
      height: 100%;
    }
    height: 65px;
  }
`;

const Highlights = (props, ref) => {
  const { siteArea } = useSiteAreaContext()
  const t = useTranslations(`homepageSections.${siteArea}.highlights`)
  const items = t('items')
  const lines = Math.ceil(items.length / 2)
  return (
    <PageSection $siteArea={siteArea} ref={ref}>
      <PageSectionTitle>{t('name')}</PageSectionTitle>
      <BlueprintIcon color={colors.primary} />
      <Gap />
      {range(0, lines).map(lineIdx => (
        <Columns $verticalAlignment="flex-start" $mobileBreakpoint={767}>
          {items.slice(lineIdx * 2, lineIdx * 2 + 2).map(item => (
            <>
              <HighlightColumn>
                <ImageContainer>
                  <ImageLoader
                    ratio={"4:3"}
                    source={item.image.src}
                  />
                </ImageContainer>
                <Gap />
                <SmallP>
                  <b>{item.title}</b>
                  <br />
                  {item.description}
                </SmallP>
              </HighlightColumn>
            </>
          ))}
        </Columns>
      ))}
      <Gap />
      <SmallP>
        {t('footnote')}
      </SmallP>
    </PageSection>
  );
};

export default forwardRef(Highlights);
