import React from "react";

function VicousticLogo(props) {
  return (
    <svg
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 876.74 203.04"
      {...props}
    >
      <defs>
        <style>{".cls-1{fill:#231f20}.cls-2{fill:#ed513b}"}</style>
      </defs>
      <title>{"logo"}</title>
      <path
        className="cls-1"
        d="m275.81 117.72-.7 1.55c-4.78 10.54-15.75 16.83-29.36 16.83-20.52 0-34.85-14.21-34.85-34.58s14.17-34.71 34.46-34.71c13.22 0 24.37 6.49 29.1 17l.71 1.56 11.43-6.56-.6-1.35C278.79 63 263.13 54 245.11 54c-27.54 0-47.52 20-47.52 47.52S217.73 149 245.49 149c18.18 0 33.94-9 41.1-23.47l.64-1.31ZM348.1 54c-27.75 0-48.67 20.43-48.67 47.52S320.35 149 348.1 149s48.54-20.43 48.54-47.52S375.77 54 348.1 54m0 82.1c-20.49 0-35.36-14.54-35.36-34.58s14.87-34.71 35.36-34.71 35.22 14.59 35.22 34.71-14.81 34.58-35.22 34.58M480.89 55.27v54.8c0 16.79-9.65 26-27.18 26-17.69 0-27.43-9.24-27.43-26v-54.8H413v54.8c0 24.4 15.18 39 40.62 39s40.63-14.57 40.63-39v-54.8ZM558.24 95.43l-3.56-.43c-19.66-2.62-26.21-4.8-26.21-12.5 0-9.83 9-15.69 24.11-15.69 14.06 0 24.19 5.26 27.8 14.46l.65 1.67 11.47-6.57-.59-1.29C585.9 61.89 571.39 54 553.08 54c-22.68 0-37.93 11.66-37.93 29 0 19.78 20.18 22.43 38 24.77 23.75 3.08 29.3 4.54 29.3 11.87 0 10.31-10 16.45-26.8 16.45-14.8 0-25.8-3.69-33.65-11.31l-1.33-1.29-7.41 11.11 1 .89c10.29 9 24.08 13.54 41 13.54 24.52 0 40.37-11.59 40.37-29.52 0-19.6-19-21.88-37.39-24.09M604.84 55.27v12.81h33.54v79.68h13.32V68.08h33.68V55.27h-80.54zM702.99 55.27h13.32v92.49h-13.32zM811.31 117.72l-.7 1.55c-4.77 10.54-15.74 16.83-29.36 16.83-20.51 0-34.85-14.21-34.85-34.58s14.18-34.71 34.47-34.71c13.21 0 24.37 6.49 29.1 17l.71 1.56 11.42-6.56-.64-1.31C814.29 63 798.64 54 780.61 54c-27.53 0-47.51 20-47.51 47.52S753.24 149 781 149c18.18 0 33.93-9 41.1-23.47l.64-1.31Z"
      />
      <path
        className="cls-2"
        d="m179.15 55.27-41.85 92.49h14.58l41.66-92.49h-14.39zM151.87 55.27h-14.39l-34.54 76.35-34.55-76.35H54l41.64 92.49h14.57l41.66-92.49z"
      />
    </svg>
  );
}

export default VicousticLogo;
