import React, { forwardRef } from "react";
import styled from "styled-components";

const Root = styled.section`
  position: relative;
  display: flex;
  align-items: ${({ $align }) => ($align ? $align : "center")};
  overflow: hidden;
  width: 100%;
`;

const ItemChild = styled.div`
  width: 200%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  width: 50%;
  flex: 50% 0 0;
  & + & ${/* sc-sel */ ItemChild} {
    margin-left: -100%;
  }
`;

const Multimedia = (props, ref) => {
  return (
    <Root ref={ref} $align={props.$align}>
      {React.Children.map(props.children, (child) => (
        <Item>
          <ItemChild>{child}</ItemChild>
        </Item>
      ))}
    </Root>
  );
};

export default forwardRef(Multimedia);
