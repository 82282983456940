import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Gap } from "shared/ui/gap";
import { H6, SmallP } from "shared/ui/headings";
import { getViewport } from "../../../../vicoustic/utility/get-viewport";

const CaseStudyCard = props => {
  const {
    ...article
  } = props

  return (
    <Card style={{ width: '100%' }}>
      <CardActionArea style={{ height: '100%' }} component={Link} to={`/case-studies/${article.id}`}>
        {article.mainImage && <CardMedia
          component="img"
          image={getViewport(article.mainImage.src, 'xs')}
          alt={article.title}
        />}
        <CardContent>
          <SmallP>{article.date}</SmallP>
          <H6><b>{article.title}</b></H6>
          <Gap x={0.5} />
          <SmallP>{article.description}</SmallP>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CaseStudyCard
