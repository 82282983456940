import React, { forwardRef } from 'react'
import styled from "styled-components";
import { colors } from "shared/ui/colors";
import { propOr } from "ramda";
import { Link } from "react-router-dom";
import { useSiteAreaContext } from "../../../shared/context/site-area";

const propTernary = (propName, yes, no) => (props) =>
  props[propName] ? yes : no;

export const Seals = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  @media screen and (max-width: 768px) {
    top: 20px;
    right: -20px;
  }
`
export const Seal = styled.div`
  max-width: 235px;
  & + & {
    margin-top: 10px;
  }
  a {
    display: block;
    transition: transform .2s ease-out;
    transform-origin: right center;
  }

  a:hover {
    transform: scale(1.05);
  }
  img {
    max-width: 100%; 
    -webkit-filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.5)); 
    filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.5));
  }
  @media screen and (max-width: 768px) {
    max-width: 130px;
    & + & {
      margin-top: 5px;
    }  
  }
`

export const StyledPageSection = styled.section`
  background: ${(props) => propTernary("$invertColors", colors.secondary[props.$siteArea], colors.white)(props)};
  color: ${propTernary("$invertColors", colors.white, colors.black)};
  display: flex;
  flex-direction: column;
  ${({ $noPadding }) => ($noPadding ? "" : "padding: 80px 30px")};
  align-items: center;
  position: relative;
  transition: all 0.3s ease-out;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    ${({ $noPadding }) => ($noPadding ? "" : "padding: 20px 15px")};
  }
`;

export const PageSection = forwardRef(({ children, ...rest }, ref) => {
  const { siteArea } = useSiteAreaContext()
  return <StyledPageSection ref={ref} {...rest} $siteArea={siteArea}>{children}</StyledPageSection>
})

export const Center = styled.div`
  text-align: center;
`;

export const ImageContainer = styled.div`
  max-width: 300px;
  width: 100%;
  margin: auto;
`;

export const TextHolder = styled.div`
  padding: 30px;
  max-width: ${propTernary("$wide", "1600px", "750px")};
  width: 100%;
  margin: auto;
  text-align: ${propOr("center", "$textAlign")};
`;

export const PageSectionTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  display: inline-block;
  position: absolute;
  margin-top: 30px;
  left: 60px;
  @media screen and (max-width: 767px) {
    left: 30px;
  }
`;

export const PageSectionHeader = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 40px;
  @media screen and (max-width: 767px) {
    top: 20px;
  }
`;

export const BigCross = styled.div`
  position: relative;
  width: 230px;
  height: 230px;
  margin: auto;

  &:before,
  &:after {
    content: "";
    background: ${colors.white};
    height: 30px;
    width: 100%;
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const ColorBall = styled.div`
  border-radius: 50%;
  ${({ $color }) =>
    $color === colors.white ? `border: 2px solid ${colors.gray};` : ""}
  background: ${({ $color }) => $color};
  width: 35px;
  height: 35px;
  margin: 4px auto;
`;

export const ColorSelector = styled.div`
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    top: 300px;
    right: 15px;
  }
`;

export const RelatedProduct = styled(Link)`
  color: white;
`
