import React from "react";

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasFocus: false };
  }
  componentDidMount() {
    window.M.updateTextFields();
    if (this.props.takeFocus) this.ipt.focus();
  }
  componentDidUpdate() {
    window.M.updateTextFields();
    if (this.props.takeFocus) this.ipt.focus();
  }
  handleChange(e) {
    if (this.props.nativeEvents) {
      this.props.onChange(e);
    } else {
      let value = e.target.value;
      this.props.onChange(value, this.ipt);
    }
  }

  customRef(ref) {
    this.ipt = ref;
    if (this.props.forwardedRef) {
      this.props.forwardedRef(ref);
    }
  }

  focusHandler(e) {
    this.setState({ hasFocus: true });
    if (this.props.onFocus) this.props.onFocus(e);
  }

  blurHandler(e) {
    this.setState({ hasFocus: false });
    if (this.props.onBlur) this.props.onBlur(e);
  }

  render() {
    return (
      <div
        className="input-field"
        data-version={this.props.version}
        data-dark={this.props.dark}
        style={this.props.style || {}}
      >
        {this.props.children}
        <input
          tabIndex={this.props.tabIndex ? this.props.tabIndex : ""}
          ref={this.customRef.bind(this)}
          placeholder={
            !this.state.hasFocus || this.props.keepPlaceholder
              ? this.props.placeholder
              : ""
          }
          id={this.props.id}
          name={this.props.name}
          type={this.props.type}
          required={this.props.required}
          pattern={this.props.regex}
          value={this.props.value}
          onFocus={this.focusHandler.bind(this)}
          onBlur={this.blurHandler.bind(this)}
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.props.onKeyPress}
          className={
            this.props.validate
              ? "validate"
              : this.props.customError
              ? "invalid"
              : ""
          }
        />
        <label htmlFor={this.props.id}>{this.props.label}</label>
        {(this.props.helper || this.props.validate) && (
          <span className="helper-text" data-error={this.props.error}>
            {this.props.helper}
          </span>
        )}
      </div>
    );
  }
}

export default Input;
