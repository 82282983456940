import { append, contains, curry, descend, ifElse, includes, propOr, without, __ } from 'ramda'
import React, { useState, useEffect } from 'react'
import { Gap } from '../../../shared/ui/gap'
import { setUrlVars } from '../../../shared/utils/url'
import ConfiguratorButton from '../product-detail/configurator-button'
import FilterPanel from './filter-panel'
import ProductCard from './product-card'
import { Root, List } from './styled'
import { useSiteAreaContext } from '../../../shared/context/site-area'

const toggleListItem = curry((value, list) => ifElse(
  contains(value),
  without([value]),
  append(value),
)(list));


const ProductList = props => {
  const [filterState, setFilters] = useState(props.initialFiltersFromUrl)
  const { products, hideConfigurator } = props
  const { siteArea } = useSiteAreaContext()
  const filterProduct = product => {
    if (filterState.length === 0) return true
    const { categories = [] } = product
    return categories.some(includes(__, filterState))
  }

  const toggleFilter = filter => {
    if (filterState.indexOf(filter) !== -1) {
      setFilters(toggleListItem(filter))
    } else {
      setFilters([filter])
    }
  }

  useEffect(() => {
    setUrlVars({
      filters: filterState.length !== 0
        ? filterState.join(',')
        : null
    })
  }, [filterState])

  return (
    <Root>
      <Gap />
      <FilterPanel filterState={filterState} toggleFilter={toggleFilter} />
      {!hideConfigurator && <ConfiguratorButton />}
      <Gap />
      <List>
        {products
          .filter(filterProduct)
          .sort(descend(propOr(0, 'relevancy')))
          .map(product => <li key={product.id}><ProductCard siteArea={siteArea} {...product} /></li>)
        }
      </List>
    </Root>
  )
}

export default ProductList
