import React from "react";
import { Root, PanelOptionButton, List } from "./styled";
import { Gap } from "shared/ui/gap";
import { P } from "shared/ui/headings";
import { useTranslations } from "../../../../../shared/context/translations";

const relevancySort = (a, b) =>
  (parseInt(b.relevancy) || 0) - (parseInt(a.relevancy) || 0);

const PreConfiguredSelector = (props) => {
  const { boothConfigurations, selectConfiguration, selectedConfiguration } = props;
  const t = useTranslations('configurator')
  return (
    <Root>
      <P $bold>{t('boothConfigurationSelector')}</P>
      <Gap x={0.5} />
      <List $wrap>
        {boothConfigurations.sort(relevancySort).map((config) => (
          <li key={config.id}>
            <PanelOptionButton
              key={config.id}
              active={config.id === selectedConfiguration}
              onClick={() => selectConfiguration(config)}
            >
              {config.name}
            </PanelOptionButton>
          </li>
        ))}
      </List>
      <Gap />
      <P $bold>{t('designSelector')}</P>
    </Root>
  );
};

export default PreConfiguredSelector;
