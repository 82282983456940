import React, { useEffect } from "react";
import Helmet from "react-helmet";
import LiveChat from "react-livechat";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useRenderedOnClient } from "shared/hooks/use-rendered-on-client";
import GlobalStyles from "../globalStyles";
import { TranslationsContextProvider } from "../shared/context/translations";
import CookiesModal from "../shared/ui/cookies-modal";
import { setUrlVar } from "../shared/utils/url";
import CaseStudiesContainer from "./pages/case-studies";
import DisambiguationHome from "./pages/disambiguation-home";
import NewsContainer from './pages/news';
import Routes from "./routes";
import NewsDetailContainer from "./pages/news-detail";
import CaseStudiesDetailContainer from "./pages/case-studies-detail";
import VideosContainer from "./pages/videos";
import VideosDetailContainer from "./pages/videos-detail";
import ShowroomsPageContainer from "./pages/showrooms/page";
import CheckoutContainer from "./pages/checkout";
import { CartContextProvider } from "../shared/context/cart";
import AccessoriesListContainer from "./pages/accessories";
import NoContextProductDetailContainer from "./pages/product-detail/no-context-detail-page";
import FirstVisitModalManager from "./partials/user-settings/FirstVisitModalManager";
import { canCheckoutOnline } from "shared/constants/checkout";

let ReactPixel
const settingsUrlVars = ["currency", "country", "locale"];

const App = (props) => {
  const isRenderedOnClient = useRenderedOnClient();
  const match = useRouteMatch('/')
  useEffect(() => {
    settingsUrlVars.forEach((v) => setUrlVar(v, null));
  }, []);

  useEffect(() => {
    ReactPixel = require('react-facebook-pixel');
    ReactPixel.default.init('388308378554492');
  }, []);

  return (
    <TranslationsContextProvider serverTranslations={props.serverTranslations}>
      <CartContextProvider>
        <GlobalStyles />
        <Helmet>
          <html lang="en" />
          <link
            href="https://fonts.googleapis.com/css?family=Barlow:400,700"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,700"
            rel="stylesheet"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js"></script>
          <link rel="stylesheet" href="/styles/materialize.css" />
          <meta name="facebook-domain-verification" content="9aycdrwc2wn02kvet8ept4he6l88h4" />
        </Helmet>
        <Switch>
          <Route exact path="/" component={DisambiguationHome} />
          <Route exact path="/showrooms" component={ShowroomsPageContainer} />
          <Route exact path="/news" component={NewsContainer} />
          <Route exact path="/case-studies" component={CaseStudiesContainer} />
          <Route exact path="/videos" component={VideosContainer} />
          <Route exact path="/news/:id" component={NewsDetailContainer} />
          <Route exact path="/case-studies/:id" component={CaseStudiesDetailContainer} />
          <Route exact path="/videos/:id" component={VideosDetailContainer} />
          {canCheckoutOnline()
            ? <Route exact path="/checkout" component={CheckoutContainer} />
            : isRenderedOnClient && <Redirect from="/checkout" to="/" />
          }
          <Route exact path="/accessories" component={AccessoriesListContainer} />
          <Route exact path="/product/:id" component={NoContextProductDetailContainer} />
          <Redirect from="/products" to="/ultra/products" />
          <Redirect from="/configurator" to="/ultra/configurator" />
        </Switch>
        <Routes siteArea="ultra" />
        <Routes siteArea="office" />
        <Routes siteArea="spa" hideConfigurator />
        <Routes siteArea="gaming" />
        {isRenderedOnClient && (
          <Route
            path="/"
            render={({ location }) => {
              ReactPixel.default.pageView(); // For tracking page view
              if (typeof window.ga === "function") {
                window.ga("set", "page", location.pathname + location.search);
                window.ga("send", "pageview");
              }
              return null;
            }}
          />
        )}
        {isRenderedOnClient && !match.isExact && <LiveChat license={8923309} />}
        <CookiesModal />
        {isRenderedOnClient && <FirstVisitModalManager />}
      </CartContextProvider>
    </TranslationsContextProvider>
  );
};

export default App;
