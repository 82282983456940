import { pathOr } from "ramda";
import React, { useContext, createContext } from "react";

export const TranslationsContext = createContext(null);

const getTranslations = () => {
  const scriptElement = document.getElementById('translations-object')
  const translationsObject = JSON.parse(scriptElement?.textContent || {})
  return translationsObject
}

export const TranslationsContextProvider = (props) => {
  const {
    children,
    serverTranslations = null
  } = props;

  return (
    <TranslationsContext.Provider value={serverTranslations || getTranslations()}>
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = (prefix = '') => {
  const translations = useContext(TranslationsContext)
  const t = key => pathOr('', `${prefix ? `${prefix}.` : ''}${key}`.split('.'), translations)
  return t
}

export const useTranslationsArray = (prefix = '') => {
  const t = useTranslations(prefix)
  return {
    t,
    tA: (key) => t(key) || [],
  }
}
