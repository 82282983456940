import { has, values } from "ramda";

import { buildUrlQueryFromState } from "../../../client/pages/configurator/helpers";

export const generateConfigUrl = (state) => {
  return `${
    window.location.origin
  }${
    window.location.pathname
  }?${
    buildUrlQueryFromState(state)
  }`;
};

export const formatState = (data, state) => {
  let lines = [];

  const currentBP = data.blueprints.find((bp) => state.blueprint === bp.id);

  const region = state.blueprintRegion !== 'NONE'
    ? currentBP.regions.find(r => r.id === state.blueprintRegion)
    : null

  lines.push({
    type: "blueprint",
    quantity: 1,
    id: currentBP.id,
    ref: region ? region.reference : currentBP.reference,
    eanCode: region ? region.eanCode : currentBP.eanCode,
    name: currentBP.name_en,
    desc: currentBP.description_en,
    region: region ? region.id : '',
    price: parseFloat(currentBP.price) || 0,
    prices: currentBP.prices,
  });

  if (state.ventilator.type !== "NONE") {
    const currentVentilator = data.ventilators.find(
      (v) => state.ventilator.type === v.id
    );

    lines.push({
      type: "ventilator",
      quantity: state.ventilator.quantity,
      id: currentVentilator.id,
      ref: currentVentilator.reference || "",
      eanCode: currentVentilator.eanCode || "",
      desc: currentVentilator.name_en,
      price: parseFloat(currentVentilator.price) || 0,
      prices: currentVentilator.prices,
    });
  }

  let panelMap = {};
  currentBP.panels.forEach((bpPanel) => {
    const statePanel = state.panels[bpPanel.id];
    const selectedPanel = data.panels.find((p) => p.id === statePanel.type);
    const selectedColor = data.colors.find((c) => c.id === statePanel.color);
    if (!selectedPanel || !selectedColor) return;
    const { reference, eanCode } = selectedPanel.models.find(
      (model) => model.color === statePanel.color
    );

    if (!has(reference, panelMap)) {
      panelMap[reference] = {
        type: "panel",
        quantity: 0,
        id: selectedPanel.id,
        ref: reference,
        eanCode: eanCode,
        color: selectedColor.name_en,
        desc: selectedPanel.name_en + " - " + selectedColor.name_en,
        price: parseFloat(selectedPanel.price) || 0,
        prices: selectedPanel.prices,
      };
    }
    panelMap[reference].quantity += 1;
  });

  return lines.concat(values(panelMap));
};
