import React, { useEffect, useRef } from "react";
import { Cell, Grid, Container } from "../../../shared/ui/grid";
import { Gap } from "../../../shared/ui/gap";
import { SmallP } from "../../../shared/ui/headings";
import CartSummary from "./cart-summary";
import ShippingDetails from "./shipping-details";
import { useCheckoutContext } from "./context";
import { assoc, assocPath, mergeDeepWith, omit } from "ramda";
import axios from "axios";
import { useCurrentUser } from "../../../shared/hooks/use-current-user";
import { validateState, getCheckoutPrices, postOrder } from "./helper";
import { withFirebase } from "react-redux-firebase";
import { useState } from "react";
import ThankYou from "./thank-you";
import { Loading } from "shared/ui/loading";
import styled from "styled-components";
import { Button } from "../../../shared/ui/button";
import { CardIcons } from "./card-icons";
import { useBreakpoints } from "../../../shared/hooks/use-breakpoints";
import { getCurrencyCookie } from "../../../shared/utils/cookies";
import parseMarkdown from "../../../shared/utils/markdown";

import { useTranslations } from "../../../shared/context/translations";
import { useCartContext } from "../../../shared/context/cart";

const leftIgnoreUndefined = (l, r) => (l === undefined ? r : l);

const activeStyles = {};
const inactiveStyles = {
  opacity: 0.3,
  pointerEvents: "none",
};

const PayPalButtonsContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: 0;
`;

const Checkout = (props) => {
  const [state, setState] = useCheckoutContext();
  const { auth, profile } = useCurrentUser();
  const [buttonsInit, setButtonsInit] = useState(false);
  const [sentEvent, setSentEvent] = useState(false);
  const paypalButtonsRef = useRef();
  const checkoutValuesRef = useRef();
  const stateRef = useRef();
  const { cart, allConfiguratorItems, clearAllConfigurations, cartActions } = useCartContext()
  const checkoutValues = getCheckoutPrices(state, cart.items, allConfiguratorItems);

  const { isMobile } = useBreakpoints();

  checkoutValuesRef.current = checkoutValues;
  stateRef.current = state;

  const isStateValid = validateState(state, cart.items, allConfiguratorItems);

  const toastError = () => {
    try {
      setState(assocPath(["checkoutData", "isProcessing"], false));
      M.toast({
        html:
          "There was an error processing your order, please try again later.",
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (window.gtag && !sentEvent && isStateValid) {
      setSentEvent(true)
      window.gtag("event", 'CheckoutFilled', {
        event_category: "ecommerce",
        value: 1,
      });
    }
  }, [isStateValid, sentEvent])

  useEffect(() => {
    if (profile.isLoaded && !profile.isEmpty) {
      const shippingDetails = {
        isLoaded: true,
        email: auth.email,
        vatNumber: profile.vatNumber,
        companyName: profile.companyName,
        country: profile.country,
        firstName: profile.firstName,
        lastName: profile.lastName,
        address: profile.address,
        postCode: profile.postCode,
        city: profile.city,
        phone: profile.phone,
        isShippingDifferent: profile.isShippingDifferent,
        shippingCountry: profile.shippingCountry,
        shippingFirstName: profile.shippingFirstName,
        shippingLastName: profile.shippingLastName,
        shippingAddress: profile.shippingAddress,
        shippingPostCode: profile.shippingPostCode,
        shippingCity: profile.shippingCity,
        shippingPhone: profile.shippingPhone,
      };
      setState(mergeDeepWith(leftIgnoreUndefined, { shippingDetails }));
    }
  }, [profile, auth]);

  const postOrderByBankTransfer = async () => {
    setState(assocPath(["checkoutData", "isProcessing"], true));
    const currentUser = props.firebase.auth().currentUser;
    const vicboothOrder = await postOrder(currentUser, stateRef.current, null, null, cart.items, allConfiguratorItems);
    if (vicboothOrder.status == 500) {
      if (window.gtag) {
        window.gtag("event", 'BankTransferError', {
          event_category: "ecommerce",
          value: 1,
        });
      }
      toastError();
      return null;
    }

    if (window.gtag) {
      window.gtag("event", 'BankTransferSuccess', {
        event_category: "ecommerce",
        value: 1,
      });
    }
    setState(
      assoc("checkoutData", {
        hasPurchased: true,
        orderNumber: vicboothOrder.data.number,
      })
    );
    clearAllConfigurations()
    cartActions.replaceState([])
  };

  useEffect(() => {
    if (buttonsInit || !window.paypal) return;
    window.requestAnimationFrame(() => {
      paypalButtonsRef.current.innerHTML = "";
      setButtonsInit(true);
      const currency = getCurrencyCookie();

      window.paypal
        .Buttons({
          style: {
            color: "black",
            shape: "rect",
            label: "pay",
            height: 50,
          },
          onClick: function() {
            if (window.gtag) {
              window.gtag("event", 'PaypalClicked', {
                event_category: "ecommerce",
                value: 1,
              });
            }
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: checkoutValuesRef.current.total.toFixed(2) + "",
                  },
                },
              ],
              application_context: {
                brand_name: "Vicbooth by Vicoustic",
                shipping_preference: "NO_SHIPPING",
              },
            });
          },
          onApprove: async (data, actions) => {
            setState(assocPath(["checkoutData", "isProcessing"], true));
            const currentUser = props.firebase.auth().currentUser;
            const token = currentUser ? await currentUser.getIdToken(true) : null;
            const vicboothOrder = await postOrder(
              token,
              stateRef.current,
              data.orderID,
              null,
              cart.items,
              allConfiguratorItems,
            );
            if (vicboothOrder.status == 500) {
              if (window.gtag) {
                window.gtag("event", 'PaypalError', {
                  event_category: "ecommerce",
                  value: 1,
                });
              }

              toastError();
              return null;
            }
            return actions.order.capture().then(function (details) {
              console.log(
                "Transaction completed by " + details.payer.name.given_name
              );
              // send payment success trigger for order
              axios({
                url: "/api/payment-success/" + vicboothOrder.data.id,
                method: "post",
                headers: {
                  "content-type": "application/json",
                  Authorization: token ? "Bearer " + token : "",
                },
                data: {
                  orderID: data.orderID,
                },
              })
                .then((result) => {
                  if (result.data == "OK") {
                    setState(
                      assoc("checkoutData", {
                        hasPurchased: true,
                        orderNumber: vicboothOrder.data.number,
                      })
                    );
                    if (window.gtag) {
                      window.gtag("event", 'PaypalSuccess', {
                        event_category: "ecommerce",
                        value: 1,
                      });
                    }

                    clearAllConfigurations()
                    cartActions.replaceState([])
                  } else {
                    if (window.gtag) {
                      window.gtag("event", 'PaypalError', {
                        event_category: "ecommerce",
                        value: 1,
                      });
                    }

                    toastError();
                  }
                })
                .catch((err) => {
                  toastError();
                });
            });
          },
        })
        .render("#paypal-buttons");
    });
  });
  const t = useTranslations('checkout')
  return (
    <Container>
      <Grid $wrap>
        <Cell s={isMobile ? 12 : 6}>
          <Gap />
          {state.checkoutData.isProcessing && <Loading />}
          {state.checkoutData.hasPurchased ? <ThankYou /> : <ShippingDetails />}
        </Cell>
        <Cell s={isMobile ? 12 : 6}>
          {!state.checkoutData.hasPurchased && (
            <>
              <Gap />
              <CartSummary />
              <Gap x={0.5} />
              <PayPalButtonsContainer>
                <SmallP as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('footnote')) }} />
                <Gap x={0.5} />
                <div style={
                  isStateValid && !state.checkoutData.isProcessing
                  ? activeStyles
                  : inactiveStyles
                }>
                  <Button $wide onClick={postOrderByBankTransfer}>
                    {t('payWithBankTransfer')}
                  </Button>
                  <Gap />
                  <div id="paypal-buttons" ref={paypalButtonsRef} />
                  <CardIcons />
                </div>
              </PayPalButtonsContainer>
            </>
          )}
          <Gap />
        </Cell>
      </Grid>
    </Container>
  );
};

export default withFirebase(Checkout);
