import React from "react";
import styled from "styled-components";

const colorProp = ({ $color }) => $color;

const Root = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    transform: scale(0.6);
    transform-origin: 50% 50%;
  }
`;

const Icon = styled.div`
  width: 51px;
  height: 51px;
  position: relative;
  margin: 12px;
  transform: rotate(-45deg);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: ${colorProp};

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 6px;
    right: 0px;
    background: ${colorProp};
  }
  ::before {
    bottom: 0px;
  }
  ::after {
    top: calc(50% - 3px);
  }
`;

const Rect = styled.span`
  width: 100%;
  border: 3px solid currentColor;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 13px 13px;

  & + & {
    transform: rotate(90deg);
  }
`;

const Square = styled.span`
  width: 27px;
  height: 27px;
  position: absolute;
  border-bottom: 3px solid currentColor;
  right: 0;
  bottom: 0;

  ::before,
  ::after {
    content: "";
    width: 3px;
    background: ${colorProp};
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -2px;
  }
  ::before {
    transform: rotate(45deg);
  }
  ::after {
    transform: rotate(-45deg);
  }
`;

export const BlueprintIcon = ({ color }) => (
  <Root>
    <Icon $color={color}>
      <Rect $color={color} />
      <Rect $color={color} />
      <Square $color={color} />
    </Icon>
  </Root>
);
