import { any, isEmpty, omit, pipe, values, compose, assoc } from "ramda";
import axios from "axios";
import { getCurrencyCookie } from "../../../shared/utils/cookies";

export const calculateCheckoutPrices = (
  items,
  shippingValue,
  promoCode,
  applyTax = true
) => {
  const subTotal = items.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  let discountValue = 0;
  let actualShippingValue = shippingValue === -1 ? 0 : shippingValue
  let total = subTotal + actualShippingValue;

  if (promoCode) {
    const discountPercentualValue = promoCode.discountValue;
    discountValue = Math.round(subTotal * discountPercentualValue) / 100;
    total -= discountValue;
  }
  let tax = 0;
  if (applyTax) {
    tax = Math.round(total * 23) / 100;
    total += tax;
  }
  return {
    subTotal,
    shippingValue,
    discountValue,
    tax,
    total,
  };
};

export const isBuyerCompany = (state) =>
  state.shippingDetails.userType === "company";

export const getCheckoutPrices = (state, cart, configurator) => {
  return calculateCheckoutPrices(
    [].concat(
      configurator,
      cart,
    ),
    state.shippingPrice,
    state.promoCodeData.appliedCode,
    !isBuyerCompany(state) || state.shippingDetails.country === "PT"
  );
};

const isAnyValueEmpty = pipe(values, any(isEmpty));

const shippingProps = [
  "isLoaded",
  "shippingCountry",
  "shippingFirstName",
  "shippingLastName",
  "shippingAddress",
  "shippingPostCode",
  "shippingCity",
  "shippingPhone",
];

export const VAT_REGEXP = new RegExp(
  /(^(AT)(U\d{8}$))|(^(BE)(\d{10}$))|(^(BG)(\d{9,10}$))|(^(CY)([0-5|9]\d{7}[A-Z]$))|(^(CZ)(\d{8,10})?$)|(^(DE)([1-9]\d{8}$))|(^(DK)(\d{8}$))|(^(EE)(10\d{7}$))|(^(EL)(\d{9}$))|(^(ES)([0-9A-Z][0-9]{7}[0-9A-Z]$))|(^(EU)(\d{9}$))|(^(FI)(\d{8}$))|(^(FR)([0-9A-Z]{2}[0-9]{9}$))|(^(GB)((?:[0-9]{12}|[0-9]{9}|(?:GD|HA)[0-9]{3})$))|(^(GR)(\d{8,9}$))|(^(HR)(\d{11}$))|(^(HU)(\d{8}$))|(^(IE)([0-9A-Z\*\+]{7}[A-Z]{1,2}$))|(^(IT)(\d{11}$))|(^(LV)(\d{11}$))|(^(LT)(\d{9}$|\d{12}$))|(^(LU)(\d{8}$))|(^(MT)([1-9]\d{7}$))|(^(NL)(\d{9}B\d{2}$))|(^(NO)(\d{9}$))|(^(PL)(\d{10}$))|(^(PT)(\d{9}$))|(^(RO)([1-9]\d{1,9}$))|(^(RU)(\d{10}$|\d{12}$))|(^(RS)(\d{9}$))|(^(SI)([1-9]\d{7}$))|(^(SK)([1-9]\d[(2-4)|(6-9)]\d{7}$))|(^(SE)(\d{10}01$))/gi
);

export const isVatValidFormat = (vat) => VAT_REGEXP.test(vat);

export const validateState = (state, cart, configurator) =>
  (configurator.length !== 0 || cart.length !== 0) &&
  (!isBuyerCompany(state) ||
    (!state.vatData.isLoading && state.vatData.valid)) &&
  !state.promoCodeData.isLoading &&
  !isAnyValueEmpty(
    compose(
      omit(
        state.shippingDetails.isShippingDifferent ? ["isLoaded"] : shippingProps
      ),
      omit(
        state.shippingDetails.userType !== "company"
          ? ["companyName", "vatNumber"]
          : []
      )
    )(state.shippingDetails)
  ) &&
  state.shippingPrice !== -1;

export const postOrder = async (token, state, transaction, siteArea, cart, configurator) => {
  const currency = getCurrencyCookie();
  const checkoutValues = getCheckoutPrices(state, cart, configurator);
  const vicboothOrder = await axios({
    url: "/api/orders",
    method: "post",
    headers: {
      "content-type": "application/json",
      Authorization: token ? "Bearer " + token : "",
    },
    data: {
      cart: [].concat(
        configurator.map(assoc('configuration', true)),
        cart,
      ),
      data: omit(["isLoaded"], state.shippingDetails),
      shipping: {
        value: state.shippingPrice,
      },
      vat:
        checkoutValues.tax !== 0
          ? { factor: 23, value: checkoutValues.tax }
          : null,
      promoCode: state.promoCodeData.appliedCode,
      currency,
      transaction: transaction || null,
      paymentMethod: transaction ? "Paypal" : "Bank transfer",
      siteArea,
    },
  });

  return vicboothOrder;
};

export const validateVat = async (country, number, callback) => {
  const res = await axios({
    url: "/api/validate-vat",
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data: {
      country,
      number,
    },
  });
  callback(res.data);
  return res.data;
};
