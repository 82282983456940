import React from 'react'
import Showrooms from './showrooms'
import { Style } from './styled'
import { Helmet } from 'react-helmet'
import NoContextHeader from '../../partials/header/no-context-header'
import SimplifiedFooter from '../../partials/footer/simplified'
import { SiteAreaContextProvider } from '../../../shared/context/site-area'

const ShowroomsPageContainer = (props) => {
  return (
    <>
      <Helmet>
        <title>Showrooms - VicBooth</title>
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: 'window.__initializeMap = function() {};' }} />
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBE936xV0qGB7dwduj6ZX3UjmahXTWgORU&callback=__initializeMap"></script>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous" />
      </Helmet>
      <NoContextHeader />
      <Style bgColor="white" color="black" />
      <SiteAreaContextProvider siteArea="ultra">
        <Showrooms />
      </SiteAreaContextProvider>
      <SimplifiedFooter />
    </>
  )
}

export default ShowroomsPageContainer
