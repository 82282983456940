import React from 'react'
import { useSiteAreaContext } from '../../../shared/context/site-area'
import SpaHomepage from './spa'
import GamingHomepage from './gaming'
import OfficeHomepage from './office'
import UltraHomepage from './ultra'

const Homepage = props => {
  const { siteArea } = useSiteAreaContext()
  switch (siteArea) {    
    case 'ultra': return <UltraHomepage />
    case 'office': return <OfficeHomepage />
    case 'spa': return <SpaHomepage />
    case 'gaming': return <GamingHomepage />
    default: return null
  }
}

export default Homepage
