import constate from "constate";
import { append, assoc, compose, evolve, filter, map, not, propEq, when } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SITE_AREAS } from "../constants/siteAreas";
import { useConfiguratorState } from "./configuratorState";
import { getCountryCookie } from "../utils/cookies";

const getInitialState = () => ({
  items: [],
  loaded: false,
})

const configuratorDefault = () => ({
  configurator: getInitialState(),
  configuratorActions: {
    updateConfiguration: () => {},
    removeConfiguration: () => {},
  },
})

const defaultValues = ({
  cartOpen: false,
  setCartState: () => {},
  cart: getInitialState(),
  cartActions: {
    addToCart: () => {},
    removeFromCart: () => {},
    changeQuantity: () => {},
    replaceState: () => {},
  },
  officeConfigurator: configuratorDefault(),
  ultraConfigurator: configuratorDefault(),
  gamingConfigurator: configuratorDefault(),
  spaConfigurator: configuratorDefault()
});

const useCart = () => {
  const region = getCountryCookie()
  const localStorageCartKey = `vicboothCartItems_general_${region}`
  const [cartOpen, setCartOpen] = useState(false)
  const [cart, setState] = useState(getInitialState());
  const officeConfigurator = useConfiguratorState(SITE_AREAS.OFFICE)
  const ultraConfigurator = useConfiguratorState(SITE_AREAS.ULTRA)
  const gamingConfigurator = useConfiguratorState(SITE_AREAS.GAMING)
  const spaConfigurator = useConfiguratorState(SITE_AREAS.SPA)

  const setCartState = useCallback((v) => setCartOpen(v), [setCartOpen])

  useEffect(() => {
    // load cart from localstorage
    const localCart = localStorage.getItem(localStorageCartKey)
    try {
      setState({
        loaded: true,
        items: !localCart ? [] : JSON.parse(localCart),
      })
    } catch (e) {
      console.log('Cart loading failed.')
    }
  }, [])

  useEffect(() => {
    // save cart to localstorage
    if (!cart.loaded) return
    localStorage.setItem(localStorageCartKey, JSON.stringify(cart.items))
  }, [cart])

  const replaceState = (newState) => {
    setState(assoc('items', newState))
  }

  const changeQuantity = (product, quantity) => {
    setState(evolve({
      items: map(when(
        propEq('cartId', product.cartId),
        assoc('quantity', quantity),
      )),
    }))
  }

  const removeFromCart = product => {
    setState(evolve({
      items: filter(
        compose(
          not,
          propEq('cartId', product.cartId)
        ),
      ),
    }))
  }

  const addToCart = product => {
    // check if exists by cartID
    const cartEntry = cart.items.find(propEq('cartId', product.cartId))
    // if exists changeQuantity
    if (cartEntry) {
      changeQuantity(product, cartEntry.quantity + 1)
    } else {
      // if not append
      setState(evolve({
        items: append(product),
      }))
    }
  }

  const cartActions = useMemo(
    () => ({
      addToCart,
      removeFromCart,
      changeQuantity,
      replaceState,
    }),
    [cart]
  );

  const getConfiguratorForArea = useCallback((siteArea) => {
    switch (siteArea) {
      case SITE_AREAS.OFFICE: return officeConfigurator
      case SITE_AREAS.GAMING: return gamingConfigurator
      case SITE_AREAS.SPA: return spaConfigurator
      case SITE_AREAS.ULTRA: return ultraConfigurator
    }
  }, [officeConfigurator, ultraConfigurator, gamingConfigurator, spaConfigurator])

  const allConfiguratorItems = useMemo(() => [].concat(
    officeConfigurator.configurator.items,
    ultraConfigurator.configurator.items,
    gamingConfigurator.configurator.items,
    spaConfigurator.configurator.items,
  ), [officeConfigurator, ultraConfigurator, gamingConfigurator, spaConfigurator])

  const clearAllConfigurations = useCallback(() => {
    officeConfigurator.configuratorActions.removeConfiguration()
    ultraConfigurator.configuratorActions.removeConfiguration()
    spaConfigurator.configuratorActions.removeConfiguration()
    gamingConfigurator.configuratorActions.removeConfiguration()
  }, [officeConfigurator, ultraConfigurator, gamingConfigurator, spaConfigurator])

  return {
    ...defaultValues,
    cartOpen,
    setCartState,
    cart,
    cartActions,
    officeConfigurator,
    ultraConfigurator,
    gamingConfigurator,
    spaConfigurator,
    getConfiguratorForArea,
    allConfiguratorItems,
    clearAllConfigurations,
  }
}

export const [CartContextProvider, useCartContext] = constate(useCart)
