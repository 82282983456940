import styled, { css } from 'styled-components';
import { colors } from '../../../shared/ui/colors';
import { H3 } from '../../../shared/ui/headings';

export const Root = styled.main`
  max-width: 1440px;
  width: 100%;
  position: relative;
  padding: 0 30px 50px;
  margin: auto;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`

export const Title = styled(H3)`
  color: ${colors.accent};
  text-align: center;
  * { margin: 0; }
`

export const ButtonList = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

export const FilterButtonList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const FilterButton = styled.div`
  margin: 0 12px 12px 0;
  ${({ $active }) => $active ? css`
    & > * {
      color: ${colors.primary};
      border-color: ${colors.primary};
    }
  ` : ''}
`
