import Cookies from 'js-cookie'
import { getCurrencyCookie, getCountryCookie } from "shared/utils/cookies";

export const ONLINE_CHECKOUT_COUNTRIES = [
  "BE",
  "DE",
  "DK",
  "ES",
  "IT",
  "LU",
  "FR",
  "NL",
  "PT",
  "SE",
];
export const ONLINE_CHECKOUT_CURRENCIES = ["EUR"];

export const canCheckoutOnline = () => (
  ONLINE_CHECKOUT_COUNTRIES.includes(getCountryCookie()) &&
  ONLINE_CHECKOUT_CURRENCIES.includes(getCurrencyCookie())
)
