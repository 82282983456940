import React from "react";
import { Tag } from "bbcode-to-react";
import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
`

class Table extends Tag {
  toReact() {
    return <TableWrapper>
      <table {...this.params}>
        {this.getComponents()}
      </table>
    </TableWrapper>
  }
}

export default Table;
