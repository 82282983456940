import React from "react";
import { Button, BUTTON_VARIANTS, FakeButton } from "shared/ui/button";
import { Gap } from "shared/ui/gap";
import { formatCurrency } from "shared/formatters/currency";
import { colors } from "shared/ui/colors";
import { SmallP } from "shared/ui/headings";
import { Root, OrderButton, Wrapper } from "./styled";
import { useLocation } from "react-router-dom";
import { getCurrencyCookie } from "../../../../../shared/utils/cookies";
import WarningIcon from '@material-ui/icons/Warning'
import { useTranslations } from "../../../../../shared/context/translations";
import { useCurrentUser } from "../../../../../shared/hooks/use-current-user";
import { withFirebase } from "react-redux-firebase";
import { useCartContext } from "../../../../../shared/context/cart";
import { useSiteAreaContext } from "../../../../../shared/context/site-area";
import GetAQuoteButton from 'shared/ui/cart-banner/get-a-quote-button';

const FloatingBuyButton = (props) => {
  const { profile } = useCurrentUser()
  const { siteArea } = useSiteAreaContext()
  const isAdmin = profile?.token?.claims?.admin || false
  const { setCartState, getConfiguratorForArea } = useCartContext()
  const { configurator, configuratorActions } = getConfiguratorForArea(siteArea)
  const { canProceed, missingVentilator, missingRegion, children } = props;
  const location = useLocation();
  const URL = encodeURIComponent(
    `${window.location.origin}${location.pathname}${location.search}`
  );
  const t = useTranslations('configurator')
  const tCart = useTranslations('cart')
  const buyNowButtonName = configurator.items.length === 0 ? tCart('addToCart') : tCart('updateCart')

  return (
    <Root>
      {!canProceed && (
        <>
          <SmallP style={{ display: 'flex', alignItems: 'center' }}>
            <WarningIcon />
            <Gap x={0.2} />
            {missingRegion
              ? t('electricSystemError')
              : missingVentilator
                ? t('ventilationError')
                : t('panelError')
            }
          </SmallP>
          <Gap x={0.5} />
        </>
      )}
      <Wrapper>
        {props.totalPrice ? (
          <>
            <div>
              <b style={{ color: colors.primary }}>{t('totalPrice')}</b>
              <br />
              {formatCurrency(props.totalPrice, getCurrencyCookie())}
            </div>
            <Gap />
            <OrderButton
              disabled={!canProceed}
              target="_self"
              onClick={(e) => {
                e.stopPropagation()
                setCartState(true)
                configuratorActions.updateConfiguration(props.formattedState, props.url, props.previewUrl)
                if (window.gtag) {
                  window.gtag("event", 'click', {
                    event_category: "Configurator",
                    event_label: buyNowButtonName,
                    value: 1
                  });
                }
              }}
            >
              <FakeButton disabled={!canProceed} variant={BUTTON_VARIANTS.ACCENT}>
                {buyNowButtonName}
              </FakeButton>
            </OrderButton>
            <Gap />
          </>
        ) : null}
        <Button
          disabled={!canProceed}
          onClick={() => {
            props.onClick();
            if (window.ga) {
              window.ga("send", {
                hitType: "event",
                eventCategory: "Configurator",
                eventAction: "Download PDF",
                eventLabel: URL,
              });
            }
          }}
          variant={BUTTON_VARIANTS.SECONDARY}
        >
          {t('downloadPDFButton.text')}
        </Button>
        {isAdmin && (
          <>
            <Gap />
            <Button
              disabled={!canProceed}
              onClick={() => {
                props.exportScene(window.__canvasScene);
              }}
              variant={BUTTON_VARIANTS.SECONDARY}
            >
              Download 3D Scene
            </Button>
          </>
        )}
        <Gap />
        {children}
      </Wrapper>
    </Root>
  );
};

export default withFirebase(FloatingBuyButton);
