import React, { forwardRef } from "react";

import { PageSection } from "../styled";
import { H3, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { BUTTON_VARIANTS, Button } from "shared/ui/button";
import Columns from "shared/ui/blocks/columns";
import parseMarkdown from "../../../../shared/utils/markdown";

import { useTranslations } from "../../../../shared/context/translations";
import { useSiteAreaContext } from "../../../../shared/context/site-area";

const About = (props, ref) => {
  const { siteArea } = useSiteAreaContext()
  const t = useTranslations(`homepageSections.${siteArea}.about`)
  const openChat = () => window?.LC_API?.open_chat_window();

  return (
    <PageSection ref={ref} $siteArea={siteArea} $invertColors>
      <Columns $mobileBreakpoint={767}>
        <>
          <H3 $bold>{t('title')}</H3>
          <Gap />
          <SmallP as="div" dangerouslySetInnerHTML={{ __html: parseMarkdown(t('description')) }} />
          <Gap />
          <Button
            onClick={openChat}
            $withHover
            variant={BUTTON_VARIANTS.OUTLINE}
          >
            {t('button.text')}
          </Button>
        </>
        <></>
      </Columns>
    </PageSection>
  )
};

export default forwardRef(About);
