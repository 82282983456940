import React from "react";
import PanelSelector from "../panel-selector";

const PanelAccordion = (props) => {
  const { currentBlueprint, data, state, selectColor, selectPanel } = props;

  return currentBlueprint.panels.map((panel, idx) => (
    <PanelSelector
      key={panel.id}
      defaultPanelTypeIdx={idx === 0 ? 0 : 1}
      panelTypes={data.panelTypes}
      panel={panel}
      panels={data.panels}
      colors={data.colors}
      selectedPanel={state.panels[panel.id]}
      selectPanel={selectPanel(panel.id)}
      selectColor={selectColor(panel.id)}
    />
  ));
};

export default PanelAccordion;
