import styled from "styled-components";
import { propTernary } from "../utils/styled";

const gutter = 12;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  overflow: hidden;
`;
export const Grid = styled.div`
  display: flex;
  width: calc(100% + ${gutter * 2}px);
  padding: 0 ${gutter}px;
  margin: 0 -${gutter}px;
  flex-wrap: ${propTernary("$wrap", "wrap", "nowrap")};
`;

export const Cell = styled.div`
  display: block;
  padding: 0 ${gutter}px;
  ${({ $noOverflow }) => ($noOverflow ? "" : "overflow: hidden;")}
  flex: ${({ s }) => (s ? `calc(100% / 12 * ${s})` : "auto")} 0 1;
`;
