import React, { useContext, createContext } from "react";
import { getCountryCookie } from "../utils/cookies";

export const isNorthAmericaRegion = country => country === 'US' || country === 'CA'

export const SiteAreaContext = createContext(null);

export const SiteAreaContextProvider = (props) => {
  const { children, siteArea } = props;
  const currentCountry = getCountryCookie()
  let filterSiteArea = siteArea
  if (isNorthAmericaRegion(currentCountry)) {
    filterSiteArea = `${siteArea}-na`
  }

  return (
    <SiteAreaContext.Provider value={{ siteArea, filterSiteArea }}>
      {children}
    </SiteAreaContext.Provider>
  );
};

export const useSiteAreaContext = () => useContext(SiteAreaContext)
